/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.3115 5.67071C29.6527 2.01069 24.7742 0 19.5791 0C8.88183 0 0.167582 8.67284 0.158272 19.3193C0.158272 22.7292 1.05204 26.0463 2.74648 28.9836L0 39L10.297 36.3129C13.1365 37.851 16.3299 38.6664 19.5791 38.6664H19.5884C30.2857 38.6664 39 29.9936 39.0093 19.3378C39 14.1768 36.9797 9.32145 33.3115 5.67071ZM19.5791 35.3956C16.6744 35.3956 13.8348 34.6172 11.3583 33.1532L10.7718 32.8011L4.66436 34.3949L6.29363 28.4647L5.91191 27.8532C4.29195 25.2958 3.44474 22.34 3.44474 19.31C3.44474 10.4704 10.688 3.26158 19.5884 3.26158C23.899 3.26158 27.9489 4.9387 31.0026 7.96864C34.047 11.0078 35.7228 15.0385 35.7228 19.3286C35.7135 28.196 28.4703 35.3956 19.5791 35.3956ZM28.433 23.3685C27.9489 23.1276 25.5655 21.9601 25.1186 21.7933C24.6718 21.6358 24.3459 21.5524 24.0294 22.0342C23.7035 22.516 22.7725 23.6094 22.4932 23.9244C22.2139 24.2488 21.9253 24.2858 21.4412 24.0449C20.957 23.804 19.3929 23.2944 17.5402 21.645C16.0972 20.3664 15.1289 18.7819 14.8403 18.3001C14.561 17.8182 14.8124 17.5588 15.0544 17.3179C15.2686 17.1048 15.5386 16.7527 15.7806 16.4747C16.0227 16.1967 16.1065 15.9929 16.2647 15.6686C16.423 15.3443 16.3485 15.0663 16.2275 14.8254C16.1065 14.5845 15.1382 12.2031 14.7286 11.2395C14.3376 10.2944 13.9372 10.4241 13.6393 10.4148C13.36 10.3963 13.0341 10.3963 12.7083 10.3963C12.3824 10.3963 11.8611 10.5167 11.4142 10.9986C10.9673 11.4804 9.71974 12.6479 9.71974 15.0292C9.71974 17.4105 11.4607 19.6992 11.7028 20.0235C11.9449 20.3478 15.1196 25.2217 19.9888 27.3158C21.1432 27.8161 22.0463 28.1126 22.7539 28.335C23.9176 28.7056 24.9697 28.65 25.8076 28.5296C26.7386 28.3906 28.6751 27.3621 29.0847 26.2316C29.4851 25.1012 29.4851 24.1376 29.364 23.9337C29.243 23.7299 28.9172 23.6094 28.433 23.3685Z"
      fill="#24CD5B"
    />
  </svg>
);

const PublishedWhatsappIcon = (props) => <Icon component={svg} {...props} />;

export default PublishedWhatsappIcon;
