import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import TadaIcon from 'components/Icons/TadaIcon';

import './PublishApprovedInfo.scss';

const PublishApprovedInfo = ({ configText, signature, approvedManually, language }) => {
  const signatureDate = approvedManually || signature?.date || new Date();
  const device = signature?.device?.toLowerCase() || 'desktop';

  return (
    <Card className={`publish-approved-info publish-approved-info-${language}`}>
      <p>
        {approvedManually
          ? configText('Proposal Manually Approved')
          : configText('Proposal Approved')}{' '}
        <TadaIcon />{' '}
      </p>
      <span>
        {approvedManually
          ? `${configText('Approved by')} ${signature?.name}`
          : configText('Signed on')}{' '}
      </span>
      <span>
        <strong>{moment(signatureDate).utc().format('MMM Do, YYYY, HH:mm A')} GMT+0</strong>
      </span>
      <span>
        {configText(`From ${device}`)} ({signature?.clientIP})
      </span>
    </Card>
  );
};

PublishApprovedInfo.defaultProps = {
  signature: {},
  approvedManually: '',
};

PublishApprovedInfo.propTypes = {
  signature: PropTypes.instanceOf(Object),
  approvedManually: PropTypes.string,
  configText: PropTypes.func.isRequired,
  language: PropTypes.string,
};

export default PublishApprovedInfo;
