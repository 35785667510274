import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg
    width="142"
    height="142"
    viewBox="0 0 142 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="142" height="142" rx="71" fill="#F5F6FA" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="142"
      height="142">
      <rect width="142" height="142" rx="71" fill="#F5F6FA" />
    </mask>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2895 112.273C27.2895 112.273 22.6747 124.586 34.0252 128.623C45.5993 132.74 49.782 120.099 49.782 120.099C49.782 120.099 53.1325 111.559 56.8549 101.094C59.7621 92.9162 62.0258 86.6529 63.3896 81.2425C64.8596 75.4048 63.6301 68.5 58.3146 66.6092C50.2357 63.7358 43.6483 68.2323 41.3875 73.7788C39.1885 79.1723 36.4449 86.5402 34.1539 92.5395C29.7476 104.073 27.2895 112.273 27.2895 112.273Z"
        fill="#C7BCE4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.408 116.206C103.408 116.206 107.702 127.334 118.905 122.606C130.329 117.786 123.321 100.15 119.574 90.8513C116.647 83.5849 115.69 76.341 113.326 71.899C110.774 67.1058 103.048 63.4519 97.8009 65.6658C89.8271 69.0304 88.2876 74.7742 90.0535 79.8897C91.7696 84.8651 94.8402 94.5568 96.8662 99.9898C100.763 110.436 103.408 116.206 103.408 116.206Z"
        fill="#C7BCE4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.2853 42.7976C95.2853 42.7976 102.254 39.6923 100.594 32.3319C104.033 26.3993 100.833 22.3096 100.833 22.3096C100.833 22.3096 101.357 14.2006 96.0292 13.313C93.3212 4.73526 83.6144 7.23005 83.6144 7.23005C83.6144 7.23005 77.973 1.80934 68.7866 7.87065C62.4492 7.58792 62.6774 4.44878 60.4719 6.46734C58.2646 8.4859 56.9449 13.5206 59.4913 15.7505C54.6548 17.3877 58.3162 25.1566 58.3162 25.1566C58.3162 25.1566 56.4405 29.3337 61.5681 33.0007C66.6958 36.6696 67.9244 47.0555 76.6242 50.993C84.9933 54.7803 94.3535 52.758 95.2853 42.7976Z"
        fill="#7D4556"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.7126 57.6574L89.8366 43.612L71.1492 44.448L70.0042 56.6411C69.4932 62.0852 73.5698 66.8794 79.0262 67.2476C79.1295 67.2551 79.2347 67.2617 79.338 67.2692C84.9175 67.6487 89.6628 63.249 89.7126 57.6574Z"
        fill="#C99382"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.6743 21.9666C87.6743 21.9666 86.0371 26.773 89.7051 28.3998C88.3272 34.6819 91.1441 34.4405 91.1441 34.4405C91.1441 34.4405 96.1938 29.3081 98.6595 34.572C101.002 39.5738 95.2385 45.8924 90.7458 44.3032C81.6572 55.7297 66.2169 54.5951 62.0483 45.9526C59.515 40.7 61.4547 33.7041 61.4434 30.3123C61.419 21.9769 63.2196 19.6578 63.2196 19.6578C63.2196 19.6578 66.2893 20.6854 70.0202 18.4968C73.4026 21.6528 79.0008 19.5469 79.0008 19.5469C79.0008 19.5469 82.0329 24.4801 87.6743 21.9666Z"
        fill="#C99382"
      />
      <path
        d="M94.6241 35.0717C95.6467 34.9898 96.324 35.3032 96.67 35.8905C96.8254 36.1541 96.8877 36.4182 96.8972 36.6394L96.8978 36.7431C96.8892 36.9375 96.7246 37.088 96.5303 37.0794C96.3554 37.0717 96.2159 36.9376 96.1961 36.7692L96.1921 36.6515C96.1837 36.5378 96.1466 36.3899 96.0631 36.2481C95.8607 35.9047 95.4456 35.7127 94.6804 35.774C93.918 35.835 93.2289 36.3268 92.7736 37.0441C92.5832 37.3441 92.4556 37.6563 92.4123 37.9106C92.3807 38.0963 92.4008 38.1963 92.4071 38.2009C93.0751 38.6907 93.5626 39.3345 93.6668 39.9892C93.8004 40.8288 93.3086 41.567 92.2031 42.0908C92.0273 42.1741 91.8173 42.0991 91.734 41.9233C91.6507 41.7475 91.7257 41.5375 91.9014 41.4542C92.7465 41.0538 93.0503 40.5978 92.9711 40.0999C92.9008 39.6586 92.5227 39.1592 91.9906 38.7691C90.9148 37.9807 92.6615 35.2289 94.6241 35.0717Z"
        fill="#9B6160"
      />
      <path
        d="M93.0559 37.5058C93.3913 37.4947 93.7846 37.5161 94.1682 37.5981C95.0618 37.7891 95.623 38.2685 95.5098 39.1013C95.4836 39.294 95.3061 39.4291 95.1134 39.4029C94.9399 39.3793 94.8132 39.2331 94.8087 39.0636L94.8118 39.0064C94.8623 38.6347 94.6003 38.4109 94.021 38.287C93.7666 38.2327 93.4977 38.2099 93.2553 38.2078L93.0394 38.2116C92.8453 38.2233 92.6784 38.0753 92.6667 37.8812C92.6561 37.7064 92.7749 37.5537 92.9403 37.5164L93.0054 37.5079L93.0559 37.5058Z"
        fill="#9B6160"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.0569 31.8771C73.9066 35.268 76.5329 38.1395 79.9229 38.2907C83.3137 38.441 86.1852 35.8147 86.3355 32.4247C86.4867 29.0339 83.8613 26.1624 80.4705 26.0112C77.0796 25.86 74.2082 28.4863 74.0569 31.8771Z"
        fill="white"
      />
      <path
        d="M73.6238 31.8582C73.4629 35.4879 76.2742 38.5616 79.903 38.7235C83.533 38.8844 86.6066 36.0729 86.7675 32.4442C86.9294 28.8143 84.1188 25.7407 80.4892 25.5789C76.9189 25.4196 73.8866 28.1369 73.6341 31.6802L73.6238 31.8582ZM80.4506 26.444C83.6024 26.5846 86.0429 29.2535 85.9023 32.4057C85.7626 35.5567 83.0936 37.998 79.9415 37.8583C76.7905 37.7178 74.3492 35.0486 74.4889 31.8967C74.6295 28.7447 77.2986 26.3035 80.4506 26.444Z"
        fill="#7D4556"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1898 30.6394C59.0386 34.0303 61.6658 36.9017 65.0557 37.052C68.4466 37.2032 71.3181 34.5769 71.4683 31.1861C71.6196 27.7952 68.9942 24.9247 65.6024 24.7735C62.2125 24.6222 59.341 27.2485 59.1898 30.6394Z"
        fill="white"
      />
      <path
        d="M58.7586 30.62C58.5967 34.2497 61.4088 37.3236 65.0379 37.4845C68.6678 37.6464 71.7414 34.8349 71.9023 31.2052C72.0642 27.5753 69.2536 24.5027 65.6231 24.3408C61.9941 24.1789 58.9205 26.9904 58.7586 30.62ZM65.5845 25.206C68.7373 25.3466 71.1777 28.0146 71.0371 31.1667C70.8975 34.3186 68.2285 36.7599 65.0764 36.6193C61.9251 36.4796 59.4832 33.8103 59.6238 30.6586C59.7643 27.5068 62.4334 25.0654 65.5845 25.206Z"
        fill="#7D4556"
      />
      <path
        d="M72.6892 30.6851C72.8445 30.8022 72.8754 31.0231 72.7582 31.1784L72.6376 31.3418C72.5403 31.4754 72.4317 31.6275 72.3147 31.7955C71.9803 32.2754 71.6459 32.7849 71.3343 33.301C71.0782 33.7253 70.8486 34.1358 70.6525 34.5255L70.5001 34.8389C69.9739 35.9618 69.8068 36.7932 70.0469 37.1564C70.3938 37.6811 70.9107 38.0062 71.5656 38.1658C72.1489 38.3079 72.7821 38.3073 73.3753 38.2195L73.5323 38.194C73.6001 38.1818 73.6512 38.1709 73.6834 38.163C73.8723 38.1165 74.0631 38.2319 74.1096 38.4208C74.1561 38.6097 74.0407 38.8006 73.8518 38.8471L73.7376 38.8721C73.6683 38.8859 73.5811 38.9013 73.4783 38.9165C72.8028 39.0163 72.0835 39.017 71.3988 38.8502C70.5821 38.6512 69.912 38.2299 69.4592 37.5449C68.9996 36.8495 69.264 35.7173 70.0232 34.2088C70.2281 33.8017 70.4663 33.3757 70.7313 32.9369C70.9714 32.5391 71.2242 32.1461 71.4801 31.7669L71.7367 31.3928C71.8266 31.2636 71.9118 31.1435 71.991 31.0335L72.1958 30.754C72.313 30.5987 72.5339 30.5679 72.6892 30.6851Z"
        fill="#9B6160"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.3911 44.4864C68.3911 44.4864 73.6443 43.1268 77.9941 43.557C77.9941 43.557 76.5296 40.256 72.3333 41.2561C68.6057 42.145 68.3911 44.4864 68.3911 44.4864Z"
        fill="white"
      />
      <path
        d="M78.0838 44.0006C78.3533 44.0165 78.5487 43.7479 78.4504 43.4964C78.3995 43.3662 78.2995 43.1523 78.1464 42.885C77.893 42.4425 77.5786 42.0089 77.1984 41.6151C76.0006 40.3741 74.2961 40.7932 72.3609 41.2239C70.7765 41.5767 69.746 41.4138 68.9442 42.5534C68.6316 42.9978 68.3968 43.4589 68.2281 43.9112C68.1257 44.1859 68.0674 44.4002 68.0423 44.5294C67.9917 44.7902 68.2243 45.0178 68.484 44.9616L68.7167 44.9132C68.9073 44.8744 69.1266 44.8315 69.3715 44.7856C71.2383 44.4357 73.2624 44.1508 75.1795 44.0289C76.244 43.9613 77.2194 43.9493 78.0838 44.0006ZM77.3872 43.0574L77.4941 43.236L77.4347 43.2339C76.7222 43.2182 75.952 43.2382 75.1325 43.2903C73.1808 43.4143 71.129 43.7031 69.2352 44.0581L68.9439 44.1139L68.9216 44.1698C69.0692 43.774 69.2755 43.3688 69.5496 42.9793C70.2495 41.9844 71.1258 42.2572 72.5218 41.9463C74.1874 41.5756 75.6369 41.0631 76.6659 42.1291C76.9447 42.4179 77.1848 42.7332 77.3872 43.0574Z"
        fill="#9B6160"
      />
      <path
        d="M70.1576 23.3325C70.3462 23.2539 70.5629 23.343 70.6416 23.5316C70.7203 23.7203 70.6311 23.937 70.4425 24.0157C68.4154 24.8611 65.953 24.9871 63.4945 24.1915C63.3001 24.1286 63.1934 23.9199 63.2564 23.7255C63.3193 23.531 63.528 23.4244 63.7224 23.4873C66.0103 24.2277 68.2911 24.111 70.1576 23.3325Z"
        fill="#7D4556"
      />
      <path
        d="M72.3621 29.3214C72.9562 29.3106 73.5858 29.3735 74.2114 29.4848C74.3459 29.5087 74.4737 29.5338 74.5937 29.5593L74.9479 29.6412L74.9851 29.651L74.7596 30.4872L74.6364 30.4565L74.5733 30.4417C74.4193 30.4063 74.2466 30.3707 74.0598 30.3375C73.485 30.2353 72.9088 30.1777 72.3778 30.1873C72.0504 30.1932 71.7526 30.225 71.4914 30.2842L71.3391 30.323L71.1016 29.4902C71.4764 29.3833 71.8992 29.3298 72.3621 29.3214Z"
        fill="#7D4556"
      />
      <path
        d="M65.3985 29.4394C66.4023 29.4844 67.0927 29.9903 67.4878 30.7789C67.7283 31.2589 67.817 31.7297 67.8276 32.0627C67.8352 32.3018 67.6477 32.5017 67.4086 32.5093C67.1895 32.5163 67.0032 32.3593 66.9678 32.149L66.9558 32.0095C66.9503 31.958 66.9408 31.8908 66.9259 31.8118C66.8842 31.5901 66.8149 31.3691 66.7136 31.1669C66.4515 30.6438 66.0295 30.3346 65.3599 30.3046C64.685 30.2747 64.2659 30.5792 64.0014 31.1466C63.9067 31.3497 63.8406 31.5719 63.7994 31.7947L63.7763 31.9372C63.7677 31.9994 63.7634 32.0473 63.7621 32.0771C63.7514 32.316 63.5491 32.501 63.3102 32.4904C63.0713 32.4797 62.8863 32.2774 62.8969 32.0385C62.9114 31.7133 62.9969 31.2518 63.2165 30.7807C63.6005 29.9569 64.2764 29.441 65.2564 29.4366L65.3985 29.4394Z"
        fill="#7D4556"
      />
      <path
        d="M80.025 30.0951L80.167 30.098C81.1709 30.143 81.8613 30.6488 82.2564 31.4375C82.4969 31.9174 82.5856 32.3883 82.5962 32.7213C82.6038 32.9603 82.4162 33.1603 82.1772 33.1679C81.9581 33.1749 81.7718 33.0179 81.7364 32.8075L81.7244 32.6681C81.7188 32.6166 81.7094 32.5494 81.6945 32.4704C81.6527 32.2487 81.5835 32.0277 81.4821 31.8254C81.2201 31.3024 80.7981 30.9932 80.1285 30.9632C79.4536 30.9332 79.0348 31.2378 78.7706 31.8051C78.676 32.0082 78.61 32.2303 78.5688 32.4531L78.5458 32.5955C78.5429 32.6163 78.5405 32.6355 78.5386 32.6529L78.5316 32.7355C78.5211 32.9744 78.3188 33.1595 78.0799 33.1489C77.841 33.1384 77.6559 32.9361 77.6664 32.6972C77.6808 32.372 77.7661 31.9105 77.9855 31.4394C78.3692 30.6156 79.0449 30.0995 80.025 30.0951Z"
        fill="#7D4556"
      />
      <path d="M86.332 32.4246L92.4009 32.6951" stroke="#7D4556" strokeWidth="1.383" />
      <path
        d="M76.7921 23.5964C76.8926 23.4184 77.1184 23.3557 77.2963 23.4562C77.9902 23.8482 78.8434 24.1637 79.82 24.4079C80.8366 24.6621 81.9297 24.8262 83.0235 24.9177C83.3301 24.9433 83.619 24.9617 83.8855 24.9739L84.0811 24.9819C84.2712 24.9888 84.4059 24.991 84.4757 24.9911C84.6801 24.9912 84.8458 25.1569 84.8457 25.3613C84.8456 25.5657 84.6798 25.7313 84.4754 25.7313L84.1493 25.7248L84.0544 25.7216C83.7244 25.7097 83.3571 25.6884 82.9618 25.6553C81.8312 25.5608 80.6999 25.3909 79.6404 25.1259C78.604 24.8668 77.6904 24.529 76.9322 24.1007C76.7543 24.0001 76.6915 23.7744 76.7921 23.5964Z"
        fill="#7D4556"
      />
      <path
        d="M71.7902 48.4737C71.9652 48.4787 72.1067 48.6106 72.1292 48.7786L72.1317 48.8577L72.1313 48.8794C72.1285 49.0567 72.131 49.2852 72.1427 49.5454C72.1598 49.9274 72.1941 50.3031 72.249 50.6522C72.374 51.4458 72.5942 51.9971 72.8679 52.1748C73.0311 52.2807 73.0775 52.4989 72.9716 52.662C72.8657 52.8252 72.6475 52.8716 72.4843 52.7657C71.9875 52.4432 71.7075 51.7423 71.5531 50.7618C71.4937 50.3843 71.4572 49.9834 71.4389 49.577L71.4285 49.2636C71.4264 49.1652 71.4256 49.0733 71.4258 48.989L71.428 48.8156C71.4335 48.6212 71.5957 48.4681 71.7902 48.4737Z"
        fill="#9B6160"
      />
      <path
        d="M70.4796 49.587C70.6738 49.5995 70.821 49.767 70.8084 49.9612L70.7988 50.1639C70.7815 50.6153 70.7843 51.1039 70.8206 51.5592C70.8469 51.8899 70.8898 52.1774 70.9499 52.4069C70.9991 52.5951 70.8865 52.7876 70.6983 52.8369C70.5101 52.8862 70.3176 52.7736 70.2684 52.5854C70.1963 52.3104 70.1477 51.9839 70.1183 51.6152C70.0795 51.1281 70.0766 50.6137 70.0948 50.137L70.1054 49.9158C70.118 49.7216 70.2855 49.5744 70.4796 49.587Z"
        fill="#9B6160"
      />
      <path
        d="M73.3076 50.0469C73.4973 50.0039 73.686 50.1228 73.729 50.3125L73.7686 50.4713C73.7807 50.5169 73.7949 50.5688 73.8112 50.6259C73.8791 50.8641 73.958 51.1019 74.0463 51.3219C74.2045 51.716 74.376 52.0014 74.5201 52.1166L74.5624 52.146C74.7305 52.2441 74.7872 52.4598 74.6892 52.6278C74.5911 52.7958 74.3754 52.8526 74.2074 52.7545C73.8778 52.5622 73.622 52.1557 73.3925 51.5844C73.2941 51.3393 73.2078 51.0791 73.1337 50.8191L73.0685 50.5781C73.0574 50.5342 73.0486 50.4972 73.042 50.4683C72.999 50.2786 73.1179 50.0899 73.3076 50.0469Z"
        fill="#9B6160"
      />
      <path
        d="M69.3724 49.2337L69.4291 49.2416C69.6 49.2797 69.714 49.436 69.7042 49.6053L69.6963 49.6621L69.2332 51.7398C69.1909 51.9297 69.0027 52.0493 68.8128 52.007C68.6419 51.9689 68.5279 51.8126 68.5377 51.6433L68.5456 51.5865L69.0087 49.5088C69.0468 49.3379 69.2031 49.2239 69.3724 49.2337Z"
        fill="#9B6160"
      />
      <path
        d="M81.5012 49.9828C81.6713 49.8885 81.8857 49.95 81.98 50.1202C82.0743 50.2904 82.0128 50.5047 81.8426 50.599C81.5649 50.7529 81.2837 50.8989 80.9991 51.0371C77.2225 52.8714 73.2406 53.2432 69.7091 52.7847C69.5003 52.7576 69.309 52.729 69.1363 52.6999L68.8965 52.6572L68.6997 52.6172C68.5101 52.5739 68.3914 52.3851 68.4347 52.1954C68.478 52.0057 68.6668 51.8871 68.8565 51.9304L69.096 51.9777C69.2981 52.0146 69.5336 52.0515 69.7998 52.0861C73.2067 52.5284 77.0562 52.169 80.6913 50.4034C80.9646 50.2707 81.2346 50.1305 81.5012 49.9828Z"
        fill="#9B6160"
      />
      <path
        d="M76.8604 38.3517C76.9393 38.1739 77.1474 38.0937 77.3252 38.1726L77.5747 38.2909C77.653 38.3297 77.7424 38.3754 77.8409 38.4275C78.2233 38.6296 78.6058 38.8579 78.9646 39.1085C80.2559 40.0108 80.9462 40.9736 80.6194 41.9929C80.56 42.1782 80.3617 42.2802 80.1765 42.2209C80.0097 42.1674 79.9104 42.0014 79.9355 41.8337L79.9485 41.7779C80.1466 41.1602 79.6202 40.426 78.5611 39.686C78.2284 39.4535 77.8701 39.2397 77.5117 39.0503L77.2647 38.9234C77.1686 38.8757 77.092 38.8398 77.0396 38.8165C76.8617 38.7376 76.7815 38.5295 76.8604 38.3517Z"
        fill="#9B6160"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.0658 61.1612L90.8785 57.7365L67.7877 58.3668L66.8596 61.6102C66.8596 61.6102 53.6399 64.7587 48.8786 67.4028C42.1523 71.1403 49.6864 115.023 49.8489 123.735C44.2873 133.746 54.3707 136.823 54.3707 136.823L99.9212 137.135C99.9212 137.135 109.095 133.354 104.506 126.203C106.385 113.413 114.416 72.6413 109.443 67.6677C105.916 64.1406 92.0658 61.1612 92.0658 61.1612Z"
        fill="#C7BCE4"
      />
      <path
        d="M51.6425 80.0103C51.8364 80.0264 51.9805 80.1967 51.9644 80.3905L51.891 81.2914L51.712 83.6314C51.5557 85.7537 51.3932 88.1603 51.2263 90.8026L51.1905 91.3723C51.0155 94.1674 50.8425 97.1079 50.6754 100.088L50.3905 105.309L50.2021 108.942C50.1924 109.136 50.027 109.286 49.8327 109.276C49.6384 109.267 49.4888 109.101 49.4985 108.907L49.711 104.82L49.9096 101.168C50.0753 98.1783 50.2473 95.215 50.4218 92.3837L50.5945 89.6392C50.7484 87.2469 50.8984 85.0637 51.0428 83.1284L51.1888 81.2352C51.2135 80.9256 51.238 80.6245 51.2623 80.3322C51.2784 80.1383 51.4487 79.9942 51.6425 80.0103Z"
        fill="#7876C1"
      />
      <path
        d="M89.2669 61.9581C89.4545 61.9067 89.6482 62.0172 89.6996 62.2048C89.751 62.3924 89.6405 62.5862 89.4529 62.6376C87.4659 63.1816 85.1754 63.539 82.6394 63.7318C78.8564 64.0193 74.813 63.9295 70.9199 63.6057L70.2684 63.549C69.8461 63.5106 69.4481 63.4711 69.077 63.4314L68.4948 63.3663L68.0223 63.3082C67.8295 63.2824 67.6941 63.1051 67.72 62.9123C67.7458 62.7195 67.923 62.5842 68.1158 62.61L68.7243 62.6837L69.4328 62.7604C69.9086 62.8093 70.4254 62.8577 70.9783 62.9036C74.8372 63.2246 78.8454 63.3136 82.5861 63.0293C85.0803 62.8398 87.3276 62.4891 89.2669 61.9581Z"
        fill="#7876C1"
      />
      <path
        d="M88.2948 116.805C88.4763 116.735 88.6802 116.826 88.75 117.007C88.8198 117.189 88.7293 117.393 88.5477 117.462C86.1133 118.399 82.1362 119.131 77.0308 119.692C73.97 120.028 70.692 120.281 67.4141 120.464L66.5696 120.509C66.1554 120.531 65.7538 120.55 65.366 120.568L63.8623 120.631L63.042 120.66C62.8475 120.665 62.6854 120.512 62.6798 120.318C62.6743 120.123 62.8274 119.961 63.0219 119.955L63.8358 119.927L65.3337 119.864C65.9778 119.835 66.6604 119.8 67.375 119.76C70.6411 119.579 73.9071 119.326 76.9539 118.992L77.7627 118.9C82.4226 118.359 86.0608 117.664 88.2948 116.805Z"
        fill="#9B6160"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.247 143.38L100.523 136.183L53.6925 136.745L53.4746 143.953C53.4746 143.953 78.259 144.907 101.247 143.38Z"
        fill="#7876C1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.1726 63.7414C74.1726 63.7414 82.5257 70.0694 86.8973 67.8039C90.8573 65.7515 88.6857 62.1145 88.6857 62.1145C88.6857 62.1145 86.9292 56.2899 83.5956 52.5092C81.4117 50.0313 77.9617 44.7008 76.3921 45.4786C74.3482 46.4921 78.7638 51.3661 78.049 52.8643C76.6006 55.9029 68.8448 48.9671 66.9841 48.5172C63.3462 47.638 64.0957 56.227 74.1726 63.7414Z"
        fill="#C99382"
      />
      <path
        d="M76.7741 45.718C77.7657 45.227 79.0133 46.2384 81.033 48.6855L81.3269 49.0449C81.4518 49.199 81.5802 49.3589 81.7173 49.5311L83.2468 51.4734L83.5349 51.8339C83.8091 52.1744 84.0436 52.4572 84.2576 52.704L84.3629 52.8245C85.1358 53.7016 85.8504 54.7125 86.5085 55.8312C87.3399 57.2446 88.0408 58.7567 88.6159 60.2687C88.7769 60.692 88.9187 61.0913 89.0416 61.4602L89.2243 62.031C89.2592 62.1451 89.2847 62.2329 89.3012 62.2923C89.3486 62.464 89.2479 62.6417 89.0761 62.6892C88.9044 62.7367 88.7267 62.636 88.6792 62.4642L88.6065 62.2172C88.5814 62.1353 88.5512 62.0386 88.5158 61.9283C88.3774 61.4971 88.2098 61.0162 88.0127 60.4981C87.4497 59.0178 86.7636 57.5377 85.9523 56.1583C85.3848 55.1937 84.7757 54.3139 84.1248 53.5375L83.6592 52.9979C83.5082 52.8209 83.347 52.6267 83.1698 52.4086L82.6382 51.7442L81.2127 49.9333C81.0766 49.7625 80.9493 49.6039 80.8256 49.4512L80.5354 49.0969C78.7784 46.9753 77.6206 46.0189 77.0606 46.2962C76.4624 46.5927 76.5877 47.4003 77.4331 49.1614L77.8005 49.901L78.0806 50.4582C78.9765 52.2612 79.1678 52.8988 78.8655 53.5327C78.079 55.1822 76.0562 54.6533 72.7896 52.6586L72.3104 52.3613C72.1464 52.2581 71.9775 52.1503 71.8016 52.0369L69.0927 50.2542L68.7257 50.0225C68.1008 49.6357 67.6822 49.4195 67.4336 49.3594C65.9381 48.9984 65.3491 50.6564 66.2666 53.4086C67.3916 56.7832 70.3865 60.6525 74.8906 64.0113C75.0335 64.1179 75.063 64.32 74.9564 64.4629C74.8499 64.6057 74.6477 64.6352 74.5049 64.5286C69.9003 61.0949 66.8236 57.12 65.6544 53.6127C64.6055 50.4661 65.4082 48.2066 67.5851 48.7322C67.9442 48.819 68.4485 49.0846 69.2183 49.5693L69.8216 49.9594C70.5129 50.4132 71.6443 51.1649 71.8846 51.3216L72.4067 51.6584C72.5741 51.7652 72.7355 51.8668 72.8928 51.9643L73.1259 52.1079C76.0394 53.8869 77.7664 54.3385 78.283 53.255C78.4578 52.8885 78.254 52.264 77.49 50.7212L77.1136 49.9728C75.8471 47.4615 75.5864 46.3067 76.7741 45.718Z"
        fill="#9B6160"
      />
      <path
        d="M65.8432 49.7739C65.9821 49.6622 66.1852 49.6843 66.2968 49.8232C67.0532 50.7644 67.9591 51.6462 68.9829 52.4668C70.1116 53.3713 71.3224 54.155 72.5332 54.814C72.8721 54.9985 73.1914 55.1623 73.4859 55.3054L73.9376 55.5177C74.0265 55.5579 74.094 55.5872 74.1382 55.6056C74.3028 55.674 74.3807 55.8628 74.3123 56.0274C74.2439 56.1919 74.055 56.2698 73.8905 56.2014L73.6744 56.1069C73.6038 56.075 73.5211 56.0369 73.4272 55.9926C73.0639 55.8216 72.6597 55.6176 72.2247 55.3808C70.9824 54.7046 69.7402 53.9006 68.5794 52.9703C67.5207 52.1218 66.5816 51.2076 65.7938 50.2275C65.6822 50.0886 65.7043 49.8855 65.8432 49.7739Z"
        fill="#9B6160"
      />
      <path
        d="M65.4659 52.3376C65.6096 52.2322 65.8115 52.2633 65.9169 52.4071L66.0224 52.5434C66.069 52.6016 66.1271 52.6723 66.1968 52.7545L66.2703 52.8406C66.5744 53.194 66.9451 53.5888 67.3839 54.0146C68.6414 55.2349 70.1909 56.4566 72.0454 57.5977C72.551 57.9088 73.0739 58.2102 73.6142 58.5008C73.7711 58.5852 73.8299 58.7809 73.7455 58.9378C73.661 59.0948 73.4654 59.1535 73.3085 59.0691C72.7571 58.7725 72.2234 58.4649 71.7072 58.1473C69.8113 56.9807 68.2248 55.7299 66.9345 54.4777C66.5462 54.1009 66.2088 53.7468 65.9209 53.4217L65.7812 53.2616C65.5894 53.0387 65.4617 52.8777 65.3964 52.7886C65.2911 52.6449 65.3222 52.443 65.4659 52.3376Z"
        fill="#9B6160"
      />
      <path
        d="M67.5547 56.9069C67.6758 56.7762 67.88 56.7684 68.0107 56.8895C69.5648 58.3295 71.0074 59.4564 72.3008 60.3058C72.6872 60.5596 73.0358 60.7721 73.3436 60.9464L73.4942 61.0303C73.6974 61.1419 73.8352 61.2108 73.9027 61.2416C74.0649 61.3154 74.1366 61.5066 74.0628 61.6688C73.989 61.831 73.7977 61.9027 73.6355 61.829L73.4917 61.7592C73.4109 61.7183 73.3079 61.6642 73.1836 61.596C72.8255 61.3994 72.4116 61.1506 71.9466 60.8452C70.6235 59.9763 69.1527 58.8274 67.5721 57.3628C67.4414 57.2417 67.4336 57.0376 67.5547 56.9069Z"
        fill="#9B6160"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.367 102.403C121.367 102.403 130.167 116.241 120.553 122.148C110.638 128.24 103.704 117.29 103.704 117.29C103.704 117.29 96.8989 107.351 91.0781 97.7314C87.5247 91.8589 83.7299 85.296 81.6578 80.1364C79.4148 74.5494 81.9237 72.3167 86.731 69.3636C92.4391 65.8562 95.1556 64.9874 98.9795 69.5974C102.626 73.9924 106.73 80.5008 110.138 85.4002C116.636 94.7425 121.367 102.403 121.367 102.403Z"
        fill="#C7BCE4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.9922 68.9437L90.1088 62.1141L95.5248 69.1335L82.8076 76.9466L78.9922 68.9437Z"
        fill="#C7BCE4"
      />
      <path
        d="M89.3978 61.8134C89.5342 61.7296 89.7073 61.7492 89.8213 61.8548L89.8611 61.8984L93.0921 66.0872L93.2537 66.0849C95.1083 66.0861 96.7386 67.0418 98.5525 69.1692L98.7231 69.372L99.2479 70.012C102.891 74.4993 110.397 84.5503 113.967 89.6818C114.078 89.8415 114.039 90.061 113.879 90.1721C113.72 90.2832 113.5 90.2438 113.389 90.0842L112.816 89.2668C108.876 83.6765 101.398 73.7001 98.1809 69.8218C96.3146 67.5721 94.7503 66.6867 92.9471 66.799C92.8498 66.805 92.7553 66.7706 92.6852 66.7053L92.6463 66.6626L89.4965 62.5792L78.9193 69.0779L81.2069 73.877C81.2447 73.9563 81.2512 74.0461 81.2266 74.1288L81.2024 74.1893C80.4144 75.7249 80.4661 77.5379 81.4572 80.0055L81.6682 80.522C83.4721 84.8667 86.4246 90.2344 90.852 97.5494L91.4022 98.4548C91.9454 99.3451 92.5072 100.256 93.0861 101.184C95.0728 104.371 97.184 107.651 99.3108 110.88L100.781 113.101C101.147 113.651 101.501 114.18 101.839 114.684L103.466 117.092C103.576 117.252 103.535 117.472 103.375 117.582C103.214 117.691 102.995 117.65 102.885 117.49L101.991 116.172L100.735 114.301C100.382 113.773 100.015 113.222 99.6357 112.65C97.1992 108.974 94.7627 105.205 92.4883 101.557L91.6312 100.175C91.3499 99.719 91.073 99.2679 90.8008 98.8218L90.2493 97.9142C85.6257 90.275 82.6031 84.7491 80.8035 80.2681C79.8106 77.7958 79.696 75.8678 80.415 74.2075L80.4974 74.0256L78.1476 69.0956C78.0787 68.9511 78.1166 68.7811 78.2336 68.6789L78.2812 68.644L89.3978 61.8134Z"
        fill="#7876C1"
      />
      <path
        d="M100.151 136.229C100.346 136.229 100.504 136.387 100.504 136.581C100.504 136.757 100.376 136.902 100.209 136.929L100.151 136.934H54.1257C53.9311 136.934 53.7734 136.776 53.7734 136.581C53.7734 136.406 53.9012 136.261 54.0685 136.234L54.1257 136.229H100.151Z"
        fill="#253368"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.658 126.166C101.658 126.166 90.2986 127.458 87.4553 122.9C84.8816 118.771 89.704 114.772 89.704 114.772C89.704 114.772 93.8313 112.076 99.0632 110.381C102.493 109.271 108.696 106.188 109.736 107.79C111.092 109.879 104.235 111.997 104.072 113.801C103.741 117.458 114.879 115.263 116.876 115.876C120.779 117.075 115.298 124.718 101.658 126.166Z"
        fill="#C99382"
      />
      <path
        d="M116.792 117.484C116.975 117.416 117.178 117.508 117.246 117.691C117.314 117.873 117.222 118.076 117.04 118.144C116.524 118.337 115.99 118.507 115.439 118.655C112.616 119.414 109.637 119.552 106.967 119.334L106.635 119.305L106.181 119.255C106.154 119.251 106.132 119.248 106.115 119.246C105.923 119.219 105.789 119.04 105.816 118.848C105.843 118.655 106.022 118.521 106.214 118.548L106.422 118.574L106.703 118.604C106.804 118.613 106.911 118.623 107.025 118.632C109.621 118.844 112.524 118.71 115.257 117.975C115.786 117.833 116.298 117.67 116.792 117.484Z"
        fill="#9B6160"
      />
      <path
        d="M115.716 120.122C115.895 120.046 116.102 120.129 116.178 120.308C116.254 120.487 116.171 120.694 115.992 120.77L115.817 120.839L115.59 120.923C115.509 120.952 115.42 120.982 115.326 121.014C114.791 121.192 114.165 121.37 113.452 121.537C111.022 122.102 108.209 122.393 105.072 122.263C104.878 122.255 104.727 122.091 104.735 121.897C104.743 121.702 104.907 121.551 105.101 121.559C108.173 121.687 110.922 121.402 113.292 120.85C113.884 120.712 114.414 120.566 114.877 120.419L115.314 120.273C115.505 120.206 115.64 120.154 115.716 120.122Z"
        fill="#9B6160"
      />
      <path
        d="M111.256 123.164C111.446 123.123 111.634 123.244 111.675 123.434C111.716 123.624 111.595 123.812 111.404 123.853C108.089 124.567 105.502 124.777 103.705 124.701L103.515 124.692C103.375 124.684 103.28 124.677 103.228 124.671C103.035 124.65 102.895 124.476 102.916 124.283C102.937 124.089 103.111 123.95 103.304 123.971L103.554 123.989C105.295 124.085 107.885 123.89 111.256 123.164Z"
        fill="#9B6160"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2523 106.325C40.2523 106.325 26.7583 106.207 26.2905 117.48C25.8077 129.107 38.7475 129.821 38.7475 129.821C38.7475 129.821 47.8663 130.098 59.1022 130.489C65.9628 130.727 73.5421 130.839 79.0577 130.132C85.0288 129.365 85.7539 126.086 85.9878 120.449C86.2658 113.755 85.6995 110.96 79.81 109.869C74.1949 108.83 66.5114 108.422 60.5722 107.836C49.247 106.719 40.2523 106.325 40.2523 106.325Z"
        fill="#C7BCE4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.1276 126.47L90.6686 113.435L81.8993 112.13L81.2812 127.043L90.1276 126.47Z"
        fill="#C7BCE4"
      />
      <path
        d="M43.0665 129.73L54.762 129.925C56.1446 129.945 57.451 129.963 58.7147 129.978L62.5198 130.014C71.1321 130.074 76.9426 129.958 79.2854 129.603C81.914 129.205 83.5028 128.329 84.4255 126.871C84.4725 126.797 84.545 126.744 84.6282 126.72L84.6923 126.709L89.9878 126.244L90.2207 113.832L85.1038 113.191C85.0071 113.179 84.9207 113.127 84.8639 113.05L84.8335 113.001C84.0157 111.389 82.4596 110.491 79.5731 110.025L79.05 109.943C76.6663 109.582 73.869 109.315 70.1005 109.059L67.6598 108.902L62.5376 108.588C61.8598 108.544 61.2689 108.503 60.6997 108.461L60.3226 108.433C55.7437 108.087 51.2374 107.824 46.9674 107.634L45.9049 107.588C44.4617 107.527 43.141 107.479 41.9618 107.441L39.9937 107.386C39.7992 107.382 39.6448 107.221 39.6485 107.026C39.653 106.832 39.814 106.678 40.0085 106.681L41.5955 106.725L42.7926 106.764C43.7598 106.797 44.8101 106.837 45.9346 106.884C50.171 107.063 54.6575 107.313 59.2307 107.646L61.5267 107.814C61.9932 107.846 62.4904 107.879 63.0491 107.915L69.2927 108.299L70.567 108.385C74.4197 108.653 77.2489 108.935 79.6854 109.329C82.6024 109.8 84.3282 110.729 85.2983 112.383L85.3727 112.514L90.6225 113.173C90.7814 113.193 90.9041 113.316 90.9271 113.47L90.9309 113.529L90.6866 126.574C90.6837 126.734 90.5738 126.869 90.4234 126.908L90.3653 126.918L84.9237 127.395C83.8994 128.907 82.2301 129.826 79.6517 130.258L79.3909 130.299L79.1525 130.333C76.5416 130.68 70.524 130.784 61.6891 130.712L59.781 130.694C58.7156 130.683 57.626 130.67 56.4909 130.654L43.0581 130.435C42.8636 130.432 42.7078 130.273 42.7101 130.078C42.7124 129.884 42.8719 129.728 43.0665 129.73Z"
        fill="#7876C1"
      />
    </g>
  </svg>
);

const CancelPlanIcon = (props) => <Icon component={svg} {...props} />;

export default CancelPlanIcon;
