import React, { useState, useEffect } from 'react';
import { Modal, Form, Steps } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { fromAppVar } from 'graphql/cache';
import { UserInfo, ProjectInfo } from './components';
import Button from 'components/Button';
import utils from 'utils/utils';
import CircleTickIcon from 'components/Icons/CircleTickIcon';
import CurrentProgressIcon from 'components/Icons/CurrentProgressIcon';
import WaitProgressIcon from 'components/Icons/WaitProgressIcon';
import projectsType from 'constants/project-type';
import SignupFinish from './components/SignupFinish/SignupFinish';

import './UserInfoModal.scss';

const { Step } = Steps;

const UserInfoModal = ({
  user,
  saveUserProfileInfo,
  createProposal,
  addUser,
  onCancel,
  refetchUser,
}) => {
  const [proposalLanguage, setLangauge] = useState(
    window.sessionStorage.getItem('language.value')?.toLowerCase() || 'english'
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [clickedCheckbox, setClickedCheckbox] = useState(
    projectsType.map((_, index) => (index < projectsType.length - 1 ? true : false))
  );
  const [termsValue, setTermsValue] = useState(true);
  const [passwordSaved, setPasswordSaved] = useState(false);
  const [form] = Form.useForm();
  const [password, setPassword] = useState('');
  const [fullname, setFullname] = useState(user?.profile?.name);
  const [wixConnected, setWixConnected] = useState(utils.getURLParam('wix') ? true : false);

  useEffect(() => {
    if (!user?.profile?.terms) {
      setCurrentStep(1);
    } else if (!user?.ptype) {
      setCurrentStep(2);
      setPasswordSaved(true);
    } else {
      setCurrentStep(3);
    }
  }, [user]);

  useEffect(() => {
    if (
      fromAppVar() === 'monday' &&
      (user?.monday === null || Object.keys(user?.monday).length === 0)
    ) {
      const authUrl = `https://auth.monday.com/oauth2/authorize?client_id=${process.env.REACT_APP_MONDAY_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MONDAY_REDIRECT_URI}`;
      utils.refetchUserOnClosingTab(authUrl).then(() => {
        refetchUser();
      });
    }
  }, [refetchUser, user?.monday]);

  const customDot = (_, info) => {
    switch (info.status) {
      case 'finish':
        return <CircleTickIcon />;
      case 'process':
        return <CurrentProgressIcon />;
      case 'wait':
        return <WaitProgressIcon />;
      default:
        break;
    }
  };

  const submitProfileInfo = (values) => {
    if (currentStep === 2) {
      let ptype = '';
      clickedCheckbox.forEach((el, index) => {
        if (el) {
          ptype += projectsType[index].key;
          if (index !== clickedCheckbox.length - 1) {
            ptype += ' ';
          }
        }
      });
      values['ptype'] = ptype;
      values['enableAITool'] = !!(proposalLanguage || user?.profile?.language === 'english');
    } else if (currentStep === 1) {
      setPasswordSaved(true);
    }

    saveUserProfileInfo(values, currentStep);
  };

  const newProposal = () => {
    setWixConnected(false);
    createProposal();
  };

  const onAddUser = () => {
    setWixConnected(false);
    addUser();
  };

  const onCancelModal = () => {
    setWixConnected(false);
    onCancel();
  };

  const updateFormValues = (changedFields, allFields) => {
    for (let field in allFields) {
      switch (field) {
        case 'proposalLanguage':
          setLangauge(allFields[field]);
          break;
        case 'password':
          setPassword(allFields[field]);
          break;
        case 'fullname':
          setFullname(allFields[field]);
          break;
        default:
        //do nothing
      }
    }
  };

  return (
    <Modal
      className={`simple-info-modal sign-up-info-modal ${
        clickedCheckbox[clickedCheckbox.length - 1] ? 'extended' : ''
      }`}
      centered
      visible
      footer={null}
      closeIcon={null}
      closable={false}
      maskClosable={false}>
      {currentStep < 3 && (
        <div>
          <h3 className="title">Welcome</h3>
          <div className="divider" />
          <Steps current={currentStep} progressDot={customDot}>
            <Step title="Step 1" />
            <Step title="Step 2" />
            <Step title="Step 3" />
          </Steps>

          <p className={`body ${currentStep < 2 ? '' : 'project-type-question'}`}>
            {currentStep < 2
              ? 'Please, fill out all the fields below'
              : 'What type of projects do you offer?'}
          </p>

          <Form
            layout="vertical email-input"
            initialValues={{ fullname, proposalLanguage, where: wixConnected ? 'Wix' : '' }}
            onFinish={submitProfileInfo}
            onValuesChange={updateFormValues}
            form={form}
            autoComplete="off">
            {currentStep === 1 && (
              <UserInfo
                fullname={fullname}
                setFullname={setFullname}
                termsValue={termsValue}
                setLangauge={setLangauge}
                setTermsValue={setTermsValue}
              />
            )}

            {currentStep === 2 && (
              <ProjectInfo
                user={user}
                projectsType={projectsType}
                clickedCheckbox={clickedCheckbox}
                setClickedCheckbox={setClickedCheckbox}
              />
            )}

            <div className={`button-wrapper ${currentStep < 2 ? 'welcome-form' : ''}`}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className=""
                text={currentStep > 3 ? "Let's Start" : 'Next'}
                disabled={
                  (currentStep === 2 && !clickedCheckbox.find((clickedBox) => clickedBox)) ||
                  (currentStep === 1 && (!termsValue || (!password && !passwordSaved)))
                    ? true
                    : false
                }
                icon={
                  <div className="button-icon-right">
                    <RightOutlined />
                  </div>
                }
              />

              {currentStep > 1 && (
                <Button
                  type="primary"
                  size="large"
                  className="cancel"
                  onClick={() => setCurrentStep(1)}
                  text={'Back'}
                />
              )}
            </div>
          </Form>
        </div>
      )}
      {currentStep === 3 && (
        <SignupFinish
          wixConnected={wixConnected}
          createNewProposal={() => newProposal()}
          addUser={() => onAddUser()}
          onCancel={() => onCancelModal()}
        />
      )}
    </Modal>
  );
};

UserInfoModal.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  saveUserProfileInfo: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  createProposal: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
};

export default UserInfoModal;
