import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button } from 'antd';

import { getProposalTitle } from 'helpers/proposal';
import { userVar, fromAppVar } from 'graphql/cache';
import { ClientName, WixClientName, MondayClientName } from 'pages/Proposal/modules';
import CloseIcon from 'components/Icons/CloseIcon';

import './CreateDuplicateProposalModal.scss';

const CreateDuplicateProposalModal = ({
  user,
  visible,
  onCancel,
  selectedProposal,
  onConfirm,
  modalType,
  isLoading,
}) => {
  const userVariables = userVar();
  const proposalTitle =
    getProposalTitle({ proposal: selectedProposal }) || selectedProposal?.templateName;

  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientCompany, setClientCompany] = useState('');
  const [mondayItemId, setMondayItemId] = useState('');
  const [form] = Form.useForm();

  const onInputChange = (value, email, company, id) => {
    setClientName(value);
    if (email) {
      setClientEmail(email);
    }
    if (company) {
      setClientCompany(company);
    }
    if (id) {
      setMondayItemId(id);
    }
  };

  const onCreateDuplicateClick = () => {
    form
      .validateFields()
      .then((values) => {
        values = {
          ...values,
          client_email: clientEmail,
          client_company: clientCompany,
          monday_item_id: mondayItemId,
        };
        onConfirm({ values, proposal: selectedProposal });
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const language =
    selectedProposal?.language === 'hebrew'
      ? 'hebrew'
      : selectedProposal?.language === 'arabic'
      ? 'arabic'
      : '';

  return (
    <Modal
      className={`simple-info-modal create-duplicate-modal ${language}`}
      visible={visible}
      okText="CONTINUE"
      centered
      cancelText="CANCEL"
      closeIcon={<CloseIcon className="close-icon" />}
      footer={null}
      onCancel={onCancel}>
      <h3 className="title">{modalType} Proposal</h3>
      <div className="divider" />

      <div className="body">
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          className="form"
          initialValues={{
            modifier: 'public',
            project_name: `${proposalTitle}-COPY`,
            client_name: clientName,
          }}>
          <Form.Item
            name="project_name"
            label="Project Name"
            rules={[
              {
                required: true,
                message: 'Project name is required.',
              },
              {
                whitespace: true,
                message: 'Project name is required.',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="client_name"
            label="Prospect Name"
            rules={[
              {
                required: true,
                message: 'Prospect name is required',
              },
              {
                whitespace: true,
                message: 'Prospect name is required.',
              },
            ]}>
            {fromAppVar() === 'monday' &&
            userVariables.monday &&
            Object.keys(userVariables.monday)?.length > 0 ? (
              <MondayClientName
                type="setContact"
                action="contact"
                clientNameValue={''}
                alert={true}
                onChange={onInputChange}
                fromTemplate={true}
              />
            ) : userVariables?.wix?.length ? (
              <WixClientName
                type="setContact"
                action="contact"
                clientNameValue={''}
                onChange={onInputChange}
                fromTemplate={true}
                language={language}
              />
            ) : (
              <ClientName
                type="setContact"
                action="contact"
                onChange={onInputChange}
                placeholder="CONTACT PERSON NAME"
                fromTemplate={true}
                language={language}
              />
            )}
          </Form.Item>
        </Form>
      </div>

      <div className="button-wrapper">
        <Button
          className="button confirm"
          type="primary"
          loading={isLoading}
          onClick={onCreateDuplicateClick}>
          {modalType}
        </Button>
        <Button className="button cancel" type="primary" disabled={isLoading} onClick={onCancel}>
          CANCEL
        </Button>
      </div>
    </Modal>
  );
};

CreateDuplicateProposalModal.defaultProps = {
  visible: false,
  isLoading: false,
};

CreateDuplicateProposalModal.propTypes = {
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  selectedProposal: PropTypes.shape({}).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default CreateDuplicateProposalModal;
