import { useQuery } from '@apollo/client';
import React from 'react';
import { Row, Col, Skeleton, Empty } from 'antd';

import { GET_ALL_MEDIAS_LIBRARIES } from 'graphql/queries/contentLibraryQueries';
import helpers from 'helpers';
import GreenTickIcon from 'components/Icons/GreenTickIcon';
import HyphenRoundIcon from 'components/Icons/HyphenRoundIcon';

import './LibraryFolderItems.scss';

const LibraryFolderItems = ({ selectedFolder, selectedItems, addToSeletedItem }) => {
  const { data: mediasData, loading } = useQuery(GET_ALL_MEDIAS_LIBRARIES, {
    variables: {
      type: 'medias',
      ...(selectedFolder === 'all-starred' ? { allStarred: true } : { key: selectedFolder }),
    },
    fetchPolicy: 'network-only',
  });

  const medias = (mediasData?.fetchContentLibraryMediasItems?.slice() || []).filter((m) =>
    helpers.isImage(m.src)
  );

  return (
    <Row gutter={[12, 12]} className="library-folder-items">
      {loading ? (
        [1, 2, 3].map(() => (
          <Col>
            <Skeleton.Avatar active shape="square" size={100} />
          </Col>
        ))
      ) : (
        <>
          {medias.length > 0 ? (
            medias.map((m) => (
              <Col span={12} className="folder-item">
                {selectedItems[m.src] && (
                  <>
                    <HyphenRoundIcon onClick={() => addToSeletedItem(m)} className="remove-icon" />
                    <GreenTickIcon className="tick-icon" />
                  </>
                )}
                <img
                  width="100%"
                  height="100px"
                  src={m.src}
                  alt={m.name}
                  className="item-img"
                  onClick={() => addToSeletedItem(m)}
                />
              </Col>
            ))
          ) : (
            <Empty
              style={{ margin: '50px auto' }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Empty"
            />
          )}
        </>
      )}
    </Row>
  );
};

export default LibraryFolderItems;
