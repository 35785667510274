import { gql } from '@apollo/client';

export const USER_CREATE = gql`
  mutation createUserMutation($email: String!, $password: String!, $profile: JSON!) {
    createUser(email: $email, password: $password, profile: $profile) {
      token
      user {
        _id
        profile {
          language
        }
        userHash
        monday
      }
    }
  }
`;

export const USER_DETAILS_UPDATE = gql`
  mutation userUpdate($id: String!, $profile: ProfileInput!) {
    updateUser(user: { id: $id, profile: $profile }) {
      emails {
        address
      }
      profile {
        companyname
      }
    }
  }
`;

export const USER_PASSWORD_UPDATE = gql`
  mutation userPassword($oldPassword: String!, $newPassword: String!) {
    updatePassword(data: { oldPassword: $oldPassword, newPassword: $newPassword })
  }
`;

export const USER_SETTINGS_UPDATE = gql`
  mutation userUpdate(
    $id: String!
    $profile: ProfileInput!
    $domain: String!
    $ptype: String!
    $otherptype: String
    $sigtype: String
    $sigOptions: [String!]
    $contactOptions: [ContactOptionsInput!]
    $signature: String
    $cname: String
    $dns: String
    $signatureEmails: [String!]
    $expiry: String
    $uploadedFonts: [UploadedFontsInput!]
  ) {
    updateUser(
      user: {
        id: $id
        profile: $profile
        domain: $domain
        ptype: $ptype
        otherptype: $otherptype
        sigtype: $sigtype
        sigOptions: $sigOptions
        contactOptions: $contactOptions
        signature: $signature
        cname: $cname
        dns: $dns
        signatureEmails: $signatureEmails
        expiry: $expiry
        uploadedFonts: $uploadedFonts
      }
    ) {
      profile {
        companyname
      }
    }
  }
`;

export const USER_UPDATE = gql`
  mutation userUpdate($user: UserInput!) {
    updateUser(user: $user) {
      _id
      emails {
        address
      }
      profile {
        name
        companyname
        address
        vatnumber
        contactnumber
        whatsappNumber
        image
        terms
        priceSeperator
        decimalPrecision
        currency
        redirectAfter
        redirectTo
        contactButton
        callButton
        dateFormat
        role
        firstPayment
        affiliate
        infoUpdate
        allowPreviewDownload
        showPdfFooter
        pdfFooterVariables
        enableAITool
        defaultProposalFont
        seenTour
      }
      otherptype
      config
      ptype
      teamId
      freshbooks
      domain
      sigtype
      sigOptions
      contactOptions {
        option
        value
        enabled
      }
      signature
      signatureEmails
      defaultLogo
      credits
      paypalId
      deletedTemplates
      affiliatePayments {
        requestedCredits
        paided
        createdAt
        payedAt
      }
      reviewWidget
      userway
      templateFilter
      uploadedFonts {
        family
        weights
        source
        title
        format
        url
      }
    }
  }
`;

export const USER_RESET_PASSWORD = gql`
  mutation resetPasswordMutation($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword)
  }
`;

export const USER_FORGOT_PASSWORD = gql`
  mutation forgotPasswordMutation($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const USER_DELETE = gql`
  mutation userDeleteMutation {
    deleteUser
  }
`;
