import React, { useEffect, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';

import { userVar } from 'graphql/cache';
import { UPDATE_CHATWAY_SCRIPT } from 'graphql/mutations/integrationMutation';

import './ChatwayIntegration.scss';
import { ChatwayBrandIcon } from 'components/Icons';
import ChatwayIntegrationModals from './Modals/ChatwayIntegrationModals';
import IntegrationItem from '../IntegrationItem';

export const ChatwayIntegration = ({
  data = [],
  isModalItem,
  userRole,
  saveProposal,
  isSaving,
}) => {
  const user = useReactiveVar(userVar);

  const [integrationData, setIntegrationData] = useState(data);
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    if (data) {
      setIntegrationData(data);
    }
  }, [data]);

  const [updateScript, { loading }] = useMutation(UPDATE_CHATWAY_SCRIPT, {
    onCompleted: (data) => {
      if (data?.updateChatwayScript) {
        let userInfo = JSON.parse(JSON.stringify(user));
        userInfo['chatwayScripts'] = data?.updateChatwayScript;
        userVar({ ...user, ...userInfo });
      }
    },
  });

  const updateCodeIntegration = (scripts, onCompleted = undefined) => {
    if (isModalItem) {
      saveProposal({
        chatway: scripts.length !== 0,
      });
    }
    if (!isModalItem || scripts.length > 0) {
      updateScript({
        variables: {
          scripts,
        },
        onCompleted,
      });
    }
  };

  const handleClick = () => {
    if (integrationData && integrationData?.length) {
      updateCodeIntegration([]);
    } else {
      if (user?.chatwayScripts?.length > 0) {
        saveProposal({ chatway: true });
      } else {
        setShowAddModal(true);
      }
    }
  };

  const status = integrationData && integrationData.length ? 'connected' : 'disconnected';

  return (
    <>
      <IntegrationItem
        type="chatway"
        name="Chatway"
        description={
          integrationData && integrationData.length
            ? 'The live chat widget is displayed on your proposals'
            : 'Add a live chat widget to your proposals'
        }
        integrationLink="https://support.goprospero.com/article/show/171216-how-to-integrate-chatway-with-prospero"
        status={status}
        logo={<ChatwayBrandIcon />}
        // image={images.MORNING_INVOICE_ICON}
        handleClick={handleClick}
        isModalItem={isModalItem}
        userRole={userRole}
      />
      <ChatwayIntegrationModals
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        updateCodeIntegration={updateCodeIntegration}
        integrationData={integrationData}
        loading={isModalItem ? isSaving : loading}
      />
    </>
  );
};

ChatwayIntegration.defaultProps = {
  data: [],
  isModalItem: false,
  userRole: '',
  saveProposal: () => {},
};

ChatwayIntegration.propTypes = {
  isModalItem: PropTypes.bool,
  isSaving: PropTypes.bool,
  userRole: PropTypes.string,
  saveProposal: PropTypes.func,
};
