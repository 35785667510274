/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="21" height="21" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="4" fill="#F5F6FA" />
    <path
      d="M12.9995 17.6481C11.7187 17.7314 10.5563 18.2244 9.89667 19.981C9.82164 20.1814 9.64127 20.3031 9.43088 20.3031C9.07618 20.3031 7.97954 19.4102 7.66699 19.1947C7.66731 21.8519 8.87825 24.1874 11.7538 24.1874C14.1756 24.1874 15.8402 22.775 15.8402 20.3092C15.8402 20.2089 15.8195 20.113 15.8093 20.0146L12.9995 17.6481ZM22.2857 7.66669C21.8017 7.66669 21.348 7.8832 21.002 8.19748C14.4761 14.0894 13.797 14.2278 13.797 15.9622C13.797 16.4043 13.9008 16.8257 14.0757 17.2109L16.1132 18.9269C16.3434 18.985 16.5806 19.0247 16.828 19.0247C18.8109 19.0247 19.9602 17.5575 23.5694 10.7495C23.805 10.2864 24.0132 9.7863 24.0132 9.26551C24.0132 8.33268 23.1831 7.66669 22.2857 7.66669Z"
      fill="#AFB1BA"
    />
  </svg>
);

const ProjectDesignIcon = (props) => <Icon component={svg} {...props} />;

export default ProjectDesignIcon;
