import React, { useState, useEffect } from 'react';
import { ContentState } from 'draft-js';
import { ResizableBox } from 'react-resizable';
import PropTypes from 'prop-types';

import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

import './HTMLComponent.scss';

const HTMLComponent = ({ block, blockProps }) => {
  const contentState = ContentState.createFromBlockArray([block]);
  const ent = block.getEntityAt(0);
  const entity = contentState.getEntity(ent);
  const resizeWidth = blockProps.resizeWidth || 530;

  const { data } = entity;

  const [htmlCode, setHtmlCode] = useState(
    data?.htmlCode ? JSON.parse(JSON.stringify(data.htmlCode)) : '<></>'
  );
  const [config, setConfig] = useState(
    data.config
      ? JSON.parse(JSON.stringify(data.config))
      : { editable: true, size: { width: 530, height: 300 } }
  );

  useEffect(() => {
    setHtmlCode(JSON.parse(JSON.stringify(data.htmlCode)));
  }, [data.htmlCode]);

  const saveHtmlCode = (htmlCodeCopy, configCopy) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey) {
      contentState.replaceEntityData(entityKey, {
        htmlCode: htmlCodeCopy || JSON.parse(JSON.stringify(htmlCode)),
        config: configCopy || config,
      });
      blockProps.update(block);
    }
  };

  const handleDuplicate = () => {
    const htmlData = {
      htmlCode,
      config,
    };
    blockProps.handleDuplicateElement(htmlData, 'html', block.getKey());
    blockProps.handleEditComponent(false);
  };

  const handleEdit = () => blockProps.onEdit({ v: htmlCode, save: saveHtmlCode, cancel: (x) => x });

  const handleRemove = () => {
    blockProps.handleEditComponent(false);
    blockProps && blockProps.onRemove(block.getKey());
  };

  const onResize = (e, data) => {
    e.preventDefault();
    setConfig({
      ...config,
      size: {
        width: data.size.width || config?.size?.width,
        height: data.size.height || config?.size?.height,
      },
    });
  };

  const onResizeStart = (e) => {
    e.preventDefault();
  };

  const onResizeStop = (e, data) => {
    e.preventDefault();
    saveHtmlCode('', {
      ...config,
      size: {
        width: data.size.width || config?.size?.width,
        height: data.size.height || config?.size?.height,
      },
    });
  };

  return (
    <ComponentWrapper
      showDrag
      showActionButtons={true}
      config={config}
      showDuplicate
      showEdit
      showDelete
      isDraggable
      sectionName={blockProps.sectionName}
      setDraggingElement={blockProps.setDraggingElement}
      blockKey={block.getKey()}
      duplicate={handleDuplicate}
      remove={handleRemove}
      onEdit={handleEdit}
      handleEditComponent={blockProps.handleEditComponent}
      setDropDisabled={blockProps.setDropDisabled}
      componentType="HTML">
      <ResizableBox
        height={config?.size?.height}
        width={config?.size?.width >= resizeWidth ? resizeWidth : config?.size?.width}
        onResize={onResize}
        onResizeStart={onResizeStart}
        onResizeStop={onResizeStop}
        resizeHandles={['se', 'sw', 'ne', 'nw', 'w', 'e', 's']}
        minConstraints={[50, 50]}
        maxConstraints={[resizeWidth, Infinity]}
        className="html-container">
        <div
          className="html-content"
          onClick={handleEdit}
          dangerouslySetInnerHTML={{ __html: `<div>${htmlCode}</div>` }}
          style={{
            height: `${config?.size?.height - 4}px` || 'auto',
            width:
              `${config?.size?.width >= resizeWidth ? resizeWidth : config?.size?.width - 4}px` ||
              'auto',
          }}
        />
      </ResizableBox>
    </ComponentWrapper>
  );
};

HTMLComponent.propTypes = {
  block: PropTypes.instanceOf(Object).isRequired,
  blockProps: PropTypes.instanceOf(Object).isRequired,
};

export default HTMLComponent;
