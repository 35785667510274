/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <span role="img" aria-label="close" className="anticon anticon-close">
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 11L6.5 6L1.5 1"
        stroke="#05034D"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
);

const ChevronRight = (props) => <Icon component={svg} {...props} />;

export default ChevronRight;
