/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="33" height="39" viewBox="0 0 33 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2C0 0.895431 0.895431 0 2 0H16.1809H23.378C23.9465 0 24.4881 0.241894 24.8674 0.665225L29.1255 5.41667L31.9642 9.21767C32.2223 9.56327 32.3617 9.98305 32.3617 10.4144V19.5V37C32.3617 38.1046 31.4663 39 30.3617 39H2C0.89543 39 0 38.1046 0 37V2Z"
      fill="#DC1D00"
    />
    <path
      d="M26.5488 23.5007C26.5366 23.3562 26.4019 21.6106 23.242 21.6828C20.0943 21.7551 19.3227 21.9477 19.3227 21.9477C19.3227 21.9477 16.9712 19.6001 16.1138 17.7823C16.1138 17.7823 17.1549 14.7847 17.1059 12.9066C17.0569 11.0286 16.6037 9.94509 15.134 9.95713C13.6643 9.96917 13.4561 11.2332 13.6398 13.1113C13.8113 14.7967 14.7053 16.7831 14.7053 16.7831C14.7053 16.7831 14.0317 18.8537 13.1254 20.9244C12.2313 22.995 11.6189 24.0785 11.6189 24.0785C11.6189 24.0785 8.5815 25.0777 7.271 26.2816C5.9605 27.4855 5.4216 28.4125 6.10747 29.3394C6.70761 30.134 8.78971 30.3146 10.6636 27.9068C12.5253 25.4991 13.3703 23.9943 13.3703 23.9943C13.3703 23.9943 16.2241 23.2238 17.1059 23.0191C17.9877 22.8145 19.0655 22.6459 19.0655 22.6459C19.0655 22.6459 21.662 25.2222 24.1728 25.1259C26.6836 25.0296 26.5611 23.6451 26.5488 23.5007ZM6.7566 28.9301C5.20114 28.0152 10.0267 25.1861 10.9086 25.0898C10.9086 25.0898 8.39779 29.8932 6.7566 28.9301ZM14.1787 12.4371C14.1787 10.9443 14.6686 10.547 15.0483 10.547C15.4279 10.547 15.8566 10.7276 15.8689 12.0157C15.8811 13.3039 15.0483 15.832 15.0483 15.832C14.7666 15.5311 14.1787 13.9179 14.1787 12.4371ZM16.1996 22.6339C14.6319 23.0071 13.848 23.4044 13.848 23.4044C13.848 23.4044 13.848 23.4044 14.4849 21.9958C15.1218 20.5873 15.7831 18.6611 15.7831 18.6611C16.665 20.2863 18.4286 22.2005 18.4286 22.2005C18.4286 22.2005 17.7673 22.2486 16.1996 22.6339ZM19.9596 22.5015C19.9596 22.5015 25.0546 21.5986 25.0546 23.3081C25.0546 25.0055 21.8947 24.3073 19.9596 22.5015Z"
      fill="white"
    />
  </svg>
);

const PublishedDownloadIcon = (props) => <Icon component={svg} {...props} />;

export default PublishedDownloadIcon;
