import React from 'react';
import { Button, Modal, Space } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { MailOutlined, MobileOutlined } from '@ant-design/icons';
import BackIcon from 'components/Icons/BackIcon';

import './ExpiredProposalModal.scss';

const ExpiredProposalModal = ({
  title,
  subTitle,
  name,
  showCallButton,
  config,
  email,
  contactNumber,
  language,
  isClient,
}) => {
  const history = useHistory();

  const sendEmail = () => {
    window.location.href = `mailto:${email}`;
  };
  const call = () => {
    window.location.href = `tel:${contactNumber}`;
  };
  return (
    <>
      <Modal className={`expired-proposal-modal ${language}`} centered visible footer={null}>
        <h3 className="title">{title}</h3>
        <div className="divider" />

        <p className="body">{subTitle}</p>

        <div className="button-wrapper">
          <Space>
            <Button
              className="button confirm"
              type="primary"
              onClick={sendEmail}
              icon={<MailOutlined />}>
              {config['contact word']} {name}
            </Button>
            {showCallButton && (
              <Button
                className="button cancel"
                type="primary"
                onClick={call}
                icon={<MobileOutlined />}>
                {config['call word']}
                {language === 'hebrew' || language === 'arabic' ? '' : ' '}
                {name}
              </Button>
            )}
          </Space>
        </div>
      </Modal>

      {!isClient && (
        <Button
          className="expired-proposal back-button cancel"
          type="primary"
          onClick={() => history.goBack()}
          icon={<BackIcon />}>
          BACK
        </Button>
      )}
    </>
  );
};

ExpiredProposalModal.defaultProps = {
  showCallButton: false,
  config: null,
  contactNumber: null,
  isClient: true,
  language: '',
};

ExpiredProposalModal.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactNumber: PropTypes.string,
  showCallButton: PropTypes.bool,
  config: PropTypes.instanceOf(Object),
  isClient: PropTypes.bool,
  language: PropTypes.string,
};

export default ExpiredProposalModal;
