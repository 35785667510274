import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DownOutlined } from '@ant-design/icons';

import './CustomSelect.scss';

const CustomSelect = ({ list, selected, onChange, hovertoggle, notext, className }) => {
  const [open, setOpen] = useState(false);

  const chooseItem = (x) => {
    return () => {
      onChange(x);
      setOpen(false);
    };
  };

  const toggleSystem = hovertoggle
    ? { onMouseEnter: () => setOpen(true), onMouseLeave: () => setOpen(false) }
    : { onClick: () => setOpen(!open) };

  return (
    <div
      className={`custom-select ${className}`}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onMouseLeave={() => setOpen(false)}
      {...toggleSystem}>
      <div className="custom-select-selector">
        <div className="custom-select-selection-item" style={{ fontFamily: selected }}>
          {notext ? null : selected}
        </div>
        <DownOutlined className="custom-select-selection-icon" />
      </div>
      <div
        className="custom-select-dropdown-list"
        style={open ? { display: 'block' } : { display: 'none' }}>
        {list.map((x) => (
          <div
            className={`custom-select-item ${x.value === selected ? 'selected' : ''}`}
            onClick={chooseItem(x.value)}
            key={x.label}>
            <div className="custom-select-item-content" style={x.style}>
              {x.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

CustomSelect.defaultProps = {
  selected: '',
  className: '',
  notext: '',
  hovertoggle: false,
};

CustomSelect.propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  notext: PropTypes.string,
  hovertoggle: PropTypes.bool,
};

export default CustomSelect;
