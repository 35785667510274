import React from 'react';
import { Button, Col, Row } from 'antd';
import RGL, { WidthProvider } from 'react-grid-layout';

import helpers from 'helpers';
import { CloseCircleOutlined } from '@ant-design/icons';

import './GridImages.scss';

const ReactGridLayout = WidthProvider(RGL);

const GridImages = ({ images, onLayoutChange, onRemoveImage, setSelectImage, addFromLib }) => {
  return (
    <Col className="grid-image">
      <Row className="grid-image-layout">
        <ReactGridLayout
          className="layout"
          height={150}
          width={1200}
          margin={[2, 2]}
          onDragStop={onLayoutChange}
          onResizeStop={onLayoutChange}
          rowHeight={30}
          preventCollision={false}>
          {images.map((galleryImg, index) => (
            <div
              key={galleryImg.i}
              style={{
                position: 'initial',
                backgroundImage: `url("${helpers.formatS3Link(galleryImg?.image)}")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% 50%',
              }}
              data-grid={{
                i: galleryImg.i,
                x: galleryImg.x,
                y: galleryImg.y,
                w: galleryImg.w,
                h: galleryImg.h,
              }}>
              <CloseCircleOutlined onClick={() => onRemoveImage(index)} />
            </div>
          ))}
        </ReactGridLayout>
      </Row>

      <Row className="button-wrapper">
        <Button className="button cancel" type="primary" onClick={() => setSelectImage(true)}>
          Upload Images
        </Button>
        <Button className="button cancel" type="primary" onClick={addFromLib}>
          Add From Library
        </Button>
      </Row>
    </Col>
  );
};

export default GridImages;
