import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Divider } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tablist } from '../../../../modules';
import Popconfirm from 'components/PopConfirm';
import Button from 'components/Button';
import ResetIcon from 'components/Icons/ResetIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import helpers from 'helpers/proposal';

import './PriceEditModal.scss';

const PriceEditModal = ({
  user,
  prop,
  config,
  priceEdit,
  visible,
  onCancel,
  onConfirm,
  removeItem,
  saveUser,
  savingRichEditorModal,
  wixProductsAndServices,
  wixSiteProperties,
}) => {
  const [delivs, setDelivs] = useState([]);
  const [tempProp, setTempProp] = useState({});
  const [chosenDelivs, setChosenDelivs] = useState([]);

  const [delivsRegex, setDelivsRegex] = useState('');
  const [editstrategy, setEditstrategy] = useState('');

  const [showPropModal, setShowPropModal] = useState(visible);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showClearModal, setShowClearModal] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [action, setAction] = useState(null);

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      setShowModal(true);
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        makeEscapeModal(true);
      }
    }
  };

  const makeEscapeModal = (showModal) => {
    setShowPromptModal(showModal);
    setShowModal(showModal);
  };

  useEffect(() => {
    if (tempProp?.pricing?.strategy === 'value') {
      if (tempProp?.deliverables?.chosen === '') {
        setDisableSaveButton(true);
      } else {
        setDisableSaveButton(false);
      }
    } else if (tempProp?.deliverables?.chosen) {
      const chosenObjKey = Object.keys(tempProp?.deliverables?.chosen);
      const checkChosenObj = tempProp?.deliverables?.chosen[chosenObjKey?.[0]];
      let idcount = 1;

      const optionList = checkChosenObj?.list;
      const optionListLen = optionList?.length;

      if (idcount === 1 && optionListLen === 0) {
        setDisableSaveButton(true);
      } else if (idcount > 1) {
        if (idcount === 1 && optionListLen === 0) {
          setDisableSaveButton(true);
        } else {
          setDisableSaveButton(false);
        }
      } else {
        setDisableSaveButton(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempProp?.pricing?.strategy]);

  useEffect(() => {
    const simpleclone = (x, doParse) => {
      if (x && doParse) return JSON.parse(JSON.stringify(x));
      else return x;
    };

    const pricing = prop.pricing || { strategy: 'table1' };
    const { strategy } = pricing;

    let tempprop = {};

    if (!prop.deliverables) {
      const { ptype } = user;

      let tempDelivs = [];

      _.each(ptype?.split(' '), (pt) => {
        tempDelivs = _.union(
          tempDelivs,
          config[`${pt} delivs`]
            ? config[`${pt} delivs`]?.split('\n')
            : config['design delivs']?.split('\n')
        );
      });

      tempDelivs = tempDelivs.map((deliv) => {
        return { name: deliv, editable: false };
      });

      prop.deliverables = {
        chosen: {},
      };

      prop.deliverables.chosen =
        helpers.getDefaultOptions({ user, prelist: tempDelivs, prop, config }) || {};
    }

    try {
      tempprop = {
        pricing: { strategy },
        curr: prop.curr || prop.currency,
        deliverables: simpleclone(prop.deliverables, true),
      };
    } catch (error) {
      tempprop = {
        pricing: { strategy },
        curr: prop.curr || prop.currency,
        deliverables: simpleclone(prop.deliverables),
      };
    }

    try {
      if (priceEdit.v && priceEdit.v !== -42) {
        tempprop = JSON.parse(priceEdit.v);
      }
    } catch (exx) {
      console.log(exx);
    }

    tempprop.pricing = tempprop.pricing || { strategy: 'table1' };
    tempprop.roundTotal = false;
    tempprop.pricing.strategy = editstrategy || tempprop.pricing.strategy;
    tempprop.priceSeperator = prop.priceSeperator;
    tempprop.decimalPrecision = prop?.decimalPrecision || 2;
    // tempprop.roundTotal = tempprop.roundTotal;
    tempprop.language = prop.language;

    setChosenDelivs(tempprop?.deliverables?.chosen || null);
    const checkTableLenFirst = Object.keys(tempProp)?.length;
    const checkValueLenFirst = Object.keys(tempprop)?.length;
    setTempProp(checkTableLenFirst ? tempProp : tempprop);
    if (checkTableLenFirst === 0) setDisableSaveButton(true);
    else setDisableSaveButton(false);
    if (checkValueLenFirst === 0) setDisableSaveButton(true);
    else setDisableSaveButton(false);

    if (!showPromptModal) {
      window.addEventListener('keydown', handleKeyCommand, true);
    } else {
      window.removeEventListener('keydown', handleKeyCommand, true);
    }
    setShowPromptModal(!showPromptModal);
  }, []); //eslint-disable-line

  useEffect(() => {
    const userConfig = user?.config;
    const userDelivs = userConfig?.delivs || '';
    let tempDelivs = [];

    if (prop.project && prop.project.type) {
      _.each(prop.project.type.split(' '), (pt) => {
        tempDelivs = _.union(
          tempDelivs,
          config[`${pt} delivs`] ? config[`${pt} delivs`].split('\n') : []
        );
      });
    }

    tempDelivs = _.compact(tempDelivs);

    tempDelivs = tempDelivs.map((deliv) => {
      return { name: deliv, editable: false };
    });
    if (userDelivs) {
      tempDelivs = _.union(tempDelivs, userDelivs || []);
    }

    if (delivsRegex) {
      const searchRegex = new RegExp(delivsRegex.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
      tempDelivs = tempDelivs.filter((deliv) => searchRegex.test(deliv.name));
    }

    if (Object.keys(wixProductsAndServices).length) {
      let services = [];
      let products = [];
      let totalServices = 0;
      let totalProducts = 0;

      if (prop.wixConnectedInstance) {
        const connectedWixServices = wixProductsAndServices?.services?.find(
          (service) => service.instanceId === prop.wixConnectedInstance
        );
        const connectedWixProducts = wixProductsAndServices?.products?.find(
          (product) => product.instanceId === prop.wixConnectedInstance
        );

        services = connectedWixServices.fields?.map((service) => ({
          ...service,
          editable: false,
        }));
        totalServices = connectedWixServices.totalItems;

        products = connectedWixProducts.fields?.map((product) => ({
          ...product,
          editable: false,
        }));
        totalProducts = connectedWixProducts.totalItems;
      } else {
        wixProductsAndServices?.services?.forEach(
          (wixServices) =>
            (services = wixServices.fields?.map((service) => ({
              ...service,
              editable: false,
            })))
        );
        totalServices = wixProductsAndServices?.services[0]?.totalItems;

        wixProductsAndServices?.products.forEach(
          (wixProducts) =>
            (products = wixProducts.fields?.map((product) => ({
              ...product,
              editable: false,
            })))
        );
        totalProducts = wixProductsAndServices?.products[0]?.totalItems;
      }

      tempDelivs = [
        {
          name: 'wixServices',
          wixServices: services,
          totalItems: totalServices,
        },
        {
          name: 'wixProducts',
          wixProducts: products,
          totalItems: totalProducts,
        },
        {
          name: 'defaultServices',
          defaultServices: tempDelivs,
        },
      ];
    }

    setDelivs(tempDelivs);
  }, [user, delivsRegex, wixProductsAndServices]); // eslint-disable-line

  const showClearButton = () => {
    setShowClearModal(true);
  };

  const resetChosen = () => {
    setShowClearModal(false);

    // will pass the action to tablist to empty the chosen list array
    setAction('reset-price-choosen');

    // reset data on this component
    const tempprop = tempProp;
    tempprop.deliverables.chosen = '';
    setTempProp(tempprop);
    setChosenDelivs('');
    setDisableSaveButton(true);
  };

  const changeOptionVal = (chosen, orderSave) => {
    const tempprop = tempProp;
    const len = Object.keys(chosen).length;

    if (len === 0) {
      return resetChosen();
    }

    const [fkey] = Object.keys(chosen);
    if (chosen[fkey]?.list?.length > 0) {
      setDisableSaveButton(false);

      if (!tempProp.deliverables || !_.isEqual(tempProp.deliverables.chosen, chosen)) {
        if (tempprop.deliverables) {
          tempprop.deliverables.chosen = chosen;
        } else {
          tempprop.deliverables = { chosen: chosen };
        }
      } else if (orderSave) {
        if (tempprop.deliverables) {
          tempprop.deliverables.chosen = chosen;
        } else {
          tempprop.deliverables = { chosen: chosen };
        }
      }

      setTempProp(tempprop);
      setChosenDelivs(tempProp.deliverables.chosen);
      if (action === 'reset-price-choosen') {
        setAction('');
      }
    } else {
      resetChosen();
    }
  };

  return (
    <>
      <Modal
        visible={showPropModal}
        wrapClassName={`rich-editor-price-edit rich-editor-price-edit-${prop?.language?.toLowerCase()}`}
        title={
          <div className="rich-editor-price-edit-header">
            <h3 className="title">
              <span>Table Edit</span>
              {wixSiteProperties?.name && (
                <span className="rich-editor-title-info">
                  {' '}
                  {wixSiteProperties.name}{' '}
                  {wixSiteProperties.domain ? '(' + wixSiteProperties.domain + ')' : ''}{' '}
                </span>
              )}
            </h3>
            <Divider />
          </div>
        }
        closeIcon={<CloseIcon />}
        okText="OK"
        cancelText="CLOSE"
        onCancel={() => setShowModal(true)}
        cancelButtonProps={{ disabled: savingRichEditorModal }}
        maskClosable={false}
        closable={!savingRichEditorModal}
        onOk={() => onConfirm(tempProp)}
        footer={[
          <Col className="rich-editor-modal-footer" key="rich-editor-modal-footer">
            <Button
              size="large"
              className="rich-editor-modal-reset"
              text="CLEAR"
              icon={<ResetIcon />}
              onClick={showClearButton}
            />
            <Row>
              <Button
                type="default"
                size="large"
                className="rich-editor-modal-close"
                text="CLOSE"
                onClick={onCancel}
              />
              <Button
                size="large"
                className={`rich-editor-modal-continue 
                ${disableSaveButton && 'rich-editor-modal-continue-disabled'}`}
                text={savingRichEditorModal ? 'SAVING' : 'SAVE'}
                onClick={() => onConfirm(tempProp)}
                disabled={savingRichEditorModal}
                loading={savingRichEditorModal}
              />
            </Row>
          </Col>,
        ]}>
        <Tablist
          action={action}
          tabType="delivs"
          currAndDisc
          price={tempProp.pricing && tempProp.pricing.strategy !== 'value'}
          tabs={tempProp.pricing && tempProp.pricing.strategy === 'value'}
          currency={tempProp.curr || tempProp.currency || '$'}
          config={config}
          prop={tempProp}
          save={(value) => {
            const types = Object.keys(value);
            const tempprop = JSON.parse(JSON.stringify(tempProp));
            if (types.includes('curr')) {
              tempprop.curr = value.curr;
            } else if (types.includes('pricing.strategy')) {
              tempprop.pricing = { strategy: value['pricing.strategy'] };
            }
            setTempProp(tempprop);
          }}
          saveUser={saveUser}
          prelist={[...delivs]}
          handleState={() => {}}
          removeItem={removeItem}
          propsChosen={chosenDelivs ? _.merge({}, chosenDelivs) : chosenDelivs}
          setDelivsRegex={setDelivsRegex}
          onCurrChange={() => setEditstrategy('value')}
          onChange={(chosen, orderSave) => {
            changeOptionVal(chosen, orderSave);
          }}
        />
      </Modal>
      <>
        {showModal && (
          <Popconfirm
            visible={showModal}
            title={'Do you want to exit without saving?'}
            maskClosable={!savingRichEditorModal}
            closable={false}
            cancelText={'Cancel'}
            confirmText={
              savingRichEditorModal ? 'Saving...' : !disableSaveButton ? 'Save Changes' : ''
            }
            onCancel={() => {
              setShowModal(false);
              setShowPropModal(true);
              onCancel();
            }}
            onConfirm={() => {
              !disableSaveButton && onConfirm(tempProp);
            }}
            footer={null}
          />
        )}
        {showClearModal && (
          <Popconfirm
            visible={showClearModal}
            title={'Do you want to clear everything?'}
            maskClosable={!savingRichEditorModal}
            closable={false}
            cancelText={'Cancel'}
            confirmText={'Clear'}
            onCancel={() => {
              setShowClearModal(false);
              setShowPropModal(true);
            }}
            onConfirm={resetChosen}
            footer={null}
          />
        )}
      </>
    </>
  );
};

PriceEditModal.defaultProps = {
  savingRichEditorModal: false,
};

PriceEditModal.propTypes = {
  prop: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  priceEdit: PropTypes.instanceOf(Object).isRequired,
  removeItem: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  savingRichEditorModal: PropTypes.bool,
};

export default PriceEditModal;
