import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

import { SketchPicker } from 'react-color';
import './ColorBox.scss';

const Text = styled.span`
  margin-left: 8px;
  vertical-align: middle;
  display: inline-block;
  font-size: 12px;
  line-height: 140%;
  color: #05034d;
  opacity: 0.8;
`;

const Box = styled.div`
  vertical-align: middle;
  display: inline-block;
  border: 1px solid #cbced8;
  width: 47px;
  background: ${(props) => props.color};
  height: 24px;
  border-radius: 4px;
`;

const ColorBox = ({ color, onChange }) => {
  const [colorValue, changeColor] = useState(color);

  useEffect(() => {
    changeColor(color);
  }, [color]);

  const onColorChange = (selectedColor) => {
    const { hex, rgb } = selectedColor;
    const color = rgb.a === 1 ? hex : `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
    changeColor(color);
    onChange(color);
  };

  return (
    <Popover
      placement="topLeft"
      content={<SketchPicker color={colorValue} onChange={onColorChange} />}
      trigger="click"
      overlayClassName="editor-color-picker">
      <div>
        <Box color={colorValue} />
        <Text>{colorValue}</Text>
      </div>
    </Popover>
  );
};

ColorBox.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ColorBox;
