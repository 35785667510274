import React, { useState } from 'react';
import {
  Divider,
  Checkbox,
  Input,
  Button,
  message,
  Typography,
  Row,
  Col,
  Tooltip,
  List,
  Radio,
} from 'antd';
import { useMutation } from '@apollo/client';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useQuery, useLazyQuery } from '@apollo/client';

import { GET_CONFIGURATION } from 'graphql/queries/configurationQueries';
import { UPDATE_CONFIGURATION } from 'graphql/mutations/configurationMutations';
import { OPEN_AI_RECOMMENDATIONS } from 'graphql/queries/proposalQueries';

import './AIPrompt.scss';

const { Title } = Typography;
const CheckboxGroup = Checkbox.Group;
const toneOptions = [
  'Professional',
  'Casual',
  'Funny',
  'Technical',
  'Empathetic',
  'Innovative',
  'Persuasive',
];

const AIPrompt = () => {
  const [promptText, setPromptText] = useState();
  const [toneText, setToneText] = useState('');
  const [lengthText, setLengthText] = useState('');
  const [commandText, setCommandText] = useState();
  const [enhancedText, setEnhancedText] = useState('');
  const [grammarText, setGrammarText] = useState('');
  const [lengthOptions, setLengthOptions] = useState([]);

  const [toneList, setToneList] = useState([]);
  const [lengthList, setLengthList] = useState('');
  const [grammarOption, setGrammarOption] = useState();
  const [enhancedOption, setEnhancedOption] = useState();
  const [commandValue, setCommandValue] = useState('');
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [aiResponse, setAIResponse] = useState([]);
  const [selectedText, setSelectedText] = useState('');

  useQuery(GET_CONFIGURATION, {
    variables: {
      language: 'english',
      configFields: ['aiPrompt'],
    },
    fetchPolicy: 'cache-and-network',
    skip: promptText,
    onCompleted: (data) => {
      const {
        promptText,
        toneText,
        lengthText,
        commandText,
        enhancedText,
        grammarText,
        lengthListOptions,
      } = data?.fetchConfiguration?.aiPrompt;
      setPromptText(
        promptText ||
          'Rephrase "text" [tone] [length] [command] [enhanced] [grammar] and the response choices should be unique.'
      );
      setToneText(toneText || 'in [tone] tone');
      setLengthText(lengthText || 'and [length] text');
      setCommandText(commandText || 'and follow [command]');
      setEnhancedText(enhancedText || 'and provide enhanced text');
      setGrammarText(grammarText || 'also check for grammar');
      setLengthOptions(lengthListOptions || ['Very Short', 'Shorter', 'Longer', 'Very Long']);
    },
  });

  const [loadRecommendations, { loading: isFetchingRecommendations }] = useLazyQuery(
    OPEN_AI_RECOMMENDATIONS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setAIResponse(data?.openAIRecommendations || []);
      },
    }
  );

  const [updateConfiguration] = useMutation(UPDATE_CONFIGURATION, {
    variables: {
      language: 'english',
      configuration: {
        aiPrompt: {
          promptText,
          toneText,
          lengthText,
          commandText,
          enhancedText,
          grammarText,
          lengthListOptions: lengthOptions,
        },
      },
    },
    onCompleted: () => message.success('Prompt updated successfully'),
    onError: () => message.error(`Failed update prompt`),
  });

  const onChangeTone = (list) => {
    setToneList(list);
    setIndeterminate(!!list.length && list.length < toneOptions.length);
    setCheckAll(list.length === toneOptions.length);
  };

  const onChangeLength = (e) => {
    setLengthList(e.target.value);
  };

  const onChangeLengthText = (value, index) => {
    const tempLengthOptions = [...lengthOptions];
    tempLengthOptions[index] = value;
    setLengthOptions(tempLengthOptions);
  };

  const onCheckAllChange = (e) => {
    setLengthList(e.target.checked ? lengthOptions[0] : []);
    setToneList(e.target.checked ? toneOptions : []);
    setGrammarOption(e.target.checked ? true : false);
    setEnhancedOption(e.target.checked ? true : false);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const replaceText = (type, text) => {
    let innerText;
    if (type === 'tone') {
      innerText = toneText.replace('[tone]', new Intl.ListFormat().format(toneList));
      return text.replace('[tone]', innerText);
    } else if (type === 'length') {
      innerText = lengthText.replace('[length]', lengthList);
      return text.replace('[length]', innerText);
    } else if (type === 'command') {
      innerText = commandText.replace('[command]', commandValue);
      return text.replace('[command]', innerText);
    } else if (type === 'enhanced') {
      return text.replace('[enhanced]', enhancedText);
    } else if (type === 'grammar') {
      return text.replace('[grammar]', grammarText);
    } else if (type === 'text') {
      return text.replace('[text]', selectedText);
    }
  };

  let newPromptText = promptText;

  if (toneList.length) {
    newPromptText = replaceText('tone', newPromptText);
  }

  if (lengthList) {
    newPromptText = replaceText('length', newPromptText);
  }

  if (commandValue) {
    newPromptText = replaceText('command', newPromptText);
  }

  if (enhancedOption) {
    newPromptText = replaceText('enhanced', newPromptText);
  }

  if (grammarOption) {
    newPromptText = replaceText('grammar', newPromptText);
  }

  if (selectedText) {
    newPromptText = replaceText('text', newPromptText);
  }

  return (
    <div className="admin-wrapper admin-ai-prompt" style={{ margin: '5em' }}>
      <>
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          Check all
        </Checkbox>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Divider />
          <Col className="gutter-row" span={6}>
            <Title level={5}>Tones</Title>
            <CheckboxGroup options={toneOptions} value={toneList} onChange={onChangeTone} />
          </Col>

          <Col className="gutter-row" span={8}>
            <Title level={5}>Lengths</Title>
            <Radio.Group value={lengthList} style={{ width: '100%' }} onChange={onChangeLength}>
              <Row>
                {lengthOptions.map((length, lengthIndex) => (
                  <Col key={lengthIndex} span={12}>
                    <Radio value={length}>
                      <Input
                        value={length}
                        onChange={(e) => onChangeLengthText(e.target.value, lengthIndex)}
                      />
                    </Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Col>

          <Col className="gutter-row" span={5}>
            <Title level={5}>Grammar Check</Title>
            <Checkbox onChange={(e) => setGrammarOption(e.target.checked)} checked={grammarOption}>
              Grammar Check
            </Checkbox>
          </Col>

          <Col className="gutter-row" span={5}>
            <Title level={5}>Enahanced Writing</Title>
            <Checkbox
              onChange={(e) => setEnhancedOption(e.target.checked)}
              checked={enhancedOption}>
              Enhance Writing
            </Checkbox>
          </Col>
        </Row>

        <Divider />
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Title level={5}>
              Command Text
              <Tooltip title="Do not remove the text in square bracket like [tone]">
                <InfoCircleOutlined />
              </Tooltip>
            </Title>
            <Input
              placeholder="Enter AI command"
              onChange={(event) => setCommandText(event.target.value)}
              value={commandText}
            />
          </Col>
          <Col className="gutter-row" span={12}>
            <Title level={5}>Command Value</Title>
            <Input
              placeholder="Enter AI command value"
              onChange={(event) => setCommandValue(event.target.value)}
              value={commandValue}
            />
          </Col>
        </Row>
        <Divider />

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Title level={5}>
              Tone text
              <Tooltip title="Do not remove the text in square bracket like [tone]">
                <InfoCircleOutlined />
              </Tooltip>
            </Title>
            <Input
              placeholder="Enter Tone command"
              onChange={(event) => setToneText(event.target.value)}
              value={toneText}
            />
          </Col>
          <Col className="gutter-row" span={12}>
            <Title level={5}>
              Length text
              <Tooltip title="Do not remove the text in square bracket like [tone]">
                <InfoCircleOutlined />
              </Tooltip>
            </Title>
            <Input
              placeholder="Enter Length command"
              onChange={(event) => setLengthText(event.target.value)}
              value={lengthText}
            />
          </Col>
        </Row>

        <Divider />

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Title level={5}>Enhance Text</Title>
            <Input
              placeholder="Enter Enhanced command"
              onChange={(event) => setEnhancedText(event.target.value)}
              value={enhancedText}
            />
          </Col>
          <Col className="gutter-row" span={12}>
            <Title level={5}>Grammar Text</Title>
            <Input
              placeholder="Enter Grammar command"
              onChange={(event) => setGrammarText(event.target.value)}
              value={grammarText}
            />
          </Col>
        </Row>

        <Divider />

        <Input.Group compact>
          <Title level={5}>Selected Text</Title>
          <Input.TextArea value={selectedText} onChange={(e) => setSelectedText(e.target.value)} />
        </Input.Group>

        <Divider />

        <Input.Group compact>
          <Title level={5}>
            Full command
            <Tooltip title="Do not remove the text in square bracket [text]">
              <InfoCircleOutlined />
            </Tooltip>
          </Title>
          <Input.TextArea value={promptText} onChange={(e) => setPromptText(e.target.value)} />
        </Input.Group>

        <Divider />

        <Button type="primary" onClick={updateConfiguration}>
          Save
        </Button>

        <Divider />

        <h2>{newPromptText}</h2>
        <Divider />

        <Button
          type="primary"
          loading={isFetchingRecommendations}
          onClick={() =>
            loadRecommendations({
              variables: {
                prompt: selectedText,
                tone: new Intl.ListFormat().format(toneList),
                recommendationSize: lengthList,
                command: commandValue,
                enhance: enhancedOption,
                grammar: grammarOption,
              },
            })
          }>
          Get AI Response
        </Button>
        <List
          bordered
          dataSource={aiResponse}
          renderItem={(item) => <List.Item>{item.text}</List.Item>}
        />
      </>
    </div>
  );
};

export default AIPrompt;
