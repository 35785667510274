/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28.7531" height="28.7531" rx="3" fill="#7876C1" />
    <rect x="5.72656" y="4.9082" width="28.7531" height="28.7531" rx="3" fill="white" />
    <rect x="7.24609" y="6.54492" width="28.7531" height="28.7531" rx="3" fill="#7876C1" />
  </svg>
);

const PublishedCopyIcon = (props) => <Icon component={svg} {...props} />;

export default PublishedCopyIcon;
