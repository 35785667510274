import React from 'react';
import { Button as AntButton, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import IconButton from '../IconButton';

import './PageHeader.scss';

const PageHeader = ({
  pageTitle,
  pageLabel,
  showButton,
  buttonText,
  onButtonClick,
  loading,
  isLocked,
  isSettingsPage,
  onResetButtonClick,
  resetIcon,
  resetDisabled,
}) => (
  <div className="page-header">
    <Row justify="space-between" align="middle">
      <Col xs={14} sm={14} md={14} lg={12}>
        <div className="page-title-conatiner">
          <h1 className={`page-title ${pageTitle === 'Settings' ? 'settings-title' : ''}`}>
            {pageTitle}
          </h1>
          <h5 className="page-label">{pageLabel}</h5>
        </div>

        <div className="color-divider" />
      </Col>
      <Col
        xs={{ span: 10 }}
        sm={{ span: 10 }}
        md={{ span: 10 }}
        lg={{ span: 12 }}
        className="create-new-button">
        {isSettingsPage ? (
          <AntButton className="reset-btn" onClick={onResetButtonClick} disabled={resetDisabled}>
            <span className="reset-icon">{resetIcon}</span>
            <span className="prosper-button-text"> {'Reset'}</span>{' '}
          </AntButton>
        ) : null}
        {showButton ? (
          isSettingsPage ? (
            <AntButton
              className="primary-btn"
              type="primary"
              onClick={onButtonClick}
              loading={loading}
              disabled={isLocked}>
              {buttonText}
            </AntButton>
          ) : (
            <IconButton disabled={isLocked} text={buttonText} onButtonClick={onButtonClick} />
          )
        ) : null}
      </Col>
    </Row>
  </div>
);

PageHeader.defaultProps = {
  showButton: false,
  buttonText: '',
  pageLabel: '',
  onButtonClick: () => {},
  isLocked: false,
};

PageHeader.propTypes = {
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  pageLabel: PropTypes.string,
  isLocked: PropTypes.bool,
};

export default PageHeader;
