import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';

import useWindowDimensions from 'hooks/useWindowDimensions';
import DropdownIcon from 'components/Icons/DropdownIcon';

import './OverflowDropDownList.scss';

const OverflowDropDownList = ({
  listItems,
  dropdownDynamic,
  dropdownClassName,
  dropdownOverlayClassName,
  dropdownIcon,
  dropdownPlacement,
  dropdownOverFlowLimit,
  dropdownCustomSize,
  isWizard,
  breakPoints = [1600, 1500, 1000, 900, 800, 700, 600],
}) => {
  const { width } = useWindowDimensions({ customWidth: dropdownCustomSize });
  let visibleListItems = [];
  let dropdownListItems = [];
  const tempListItems = listItems.filter((listItem) =>
    listItem.props ? listItem.props.visible !== false : listItem.visible !== false
  );

  let visible = 0;
  for (let i = 0; i < breakPoints.length; i++) {
    if (width >= breakPoints[i]) {
      // visible = 9,8,7,6 and so on
      visible = breakPoints.length - i;
      break;
    }
  }

  if (isWizard) {
    visibleListItems = tempListItems;
  } else {
    visibleListItems = tempListItems.slice(0, visible);
  }
  dropdownListItems = tempListItems.slice(visible);

  const menu = (
    <Menu
      className={
        dropdownDynamic ? 'overflow-dropdown-list-menu-dynamic' : 'overflow-dropdown-list-menu'
      }>
      {dropdownListItems.map((dropdownListItem, dropdownListItemIndex) =>
        React.isValidElement(dropdownListItem) ? (
          <Menu.Item
            key={dropdownListItemIndex}
            className="overflow-dropdown-list-menu-item-dynamic">
            {React.cloneElement(dropdownListItem)}
          </Menu.Item>
        ) : (
          <Menu.Item
            key={dropdownListItemIndex}
            className="overflow-dropdown-list-menu-item"
            onClick={dropdownListItem.onClick}>
            {dropdownListItem.text}
          </Menu.Item>
        )
      )}
    </Menu>
  );

  return (
    <div className={`overflow-dropdown-list-container ${dropdownClassName}`}>
      {visibleListItems.map((visibleListItem, visibleListItemKey) =>
        React.isValidElement(visibleListItem) ? (
          React.cloneElement(visibleListItem, {
            key: visibleListItemKey,
          })
        ) : (
          <div
            className="overflow-dropdown-list-item overflow-dropdown-background"
            key={visibleListItemKey}>
            {visibleListItem}
          </div>
        )
      )}
      {dropdownListItems.length && !isWizard ? (
        <Dropdown
          overlay={menu}
          placement={dropdownPlacement}
          overlayClassName={dropdownOverlayClassName}>
          {dropdownIcon || <DropdownIcon />}
        </Dropdown>
      ) : null}
    </div>
  );
};

OverflowDropDownList.defaultProps = {
  dropdownDynamic: false,
  dropdownIcon: '',
  dropdownPlacement: 'topLeft',
  dropdownClassName: '',
  dropdownOverlayClassName: '',
  dropdownOverFlowLimit: 0,
  dropdownCustomSize: 0,
};

OverflowDropDownList.propTypes = {
  listItems: PropTypes.instanceOf(Object).isRequired,
  dropdownIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  dropdownDynamic: PropTypes.bool,
  dropdownPlacement: PropTypes.string,
  dropdownClassName: PropTypes.string,
  dropdownOverlayClassName: PropTypes.string,
  dropdownOverFlowLimit: PropTypes.number,
  dropdownCustomSize: PropTypes.number,
};

export default OverflowDropDownList;
