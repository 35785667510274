import React from 'react';
import { Button, Modal, Input } from 'antd';
import PropTypes from 'prop-types';
import ShareIcon from 'components/Icons/ShareIcon';
import PublishedCopyIcon from 'components/Icons/PublishedCopyIcon';
import PublishedWhatsappIcon from 'components/Icons/PublishedWhatsappIcon';
import PublishedMailIcon from 'components/Icons/PublishedMailIcon';
import PublishedDownloadIcon from 'components/Icons/PublishedDownloadIcon';
import CloseIcon from 'components/Icons/CloseIcon';

import './PublishedProposalModal.scss';

const PublishedProposalModal = ({
  onCancel,
  publishedProposalLink,
  copyPropsalLink,
  downloadPdf,
}) => {
  const pdfLink = publishedProposalLink();

  return (
    <Modal
      className="simple-info-modal published-proposal-modal"
      centered
      visible
      footer={null}
      closeIcon={<CloseIcon />}
      onCancel={onCancel}>
      <ShareIcon />
      <h3 className="title">Great job!</h3>
      <h3 className="title">Your Proposal is now live.</h3>
      <div className="divider" />

      <p className="body">You can share this link below with your client.</p>

      <Input
        id="pdf-link-input"
        name="pdf-link-input"
        type="text"
        className="pdf-link-input"
        value={pdfLink}
        readOnly
      />

      <div className="modal-options-block">
        <div className="published-proposal-modal-icon-container" onClick={copyPropsalLink}>
          <PublishedCopyIcon />
          <span>Copy Link</span>
        </div>
        <div className="published-proposal-modal-icon-container" onClick={() => window.open(`https://web.whatsapp.com/send?text=${pdfLink}`, '_blank')}>
          <PublishedWhatsappIcon />
          <span>Via WhatsApp</span>
        </div>
        <div className="published-proposal-modal-icon-container" onClick={() =>
              window.open(
                `mailto:?subject=Proposal&body=Check out this proposal ${pdfLink}`,
                '_blank'
              )
            }>
          <PublishedMailIcon className="email-icon" />
          <span>Via Email</span>
        </div>
        <div className="published-proposal-modal-icon-container" onClick={downloadPdf}>
          <PublishedDownloadIcon />
          <span>Download</span>
        </div>
      </div>

      <div className="button-wrapper">
        <Button className="button cancel" type="primary" onClick={onCancel}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

PublishedProposalModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  publishedProposalLink: PropTypes.func.isRequired,
  copyPropsalLink: PropTypes.func.isRequired,
  downloadPdf: PropTypes.func.isRequired,
};

export default PublishedProposalModal;
