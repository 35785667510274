import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { UnorderedListOutlined } from '@ant-design/icons';
import { sanitizeString } from 'utils/xss';
import { Layout, Menu, Tooltip, Space, Popover, Input, Button, message } from 'antd';
import EditIcon from 'components/Icons/EditIcon';
import EyeIcon2 from 'components/Icons/EyeIcon2';
import EyeoffIcon from 'components/Icons/EyeoffIcon';
import DownloadUIIcon from 'components/Icons/DownloadUIIcon';
import { getSectionTitle } from 'helpers/proposal';

import './ContentTable.scss';

const ContentTable = ({
  mode,
  configText,
  collapse,
  setCollapse,
  proposal,
  user,
  scrollToSection,
  contentTableClassName,
  downloadPdf,
  isHebrew,
  config,
  saveProposal,
  path,
  clientWixPreview,
  templateUserWixPreview,
}) => {
  const isHeaderHidden = proposal?.draft?.header?.hidecover;
  const isSignatureDisabled = proposal?.disableSig;
  const allowPreviewDownload = user?.profile.allowPreviewDownload;
  const configHeadline = proposal?.draft?.header?.headline || 'Headline';

  const sectionOrder = proposal?.draft?.sectionorder?.filter(
    (section) => Object.keys(proposal?.draft[section] || {})?.length
  );

  const fullWidthImgArray = proposal?.draft?.sectionorder?.filter(
    (section) => Object.keys(proposal?.draft[section]?.image || {})?.length
  );

  const [headlineVisible, toggleHeadlineEdit] = useState(false);
  const [headlineName, setHeadlineName] = useState(configHeadline);
  const [headlineNameError, setHeadlineNameError] = useState('');
  const [showHeadlineEditIcon, toggleHeadlineEditIcon] = useState(false);

  const [fullImageVisible, toggleFullImageEdit] = useState(false);
  const [fullImageName, setFullImageName] = useState('');
  const [fullImageNameError, setFullImageNameError] = useState('');
  const [showFullImageEditIcon, toggleFullImageEditIcon] = useState(false);

  const [presentFullImageName, setPresentFullImageName] = useState('');

  const [eachFullImage, setEachFullImage] = useState([]);
  const [tocExclude, setTocExclude] = useState([]);

  useEffect(() => {
    // get excluded from TOC list
    if (proposal?.draft?.tocExclude) {
      setTocExclude(proposal.draft.tocExclude);
    }
  }, [proposal?.draft?.tocExclude]);

  const handleMouseEnter = (event, s, stitle) => {
    if (s) {
      setPresentFullImageName(s);
    }
    if (presentFullImageName !== '' || presentFullImageName !== null) {
      toggleFullImageEditIcon(true);
      setFullImageName(stitle);
    }
  };

  const arrCurrentNames = eachFullImage;

  const getCurrentName = (sid, newName) => {
    if (arrCurrentNames !== null) {
      Object.keys(arrCurrentNames).forEach(function (key) {
        arrCurrentNames[key] = newName;
      });
    } else {
      arrCurrentNames[sid] = newName;
      setEachFullImage(arrCurrentNames);
    }
    return sid;
  };

  const updateTOCText = ({ actionType, value, sectionId }) => {
    if (actionType === 'update_headline') {
      saveProposal({ 'draft.header.headline': value }, ({ data }) => {
        if (data) {
          message.success(`Headline successfully updated`);
        }
      });
    } else if (actionType === 'update_fullimage') {
      saveProposal(
        {
          [`draft.${sectionId}.imageName`]: value,
        },
        ({ data }) => {
          if (data) {
            message.success(`Image name successfully updated`);
          }
        }
      );
    }
  };

  const updateTOCExclude = (e, key, exclude) => {
    e.stopPropagation();

    let value = [...tocExclude];

    if (!exclude) {
      // if not excluded, remove from exclude list
      value = value.filter((item) => item !== key);
    } else if (!value.includes(key)) {
      // if excluded, add to exclude list
      value.push(key);
    }

    setTocExclude(value);

    saveProposal({ 'draft.tocExclude': value });
  };

  const saveByEnter = (e, actionType, s) => {
    const x = e.which || e.keyCode;
    if (e.key === 'Enter' || x === 13) {
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        if (actionType === 'update_headline') {
          updateTOCText({
            actionType: actionType,
            value: headlineName,
            sectionId: s,
          });
          toggleHeadlineEdit(false);
        } else if (actionType === 'update_fullimage') {
          updateTOCText({
            actionType: actionType,
            value: fullImageName,
            sectionId: s,
          });
          toggleFullImageEdit(false);
        }
      }
    }
  };

  const cdClassName = () => {
    const url = window.location.pathname;
    if (url.includes('/cd/') || clientWixPreview) {
      return 'client-preview';
    } else {
      return;
    }
  };

  const isEditor = path === '/d/:pid';
  const tocOptions = { tocExclude, updateTOCExclude, isEditor };

  return (
    <Layout.Sider
      className={`content-table-side-menu ${cdClassName()} 
      ${contentTableClassName} 
      ${collapse ? 'ant-layout-sider-is-collapsed' : 'ant-layout-sider-is-not-collapsed'}`}>
      {collapse && (
        <div>
          <div className="content-table-button" onClick={() => setCollapse(!collapse)}>
            <Tooltip placement={isHebrew ? 'left' : 'right'} title="Expand Table of Content">
              <UnorderedListOutlined className={isHebrew ? 'content-table-icon' : ''} />
            </Tooltip>
          </div>

          {/* // main */}
          {/* // {mode === 'preview' && collapse && allowPreviewDownload !== false && ( */}

          {mode === 'preview' && allowPreviewDownload !== false && (
            <div className="content-table-button download-icon-header" onClick={downloadPdf}>
              <Tooltip placement={isHebrew ? 'left' : 'right'} title={configText('Download PDF')}>
                <DownloadUIIcon />
              </Tooltip>
            </div>
          )}
        </div>
      )}

      <div className="content-table-menu">
        <div className="content-table-header" onClick={() => setCollapse(!collapse)}>
          <span>{config['Table of Content'] || 'Table of Content'}</span>
          <UnorderedListOutlined className={isHebrew ? 'content-table-icon' : ''} />
        </div>
        <Menu defaultSelectedKeys={['header']} mode="inline">
          {!isEditor && tocExclude.includes('header') ? null : (
            <>
              {!isHeaderHidden && (
                <Menu.Item
                  key={'header'}
                  className="editable"
                  onClick={() => scrollToSection({ sectionId: 'proposal-headersection' })}
                  onMouseEnter={() => toggleHeadlineEditIcon(true)}
                  onMouseLeave={() => toggleHeadlineEditIcon(false)}>
                  <span className="menu-item-text">{headlineName}</span>
                  <span className="menu-item-icons">
                    {path === '/d/:pid' && (
                      <Popover
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onVisibleChange={() => toggleHeadlineEdit(!headlineVisible)}
                        trigger="click"
                        visible={headlineVisible}
                        content={
                          <div className="edit-container" onClick={(e) => e.stopPropagation()}>
                            <Input
                              value={headlineName}
                              onChange={(e) => {
                                if (headlineNameError) setHeadlineNameError('');
                                setHeadlineName(
                                  !!sanitizeString(e.target.value).trim()
                                    ? sanitizeString(e.target.value)
                                    : ''
                                );
                              }}
                              onKeyPress={(e) => saveByEnter(e, 'update_headline', 'Headline')}
                            />
                            <p className="error-field">{headlineNameError}</p>

                            <div className="button-wrapper">
                              <Space>
                                <Button
                                  onClick={() => {
                                    if (!headlineName) {
                                      return setHeadlineNameError('Headline cannot be empty');
                                    }
                                    updateTOCText({
                                      actionType: 'update_headline',
                                      value: headlineName,
                                      sectionId: 'Headline',
                                    });
                                    toggleHeadlineEdit(false);
                                  }}>
                                  Save
                                </Button>
                                <Button
                                  onClick={() => {
                                    toggleHeadlineEdit(false);
                                    setHeadlineName(configHeadline);
                                    setHeadlineNameError('');
                                  }}>
                                  Cancel
                                </Button>
                              </Space>
                            </div>
                          </div>
                        }>
                        {(showHeadlineEditIcon || headlineVisible) && (
                          <EditIcon
                            className={`edit-icon ${
                              headlineVisible ? 'content-item-name-active' : ''
                            }`}
                          />
                        )}
                      </Popover>
                    )}
                    <ToggleVisibility {...tocOptions} text="header" />
                  </span>
                </Menu.Item>
              )}
            </>
          )}

          {_.compact(
            _.map(sectionOrder, (s, sectionIndex) => {
              const stitle = getSectionTitle({
                proposal: proposal,
                sectionName: s,
                sectionIndex,
              });

              const isImage = fullWidthImgArray.includes(s) && presentFullImageName === s;
              const text = isImage ? fullImageName : stitle || s;

              // text2 -> based on this value (uuid or text) the toggle functionality will work
              const text2 = s.length === 36 ? s : text;

              // if not editor, if excluded from TOC don't show th text
              if (!isEditor && tocExclude.includes(text2)) {
                return null;
              }

              return (
                <Menu.Item
                  key={sectionIndex}
                  onClick={() => scrollToSection({ sectionId: s })}
                  onMouseEnter={(e) => handleMouseEnter(e, s, stitle)}
                  onMouseLeave={() => toggleFullImageEditIcon(false)}>
                  <span className="menu-item-text">{text}</span>
                  <span className="menu-item-icons">
                    {path === '/d/:pid' && isImage && (
                      <Popover
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onVisibleChange={() => toggleFullImageEdit(!fullImageVisible)}
                        trigger="click"
                        visible={fullImageVisible}
                        content={
                          <div className="edit-container" onClick={(e) => e.stopPropagation()}>
                            <Input
                              value={fullImageName}
                              onChange={(e) => {
                                if (fullImageNameError) setFullImageNameError('');
                                getCurrentName(s, e.target.value);
                                setFullImageName(
                                  !!sanitizeString(e.target.value).trim()
                                    ? sanitizeString(e.target.value)
                                    : ''
                                );
                              }}
                              onKeyPress={(e) => saveByEnter(e, 'update_fullimage', s)}
                            />
                            <p className="error-field">{fullImageNameError}</p>

                            <div className="button-wrapper">
                              <Space>
                                <Button
                                  onClick={() => {
                                    if (!fullImageName) {
                                      return setFullImageNameError('Image Name cannot be empty');
                                    }
                                    updateTOCText({
                                      actionType: 'update_fullimage',
                                      value: fullImageName,
                                      sectionId: s,
                                    });
                                    toggleFullImageEdit(false);
                                  }}>
                                  Save
                                </Button>
                                <Button
                                  onClick={() => {
                                    toggleFullImageEdit(false);
                                    setFullImageName(stitle || s);
                                    setFullImageNameError('');
                                  }}>
                                  Cancel
                                </Button>
                              </Space>
                            </div>
                          </div>
                        }>
                        {(showFullImageEditIcon || fullImageVisible) &&
                          presentFullImageName === s && (
                            <EditIcon
                              className={`edit-icon ${
                                fullImageVisible ? 'content-item-name-active' : ''
                              }`}
                            />
                          )}
                      </Popover>
                    )}

                    {/* if length == 36 then pass uuid, section visiblity will be based on uuid */}
                    <ToggleVisibility {...tocOptions} text={text2} />
                  </span>
                </Menu.Item>
              );
            })
          )}

          {!isEditor && tocExclude.includes('signature') ? null : (
            <>
              {!isSignatureDisabled && (
                <Menu.Item
                  key={'signature'}
                  onClick={() => scrollToSection({ sectionId: 'proposal-signaturesection' })}>
                  <span className="menu-item-text">
                    {getSectionTitle({ proposal: proposal, sectionName: 'signature' })}
                  </span>
                  <span className="menu-item-icons">
                    <ToggleVisibility {...tocOptions} text="signature" />
                  </span>
                </Menu.Item>
              )}
            </>
          )}
        </Menu>
        {mode === 'preview' && allowPreviewDownload !== false && (
          <div className="content-table-footer">
            <div onClick={downloadPdf}>
              <DownloadUIIcon className="download-icon" />
              <span className="link-text">{configText('Download PDF')}</span>
            </div>
          </div>
        )}
      </div>
    </Layout.Sider>
  );
};

// component to show eye icon to toggle visibility of TOC items
function ToggleVisibility({ tocExclude, text, updateTOCExclude, isEditor }) {
  if (!isEditor) {
    return null;
  }

  // excluded from TOC
  if (tocExclude.includes(text)) {
    return <EyeoffIcon className="eye-icon" onClick={(e) => updateTOCExclude(e, text, false)} />;
  }

  return <EyeIcon2 className="eye-icon" onClick={(e) => updateTOCExclude(e, text, true)} />;
}

ContentTable.defaultProps = {
  collapse: false,
  configText: null,
  setCollapse: () => {},
  downloadPdf: () => {},
  contentTableClassName: '',
  clientWixPreview: false,
  templateUserWixPreview: false,
};

ContentTable.propTypes = {
  proposal: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object),
  collapse: PropTypes.bool,
  configText: PropTypes.instanceOf(Object),
  setCollapse: PropTypes.func,
  downloadPdf: PropTypes.func,
  contentTableClassName: PropTypes.string,
  config: PropTypes.instanceOf(Object).isRequired,
  clientWixPreview: PropTypes.bool,
  templateUserWixPreview: PropTypes.bool,
};

export default React.memo(ContentTable);
