import React, { useState } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { ContentState } from 'draft-js';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import helpers from 'helpers';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const ReactGridLayout = WidthProvider(RGL);

const GalleryComponent = ({ block, blockProps }) => {
  const [updating, setUpdating] = useState(false);
  const contentState = ContentState.createFromBlockArray([block]);

  const getGalleryValue = () => {
    const ent = block.getEntityAt(0);
    const entity = contentState.getEntity(ent);

    const {
      data: { data },
    } = entity;
    return data;
  };

  const onRemove = () => blockProps && blockProps.onRemove(block.getKey());

  const finishEdit = () => blockProps.onFinishEdit(block.getKey());

  const saveGallery = (v) => {
    setUpdating(true);
    const entityKey = block.getEntityAt(0);
    if (entityKey) {
      contentState.replaceEntityData(entityKey, { data: v });
      blockProps.update(block);
      finishEdit();
      setTimeout(() => setUpdating(false), 500);
    }
  };

  const onEdit = () =>
    blockProps.onEdit({ v: getGalleryValue(), save: saveGallery, cancel: (x) => x });

  const handleBlur = () => blockProps.handleEditComponent(false);

  const getGrid = (images) => {
    return (
      <ReactGridLayout
        className="layout"
        height={150}
        width={1200}
        rowHeight={30}
        margin={[2, 2]}
        preventCollision={false}
        isDraggable={false}
        isResizable={false}>
        {images.map((galleryImg) => (
          <div
            key={galleryImg.i}
            style={{
              position: 'initial',
              backgroundImage: `url("${helpers.formatS3Link(galleryImg?.image)}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% 50%',
            }}
            data-grid={{
              i: galleryImg.i,
              x: galleryImg.x,
              y: galleryImg.y,
              w: galleryImg.w,
              h: galleryImg.h,
            }}
          />
        ))}
      </ReactGridLayout>
    );
  };

  const galleryUploader = () => {
    return (
      <div className="gallery-uploader">
        <div>Image Gallery</div>
        <Button onClick={onEdit}>SELECT IMAGES</Button>
      </div>
    );
  };

  const images = getGalleryValue();

  return (
    <ComponentWrapper
      showDrag
      showActionButtons
      showEdit={images.length === 0 ? false : true}
      showDelete
      isDraggable
      sectionName={blockProps.sectionName}
      setDraggingElement={blockProps.setDraggingElement}
      blockKey={block.getKey()}
      remove={onRemove}
      onEdit={onEdit}
      onBlur={handleBlur}
      componentType={'Gallery'}
      handleEditComponent={blockProps.handleEditComponent}
      setDropDisabled={blockProps.setDropDisabled}>
      <div className="gallery-container">
        <div className="gallery-image-container" style={images.length ? {} : { height: '130px' }}>
          {!updating && !!images.length && getGrid(images)}
          {!updating && !images.length && galleryUploader()}
        </div>
      </div>
    </ComponentWrapper>
  );
};

GalleryComponent.propTypes = {
  block: PropTypes.instanceOf(Object).isRequired,
  blockProps: PropTypes.instanceOf(Object).isRequired,
};

export default GalleryComponent;
