import React, { useCallback, useState } from 'react';
import { Card, Col, Row, Space, Statistic, Table, DatePicker } from 'antd';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import _ from 'lodash';

import { FETCH_ADMIN_KIPS } from 'graphql/queries/adminQueries';
import Loader from 'components/Loader';
import kipsHelper from './helpers';

import './KpiDetails.scss';

const { RangePicker } = DatePicker;

const yearPrice = 60;
const monthPrice = 8;

const KpiDetails = () => {
  const initialFilters = {
    startDate: moment().subtract(1, 'month').toISOString(),
    endDate: moment().toISOString(),
    search: '',
  };

  const [users, setUsers] = useState();
  const [relevantUsers, setRelevantUsers] = useState([]);
  const [relevantTrans, setRelevantTrans] = useState([]);
  const [computedData, setComputedData] = useState({
    newTrials: [],
    totalTrials: [],
    expiredTrials: [],
    newPaidMonthSub: [],
    utmUsers: [],
    newPaidTrialSub: [],
    yearSubs: [],
    nonUtmUsers: [],
    newPaidYearSub: [],
    trialSubs: [],
    churnTrlSub: [],
    existingYearSub: [],
    existingMonthSub: [],
    monthSubs: [],
    churnYearSub: [],
    churnMonthSub: [],
    totalRevenue: 0,
  });
  const [filters, setFilters] = useState(initialFilters);

  // getting all payments user made
  // using some simple temporary memcaching because it happens a lot

  const updateAdminKips = (response) => {
    const userMap = {};
    const allusers = _.uniq(response.users);

    if (allusers) {
      allusers.forEach((u) => {
        const tempUser = JSON.parse(JSON.stringify(u));
        const daysSince = moment().diff(u.lastSeen, 'days');

        tempUser.daysSince = daysSince;
        tempUser.canceledPlan = kipsHelper.canceledPlan(u._id, u.paymentStatus.activeProfileId, {
          payments: u.paymentStatus.payments,
        });
        tempUser.paymentStatus['type'] = kipsHelper.uType(u);
        userMap[u._id] = tempUser;
      });

      setUsers(userMap);

      const utmUsers = _.compact(
        _.map(userMap, (u) =>
          moment(u.createdAt) > moment(filters.startDate) &&
          moment(u.createdAt) < moment(filters.endDate) &&
          u.utmData &&
          u.utmData.utm_source
            ? u
            : null
        )
      );
      const nonUtmUsers = _.compact(
        _.map(
          userMap,
          (u) =>
            moment(u.createdAt) > moment(filters.startDate) &&
            moment(u.createdAt) < moment(filters.endDate) &&
            (!u.utmData || !u.utmData.utm_source ? u : null)
        )
      );

      const newTrials = _.compact(
        _.map(userMap, (u) => {
          return moment(u.createdAt) > moment(filters.startDate) &&
            moment(u.createdAt) < moment(filters.endDate) &&
            u.paymentStatus.trialTime > 0 &&
            !kipsHelper.everPayed(u)
            ? u
            : null;
        })
      );

      const totalTrials = _.compact(
        _.map(userMap, (u) => {
          return u.paymentStatus.trialTime > 0 && !kipsHelper.everPayed(u) ? u : null;
        })
      );

      // now + trialTime days = exact time of expiration - if between dates then true
      const expiredTrials = _.compact(
        _.map(userMap, (u) => {
          const exacttime = moment().add(u.paymentStatus.trialTime, 'days');
          return u.paymentStatus.trialTime <= 0 &&
            exacttime > moment(filters.startDate) &&
            exacttime < moment(filters.endDate) &&
            !kipsHelper.everPayed(u)
            ? u
            : null;
        })
      );

      const newPaidMonthSub = _.compact(
        _.map(userMap, (u) => {
          if (u.paymentStatus.type === 'sub' && u.paymentStatus.subType === 'monthly') {
            const firstRealPay = kipsHelper.firstRealPayment(u);
            return firstRealPay > moment(filters.startDate) &&
              firstRealPay < moment(filters.endDate)
              ? u
              : null;
          }
        })
      );

      const newPaidTrialSub = _.compact(
        _.map(userMap, (u) => {
          if (u.paymentStatus.type === 'sub' && u.paymentStatus.subType === 'monthly') {
            const firstPay = kipsHelper.firstPayment(u);
            const trialPay = kipsHelper.trialPaytime(u);
            return trialPay &&
              firstPay > moment(filters.startDate) &&
              firstPay < moment(filters.endDate)
              ? u
              : null;
          }
        })
      );

      const newPaidYearSub = _.compact(
        _.map(userMap, (u) => {
          if (u.paymentStatus.type === 'sub' && u.paymentStatus.subType === 'yearly') {
            const firstPay = kipsHelper.firstPayment(u);
            return firstPay > moment(filters.startDate) && firstPay < moment(filters.endDate)
              ? u
              : null;
          }
        })
      );

      const existingMonthSub = _.compact(
        _.map(userMap, (u) => {
          const cancelTime = u.canceledPlan;
          const firstPay = kipsHelper.firstPayment(u);

          if (
            u.paymentStatus.type === 'sub' &&
            u.paymentStatus.subType === 'monthly' &&
            !cancelTime
          ) {
            return firstPay && firstPay < new Date(filters.startDate) ? u : null;
          }
        })
      );

      const existingYearSub = _.compact(
        _.map(userMap, (u) => {
          const cancelTime = u.canceledPlan;
          const firstPay = kipsHelper.firstPayment(u);
          if (
            u.paymentStatus.type === 'sub' &&
            u.paymentStatus.subType === 'yearly' &&
            !cancelTime
          ) {
            return firstPay && firstPay < new Date(filters.startDate) ? u : null;
          }
        })
      );

      const churnTrlSub = _.compact(
        _.map(userMap, (u) => {
          const cancelTime = u.canceledPlan;
          if (
            u.paymentStatus.type === 'sub' &&
            u.paymentStatus.subType === 'monthly' &&
            cancelTime
          ) {
            const firstRealPay = kipsHelper.firstRealPayment(u);
            if (firstRealPay) {
              return null;
            }
            const actualLastDay =
              new Date(filters.endDate) > moment().toDate()
                ? moment().toDate()
                : new Date(filters.startDate);
            const churned = cancelTime > new Date(filters.endDate) && cancelTime < actualLastDay;
            return churned ? u : null;
          }
        })
      );

      const churnMonthSub = _.compact(
        _.map(userMap, (u) => {
          const cancelTime = u.canceledPlan;
          if (
            u.paymentStatus.type === 'sub' &&
            u.paymentStatus.subType === 'monthly' &&
            cancelTime
          ) {
            const firstRealPay = kipsHelper.firstRealPayment(u);
            if (!firstRealPay) {
              return null;
            }
            const actualLastDay =
              new Date(filters.startDate) > moment().toDate()
                ? moment().toDate()
                : new Date(filters.startDate);
            const churned = cancelTime > new Date(filters.endDate) && cancelTime < actualLastDay;
            return churned ? u : null;
          }
        })
      );

      const churnYearSub = _.compact(
        _.map(userMap, (u) => {
          const cancelTime = u.canceledPlan;
          if (
            u.paymentStatus.type === 'sub' &&
            u.paymentStatus.subType === 'yearly' &&
            cancelTime
          ) {
            const firstRealPay = kipsHelper.firstRealPayment(u);
            if (!firstRealPay) {
              return null;
            }

            const actualLastDay =
              new Date(filters.endDate) > moment().toDate()
                ? moment().toDate()
                : new Date(filters.endDate);
            const churned = cancelTime > new Date(filters.startDate) && cancelTime < actualLastDay;
            return churned ? u : null;
          }
        })
      );

      // recurring payments in the time period ~~== number of subscriptions
      const monthSubs = _.compact(
        _.map(response.transactions, (t) => {
          const ret =
            t.trans.txn_type === 'recurring_payment' &&
            t.trans.product_name === `Prospero Monthly Subscription, $${monthPrice}`
              ? t
              : null;
          return ret;
        })
      );

      // trial payments in the time period ~~== number of trial subscriptions
      const trialSubs = _.compact(
        _.map(response.transactions, (t) => {
          const ret =
            t.trans.txn_type === 'recurring_payment_profile_created' &&
            t.trans.initial_payment_status === 'Completed' &&
            t.trans.initial_payment_txn_id &&
            parseFloat(t.trans.initial_payment_amount) === 1 &&
            t.trans.product_name === `Prospero Monthly Subscription, $${monthPrice}`
              ? t
              : null;
          return ret;
        })
      );

      const yearSubs = _.compact(
        _.map(response.transactions, (t) => {
          const ret =
            t.trans.txn_type === 'recurring_payment' &&
            t.trans.product_name === `Prospero Yearly Subscription, $${yearPrice}`
              ? t
              : null;
          return ret;
        })
      );

      const totalRevenue =
        trialSubs.length + monthSubs.length * monthPrice + yearSubs.length * yearPrice;

      setComputedData({
        newTrials,
        totalTrials,
        expiredTrials,
        newPaidMonthSub,
        utmUsers,
        newPaidTrialSub,
        yearSubs,
        nonUtmUsers,
        newPaidYearSub,
        trialSubs,
        churnTrlSub,
        existingYearSub,
        existingMonthSub,
        monthSubs,
        churnYearSub,
        churnMonthSub,
        totalRevenue,
      });
    }
  };

  const { loading: isFetchingAdminKips, refetch } = useQuery(FETCH_ADMIN_KIPS, {
    variables: {
      userCondition: {
        queryDates: {
          startDate: filters.startDate,
          endDate: filters.endDate,
        },
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchAdminKips }) => {
      updateAdminKips(fetchAdminKips);
    },
  });

  const userColumn = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (data) => (
        <div>
          <div>{data._id}</div>
          <div>{data.profile.name}</div>
          <div>{data.emails[0].address}</div>
        </div>
      ),
    },
    {
      title: 'Props',
      dataIndex: 'props',
      key: 'props',
    },
    {
      title: 'Days since activity',
      dataIndex: 'activity',
      key: 'activity',
    },
    {
      title: 'First payed',
      dataIndex: 'firstPayed',
      key: 'firstPayed',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Sub Type',
      dataIndex: 'subType',
      key: 'subType',
    },
    {
      title: 'Trial days left',
      dataIndex: 'daysLeft',
      key: 'daysLeft',
    },
  ];
  const transColumn = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Kind',
      dataIndex: 'kind',
      key: 'kind',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'User details',
      dataIndex: 'user',
      key: 'user',
      render: (userId) => {
        const data = users[userId];
        if (data) {
          return (
            <div>
              <div>{data._id}</div>
              <div>{data.profile.name}</div>
              <div>{data.emails[0].address}</div>
            </div>
          );
        }
        return <div>No info</div>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'TxnId',
      dataIndex: 'txnId',
      key: 'txnId',
    },
  ];

  const handleFilters = useCallback(
    (type, value) => {
      if (type === 'date') {
        [filters.startDate, filters.endDate] = value;
      } else {
        filters[type] = value;
      }
      setFilters({ ...filters });
    },
    [setFilters, filters]
  );

  if (isFetchingAdminKips) {
    return <Loader />;
  }

  return (
    <div>
      <div className="admin-table-wrapper">
        <div className="table-action">
          <h3>Total KIPS</h3>
          <div>
            <Space>
              <RangePicker
                value={[moment(filters.startDate), moment(filters.endDate)]}
                onChange={(values) => {
                  let dataValue = values;
                  if (!values) {
                    dataValue = [moment(), moment()];
                  }
                  handleFilters(
                    'date',
                    dataValue.map((el) => el.toISOString())
                  );
                  refetch();
                }}
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
              />
            </Space>
          </div>
        </div>
      </div>
      <div className="admin-table-wrapper">
        <Row gutter={16}>
          <Col span={12}>
            <Card className="kpi-card">
              <Statistic title="First day" value={moment(filters.startDate).format('DD/MM/YYYY')} />
              <Statistic title="Last day" value={moment(filters.endDate).format('DD/MM/YYYY')} />
              <Statistic
                title="Day window"
                value={moment(filters.startDate).diff(moment(filters.endDate), 'days')}
                prefix="days"
              />
            </Card>
          </Col>
          <Col span={12}>
            <Row gutter={5}>
              <Col span={8}>
                <Card
                  hoverable
                  onClick={() => {
                    setRelevantTrans(computedData.trialSubs);
                  }}>
                  <Statistic
                    title="Trial sub"
                    value={computedData.trialSubs.length}
                    suffix={`/ $${computedData.trialSubs.length}`}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  hoverable
                  onClick={() => {
                    setRelevantTrans(computedData.monthSubs);
                  }}>
                  <Statistic
                    title="Month sub"
                    value={computedData.monthSubs.length}
                    suffix={`/ $${computedData.monthSubs.length * monthPrice}`}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  hoverable
                  onClick={() => {
                    setRelevantTrans(computedData.yearSubs);
                  }}>
                  <Statistic
                    title="Year sub"
                    value={computedData.yearSubs.length}
                    suffix={`/ $${computedData.yearSubs.length * yearPrice}`}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="admin-table-wrapper">
        <Row gutter={16}>
          <Col span={4}>
            <Card
              hoverable
              onClick={() => {
                setRelevantUsers(computedData.nonUtmUsers);
              }}>
              <Statistic title="Sign ups no UTM" value={computedData.nonUtmUsers.length} />
            </Card>
          </Col>
          <Col span={4}>
            <Card
              hoverable
              onClick={() => {
                setRelevantUsers(computedData.utmUsers);
              }}>
              <Statistic title="Sign ups UTM" value={computedData.utmUsers.length} />
            </Card>
          </Col>
          <Col span={4}>
            <Card
              hoverable
              onClick={() => {
                setRelevantUsers(computedData.newTrials);
              }}>
              <Statistic title="New Trials" value={computedData.newTrials.length} />
            </Card>
          </Col>
          <Col span={4}>
            <Card
              hoverable
              onClick={() => {
                setRelevantUsers(computedData.totalTrials);
              }}>
              <Statistic title="Total Trials" value={computedData.totalTrials.length} />
            </Card>
          </Col>
          <Col span={4}>
            <Card
              hoverable
              onClick={() => {
                setRelevantUsers(computedData.expiredTrials);
              }}>
              <Statistic title="Expired Trials" value={computedData.expiredTrials.length} />
            </Card>
          </Col>
        </Row>
      </div>
      <div className="admin-table-wrapper">
        <Row gutter={16}>
          <Col span={8}>
            <Card>
              <h3>New Paid</h3>
              <Row gutter={5}>
                <Col span={8}>
                  <Card
                    hoverable
                    onClick={() => {
                      setRelevantUsers(computedData.newPaidTrialSub);
                    }}>
                    <Statistic title="Trial" value={computedData.newPaidTrialSub.length} />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    hoverable
                    onClick={() => {
                      setRelevantUsers(computedData.newPaidMonthSub);
                    }}>
                    <Statistic title="Month" value={computedData.newPaidMonthSub.length} />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    hoverable
                    onClick={() => {
                      setRelevantUsers(computedData.newPaidYearSub);
                    }}>
                    <Statistic title="Year" value={computedData.newPaidYearSub.length} />
                  </Card>
                </Col>
              </Row>
              <h4>
                Total : $
                {computedData.newPaidTrialSub.length +
                  computedData.newPaidMonthSub.length +
                  computedData.newPaidYearSub.length}
              </h4>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <h3>Existing</h3>
              <Row gutter={5}>
                <Col span={12}>
                  <Card
                    hoverable
                    onClick={() => {
                      setRelevantUsers(computedData.existingMonthSub);
                    }}>
                    <Statistic title="Month" value={computedData.existingMonthSub.length} />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    hoverable
                    onClick={() => {
                      setRelevantUsers(computedData.existingYearSub);
                    }}>
                    <Statistic title="Year" value={computedData.existingYearSub.length} />
                  </Card>
                </Col>
              </Row>

              <h4>
                Total : $
                {computedData.existingMonthSub.length + computedData.existingYearSub.length}
              </h4>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <h3>Churn</h3>
              <Row gutter={5}>
                <Col span={8}>
                  <Card
                    hoverable
                    onClick={() => {
                      setRelevantUsers(computedData.churnTrlSub);
                    }}>
                    <Statistic title="Trial" value={computedData.churnTrlSub.length} />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    hoverable
                    onClick={() => {
                      setRelevantUsers(computedData.churnMonthSub);
                    }}>
                    <Statistic title="Month" value={computedData.churnMonthSub.length} />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    hoverable
                    onClick={() => {
                      setRelevantUsers(computedData.churnYearSub);
                    }}>
                    <Statistic title="Year" value={computedData.churnYearSub.length} />
                  </Card>
                </Col>
              </Row>

              <h4>
                Total : $
                {computedData.churnTrlSub.length +
                  computedData.churnMonthSub.length +
                  computedData.churnYearSub.length}
              </h4>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="admin-table-wrapper">
        <Row gutter={16}>
          <Col span={8}>
            <Card className="kpi-card">
              <Statistic
                title="Total revenue for time period"
                value={computedData.totalRevenue}
                prefix="$"
              />
              <Statistic
                title="ARPA"
                value={
                  computedData.totalRevenue /
                  (
                    computedData.newPaidTrialSub.length +
                    computedData.newPaidMonthSub.length +
                    computedData.newPaidYearSub.length +
                    computedData.existingMonthSub.length +
                    computedData.existingYearSub.length +
                    computedData.churnMonthSub.length +
                    computedData.churnYearSub.length
                  ).toFixed(0)
                }
                prefix="$"
              />
            </Card>
          </Col>
        </Row>
      </div>
      <div className="admin-table-wrapper">
        <div className="table-action">
          <h3>Users</h3>
        </div>
        <Table
          dataSource={relevantUsers.map((el, index) => ({
            dataIndex: index + 1,
            details: el,
            activity: el.daysSince,
            createdAt: moment(el.createdAt).format('DD/MM/YYYY'),
            props: el.props && el.props.length,
            firstPayed: moment(kipsHelper.firstPayment(el)).format('DD/MM/YYYY'),
            type: el.type,
            subType: el.paymentStatus.subType,
            daysLeft: el.paymentStatus.trialTime.toFixed(1),
          }))}
          columns={userColumn}
        />
      </div>
      <div className="admin-table-wrapper">
        <div className="table-action">
          <h3>Trans</h3>
        </div>
        <Table
          dataSource={relevantTrans.map((el) => ({
            createdAt: moment(el.time).toISOString(),
            kind: el.trans && (el.trans.item_number || el.trans.product_name),
            type: el.trans.txn_type || 'none',
            user: el.uid,
            amount: el.trans.mc_gross,
            txnId: el.trans.txn_id,
          }))}
          columns={transColumn}
        />
      </div>
    </div>
  );
};

export default KpiDetails;
