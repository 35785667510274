import React from 'react';
import { Row, Col } from 'antd';

import './ContentLoader.scss';

const Skeleton = (props) => <div className="content-library-skeleton" {...props}></div>;

const ContentSkeleton = () => (
  <Col
    xs={{ span: 24, offset: 0, order: 2 }}
    sm={{ span: 24, offset: 0, order: 2 }}
    md={{ span: 24, offset: 0, order: 2 }}
    lg={{ span: 6, offset: 0, order: 1 }}
    xl={{ span: 8, offset: 0, order: 1 }}
    className="value">
    <Skeleton />
  </Col>
);

const ContentLoader = () => {
  return (
    <div className="content-library-skeleton-container scroll-wrapper">
      <Row className="item-row covers-card">
        {Array.from({ length: 3 }, (_, x) => (
          <ContentSkeleton key={x} />
        ))}
      </Row>

      <div className="divider-line" />

      <Row className="item-row covers-card">
        {Array.from({ length: 8 }, (_, x) => (
          <ContentSkeleton key={x} />
        ))}
      </Row>
    </div>
  );
};

const ContentLoaderSingleLine = () => {
  return (
    <div className="content-library-skeleton-container scroll-wrapper">
      <Row className="item-row covers-card">
        {Array.from({ length: 4 }, (_, x) => (
          <ContentSkeleton key={x} />
        ))}
      </Row>
    </div>
  );
};

export { ContentLoader, ContentLoaderSingleLine };
