import React from 'react';
import { Affix, Button } from 'antd';
import ArrowTop from 'components/Icons/ArrowTop';
import PropTypes from 'prop-types';

import './ScrollToTopButton.scss';

const ScrollToTopButton = ({ showScrollTop, scrollToTop }) => (
  <Affix style={{ position: 'fixed', bottom: 78, right: 26 }}>
    <Button
      shape="circle"
      className={`arrow-top-button scrollTop ${showScrollTop ? '' : 'hide'}`}
      icon={<ArrowTop />}
      size="large"
      onClick={scrollToTop}
    />
  </Affix>
);

ScrollToTopButton.defaultProps = {
  showScrollTop: false,
  scrollToTop: () => {},
};

ScrollToTopButton.propTypes = {
  showScrollTop: PropTypes.bool,
  scrollToTop: PropTypes.func,
};

export default ScrollToTopButton;
