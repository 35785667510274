import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

import utils from 'utils/utils';
import DownloadPdfAnimation from 'components/Icons/DownloadPdfAnimation';
import CloseIcon from 'components/Icons/CloseIcon';
import PdfAdobeIcon from 'components/Icons/PdfAdobeIcon';

import './ClientSignProposalModal.scss';

let intervalHandle = '';

const ClientSignProposalModal = ({
  state,
  redirectTo,
  redirectAfter,
  onClose,
  downloadPdf,
  configText,
  btnText,
  proposal,
}) => {
  const language = proposal?.language?.toLowerCase();
  const defaultBtnText = configText('sign proposal button text');
  const title = utils.getSignText(
    configText('client pdf dialog title'),
    btnText !== defaultBtnText,
    language
  );

  const [redirectionInfo, setRedirectionInfo] = useState('');
  const [redirectUrl, setRedirectUrl] = useState(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: DownloadPdfAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getValidUrl = (url = '') => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, '');

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `http://${newUrl}`;
    }

    return newUrl;
  };

  const clearCountDown = () => {
    clearInterval(intervalHandle);
    intervalHandle = null;
    setRedirectionInfo('');
    onClose();
  };

  const startCountDown = (time) => {
    let secondsRemaining = time,
      minutes,
      seconds;

    const tick = () => {
      var min = Math.floor(secondsRemaining / 60);
      var sec = secondsRemaining - min * 60;

      minutes = min;
      seconds = sec;

      if (sec < 10) {
        seconds = '0' + seconds;
      }

      if (min < 10) {
        minutes = '0' + minutes;
      }

      setRedirectionInfo(`You'll be redirected in ${minutes}:${seconds}`);

      if ((min === 0) & (sec === 0)) {
        clearCountDown();
      }

      secondsRemaining--;
    };

    intervalHandle = setInterval(tick, 1000);
  };

  useEffect(() => {
    // already processed
    if (redirectUrl !== null) {
      return;
    }

    if (proposal && redirectTo) {
      // check if redirectTo is flagged as spam
      const isBlocked = proposal?.spamUrls
        ? proposal.spamUrls.some((s) => s.url === redirectTo && s.blocked)
        : false;

      if (isBlocked || redirectTo?.includes('[dot]')) {
        setRedirectUrl(false);
      } else {
        setRedirectUrl(redirectTo);
      }
    }
  }, [redirectUrl]); //eslint-disable-line

  useEffect(() => {
    if (redirectUrl && !redirectionInfo) {
      // startTimerInterval = setTimeout(
      //   () => {
      //     window.location = getValidUrl(redirectUrl);
      //   },
      //   redirectAfter ? redirectAfter * 1000 : 1000
      // );
      state?.onRedirect(getValidUrl(redirectTo), redirectAfter);
      startCountDown(redirectAfter ? redirectAfter : 0);
    }
  }, [redirectUrl]); //eslint-disable-line

  const onCancel = () => {
    clearCountDown();
  };

  const onDownloadPdf = () => {
    clearCountDown();
    downloadPdf();
  };

  const getConfigTextSubtitle = utils.getSentenceAndSplit(
    7,
    configText('client pdf dialog subtitle')
  );

  return (
    <Modal
      className="simple-info-modal client-sign-modal"
      centered
      visible
      footer={null}
      closeIcon={<CloseIcon className="close-icon" />}
      onCancel={onCancel}>
      <h4 className="title">{title}</h4>
      <div className="divider-thanks" />

      <div className="body-container">
        <div className="body">
          {getConfigTextSubtitle.map((name, subtitleIndix) => (
            <p className="subtitle-font" key={subtitleIndix}>
              {utils.getSignText(name, btnText !== defaultBtnText, language)}
            </p>
          ))}
        </div>
        <Lottie className="lottie" options={defaultOptions} height={300} width={300} />
      </div>

      <div className="button-wrapper">
        <Button className="button primary" type="primary" onClick={onDownloadPdf}>
          <PdfAdobeIcon />
          {configText('client pdf dialog download button')}
        </Button>
        <span>{redirectionInfo}</span>
      </div>
    </Modal>
  );
};

ClientSignProposalModal.defaultProps = {
  redirectTo: '',
  redirectAfter: 0,
};

ClientSignProposalModal.propTypes = {
  redirectTo: PropTypes.string,
  redirectAfter: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  configText: PropTypes.func.isRequired,
  downloadPdf: PropTypes.func.isRequired,
};

export default ClientSignProposalModal;
