/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="21" height="21" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="4" fill="#F5F6FA" />
    <path
      d="M22.7008 14.9543L11.6135 8.69747C10.7126 8.18935 9.33301 8.68244 9.33301 9.93922V22.4499C9.33301 23.5774 10.615 24.2569 11.6135 23.6916L22.7008 17.4378C23.6899 16.8816 23.693 15.5105 22.7008 14.9543Z"
      fill="#AFB1BA"
    />
  </svg>
);

const ProjectVideoIcon = (props) => <Icon component={svg} {...props} />;

export default ProjectVideoIcon;
