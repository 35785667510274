import React, { useEffect } from 'react';
import { useReactiveVar, useQuery } from '@apollo/client';

import PageHeader from 'components/PageHeader';
import StripeIntegration from './components/StripeIntegration';
import ZapierIntegration from './components/ZapierIntegration';
import QuickbooksIntegration from './components/QuickbooksIntegration';
import IntegromatIntegration from './components/IntegromatIntegration';
import FreshbooksIntegration from './components/FreshbooksIntegration';
import XeroIntegration from './components/XeroIntegration';
import SlackIntegration from './components/SlackIntegration';
import CodeIntegration from './components/CodeIntegration';
import UserwayIntegration from './components/UserwayIntegration';
import WixIntegration from './components/WixIntegration';
import MorningIntegration from './components/MorningIntegration';
import MondayIntegration from './components/MondayIntegration';
import Loader from 'components/Loader';

import { getUserId, userVar } from 'graphql/cache';
import { USER_DETAILS } from 'graphql/queries/userQueries';

import './Integration.scss';
import { ChatwayIntegration } from './components/ChatwayIntegration';

const Integration = () => {
  let user = useReactiveVar(userVar);

  const { data: userInfo, refetch: refetchUser } = useQuery(USER_DETAILS, {
    variables: { id: getUserId() },
    skip: user || !getUserId(),
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      const { fetchUser } = res;
      userVar({ ...user, ...fetchUser });
    },
  });

  useEffect(() => {
    document.title = 'Prospero - Integrations';
  });

  const handleRefetchUser = async () => {
    try {
      const {
        data: { fetchUser },
      } = await refetchUser();
      return userVar({ ...user, ...fetchUser });
    } catch (error) {
      console.error(error);
    }
  };

  user = user || userInfo?.fetchUser;

  return !user ? (
    <Loader />
  ) : (
    <div className="container">
      <div className="integration">
        <div className="integration-header">
          <PageHeader pageTitle="Integrations" />
        </div>
        <div className="integration-main-content">
          <StripeIntegration
            data={user.stripe || {}}
            userEmail={user.emails?.[0]?.address}
            userRole={user?.roles && user?.roles[0]}
            handleRefetchUser={handleRefetchUser}
          />
          <ChatwayIntegration
            data={user.chatwayScripts || []}
            userEmail={user.emails?.[0]?.address}
            userRole={user?.roles && user?.roles[0]}
            handleRefetchUser={handleRefetchUser}
          />
          {!user?.paymentStatus?.canLock && user?.profile?.oldWixUser && (
            <WixIntegration
              data={user?.wix}
              userRole={user?.roles && user?.roles[0]}
              handleRefetchUser={handleRefetchUser}
            />
          )}
          <ZapierIntegration data={user.zaps} userRole={user?.roles && user?.roles[0]} />
          <IntegromatIntegration
            data={user.integromatHooks}
            userVar={userVar}
            handleRefetchUser={handleRefetchUser}
            userRole={user?.roles && user?.roles[0]}
          />
          <FreshbooksIntegration
            data={user.freshbooks}
            userRole={user?.roles && user?.roles[0]}
            refetchUser={refetchUser}
            user={user}
          />
          <QuickbooksIntegration
            data={user.quickbooks}
            userRole={user?.roles && user?.roles[0]}
            refetchUser={refetchUser}
            user={user}
          />
          <XeroIntegration
            data={user.xero}
            userRole={user?.roles && user?.roles[0]}
            refetchUser={handleRefetchUser}
            user={user}
          />
          <MorningIntegration
            data={user.morninginvoice}
            userRole={user?.roles && user?.roles[0]}
            handleRefetchUser={handleRefetchUser}
            user={user}
          />
          <MondayIntegration
            data={user.monday}
            userRole={user?.roles && user?.roles[0]}
            refetchUser={handleRefetchUser}
            user={user}
          />
          <SlackIntegration
            data={user.slack}
            userRole={user?.roles && user?.roles[0]}
            refetchUser={refetchUser}
            user={user}
          />
          <UserwayIntegration data={user?.userway || false} />
          <CodeIntegration data={user.scripts || []} userRole={user?.roles && user?.roles[0]} />
        </div>
      </div>
    </div>
  );
};

export default Integration;
