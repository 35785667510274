import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SignProposalModal from './SignProposalModal/SignProposalModal';
import ThanksSignProposalModal from './ThanksSignProposalModal/ThanksSignProposalModal';
import TestSignProposalModal from './TestSignProposalModal/TestSignProposalModal';
import ClientSignProposalModal from './ClientSignProposalModal/ClientSignProposalModal';
import helpers from 'helpers';
import { PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';

const SignatureModals = ({
  state,
  dispatch,
  proposal,
  setProp,
  updateProposalClient,
  isClient,
  configText,
  downloadPdf,
  user,
  clientType,
  clientWixPreview,
  isEditingModal,
}) => {
  const [sigName, setSigName] = useState(undefined);
  const [signing, setSigning] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    proposal.selectedOption ? proposal.selectedOption : null
  );

  const defaultBtnText = configText('sign proposal button text');
  const [btnText, setBtnText] = useState(defaultBtnText);

  useEffect(() => {
    // get button text based on signer type
    if (state?.showOrHideSignSignature === 'main') {
      setBtnText(proposal?.draft?.signature?.btnText || defaultBtnText);
    } else if (state?.showOrHideSignSignature === 'secondary') {
      setBtnText(proposal?.draft?.signature?.btnText2 || defaultBtnText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal, state.showOrHideSignSignature]);

  const signProposal = async ({ signature, showOrHideSignSignature }) => {
    signature = signature || sigName;
    if (!selectedOption && proposal.pricing && proposal.pricing.strategy !== 'value') {
      setSigning(true);
      dispatch({ type: 'setIsSigned', isSigned: 'Signing...' });
    }

    let clientIP;

    try {
      await Promise.all([
        new Promise((resolve, reject) =>
          fetch('https://www.cloudflare.com/cdn-cgi/trace')
            .then((res) => res.text())
            .then((result) => {
              result = helpers.transformReadableText(result);
              if (result) {
                clientIP = result.ip;
                resolve(result);
              }
            })
            .catch((error) => {
              reject(error);
            })
        ),
      ]);
    } catch (error) {
      console.error(error);
    }

    let proposalInfo = {
      id: proposal._id,
      _id: proposal._id,
      pid: proposal.pid,
      uid: proposal.uid,
    };

    proposalInfo = Object.assign(proposalInfo, {
      state: 'approved',
      prevState: 'seen',
      signature: {
        name:
          state.showOrHideSignSignature === 'main' || showOrHideSignSignature === 'main'
            ? signature
            : proposal?.signature?.name,
        additionalSignature:
          state.showOrHideSignSignature === 'secondary' || showOrHideSignSignature === 'secondary'
            ? signature
            : proposal?.signature?.additionalSignature,
        myname: proposal?.signature?.myname,
        email: 'no@email.com',
        date: new Date(new Date().toUTCString()).toISOString(),
        clientIP: clientIP || (await helpers.getBrowserName()),
        device: /Mobi|Android/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop',
      },
      payedfor: false,
      selectedOption: selectedOption,
    });

    updateProposalClient({
      variables: {
        topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
          proposalInfo?.channel || proposalInfo?.auid || proposalInfo?.uid
        }`,
        updateType: 'sign',
        proposal: proposalInfo,
        origin: window.location.origin,
      },
    }).then(({ data }) => {
      if (data) {
        setProp(
          Object.assign(
            JSON.parse(JSON.stringify(proposal)),
            JSON.parse(JSON.stringify(proposalInfo))
          )
        );
        try {
          var thedate = new Date();
          if (window.location.href.indexOf('/cd/') === -1 && !clientWixPreview) {
            window.ga('set', 'dimension2', thedate.getMonth() + 1 + '-' + thedate.getFullYear());
            window.ga('send', 'event', 'prop-state', 'signed', window.location.href);
          }
        } catch (error) {
          console.log(error);
        }

        dispatch({ type: 'setIsSigned', isSigning: 'signed' });
        dispatch({ type: 'setTestSign', testSign: false });
        dispatch({
          type: 'setShowOrHideSignSignature',
          showOrHideSignSignature: false,
        });
        setSigning(false);
        dispatch({ type: 'setThanksClientSign', thanksClientSign: true });
      } else {
        dispatch({ type: 'setIsSigned', isSigning: 'failed' });
        setSigning(false);
      }
    });
  };
  return (
    <>
      {state.showOrHideSignSignature && !isEditingModal && (
        <SignProposalModal
          btnText={btnText}
          signing={signing}
          setSigning={setSigning}
          sigName={sigName}
          setSigName={setSigName}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          signProposal={signProposal}
          dispatch={dispatch}
          proposal={proposal}
          showOrHideSignSignature={state.showOrHideSignSignature}
          onCancel={() =>
            dispatch({
              type: 'setShowOrHideSignSignature',
              showOrHideSignSignature: false,
            })
          }
          configText={configText}
          isClient={isClient}
          clientWixPreview={clientWixPreview}
        />
      )}
      {state.thanksClientSign && !isEditingModal && (
        <ClientSignProposalModal
          state={state}
          proposal={proposal}
          btnText={btnText}
          redirectTo={proposal.proposalRedirectTo || proposal.redirectTo}
          redirectAfter={
            proposal.proposalRedirectTo ? proposal.proposalRedirectAfter : proposal.redirectAfter
          }
          configText={configText}
          downloadPdf={downloadPdf}
          onClose={() =>
            dispatch({
              type: 'setThanksClientSign',
              thanksClientSign: false,
            })
          }
        />
      )}
      {state.thanksSign && (
        <ThanksSignProposalModal
          onCancel={() =>
            dispatch({
              type: 'setThanksSign',
              thanksSign: false,
            })
          }
          configText={configText}
        />
      )}
      {state.testSign && !isEditingModal && (
        <TestSignProposalModal
          clientType={clientType}
          signing={signing}
          proposal={proposal}
          btnText={btnText}
          dateFormat={user?.profile?.dateFormat}
          onCancel={() => {
            dispatch({
              type: 'setTestSign',
              testSign: false,
            });
            dispatch({ type: 'setThanksSign', thanksSign: true });
          }}
          onConfirm={() => {
            setSigning(true);
            signProposal({
              signature: state?.testSign?.signature,
              showOrHideSignSignature: state?.testSign?.showOrHideSignSignature,
            });
          }}
          configText={configText}
        />
      )}
    </>
  );
};

SignatureModals.defaultProps = {
  proposal: '',
  isClient: false,
  configText: () => {},
  setProp: () => {},
  updateProposalClient: () => {},
  user: null,
  isEditingModal: false,
};

SignatureModals.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  proposal: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  isClient: PropTypes.bool,
  configText: PropTypes.func,
  setProp: PropTypes.func,
  updateProposalClient: PropTypes.func,
  downloadPdf: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object),
  clientType: PropTypes.string,
  isEditingModal: PropTypes.bool,
};

export default SignatureModals;
