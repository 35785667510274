/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import { EditorState, ContentState } from 'draft-js';
import utils from '../utils';
import {
  ImageComponent,
  VideoComponent,
  GalleryComponent,
  TableComponent,
  TestimonialComponent,
  FormComponent,
  PriceMilesComponent,
  DividerComponent,
  HTMLComponent,
} from '../components';

import { WixPriceMiles } from '../wix-components';

const blockRenderer = ({
  setElementsInSection,
  sectionType,
  block,
  state,
  dispatch,
  props,
  editorChange,
  onEdit,
  upload,
  updateLoaderState,
  handleImageInsert,
  handleInsertVideo,
  numberOfColumns,
  setDraggingElement,
  sectionSpan,
  wixEditor,
  templateWixEditor,
  isEditingModal,
  editorRef,
  setDeletedBlockKeys,
  sectionName,
}) => {
  let keysDeleted = [];

  if (block.getType() === 'atomic') {
    const titlePosition =
      props?.prop?.draft?.[sectionName]?.titlePosition || props?.prop?.draft?.titleFont?.position;

    let resizeWidth = ['top', 'top-middle', 'none'].includes(titlePosition)
      ? 796
      : titlePosition === 'large'
      ? 664
      : 540;

    // wil divide the image width by this factor
    let resizefactor = numberOfColumns || 1;
    if (numberOfColumns === 2) {
      // 4 for col span 6, 1.33 for col span 18
      resizefactor = 24 / sectionSpan;
    }

    const contentState = ContentState.createFromBlockArray([block]);
    const ent = block.getEntityAt(0);

    if (!ent) return;

    const entity = contentState.getEntity(ent);
    const data = entity?.data;
    const type = entity?.type;

    if (data && data.texcontent) {
      if (!keysDeleted.includes(block.getKey())) {
        setElementsInSection('price', 'add', block.getKey());
      }
      return {
        component: wixEditor || templateWixEditor ? WixPriceMiles : PriceMilesComponent,
        editable: false,
        props: {
          setDraggingElement,
          texcontent: data.texcontent,
          sectionName: props.sectionName,
          prop: props.prop,
          editorState: state.editorState,
          language: props.prop.language,
          isSaving: props.saving,
          setDropDisabled: props.setDropDisabled,
          isEditingModal: isEditingModal,
          configText: (x) => {
            return props.config[x] || x;
          },
          onEdit: (x) => {
            const { editorState } = state;
            x.editorState = editorState;
            onEdit(x);
          },
          onStartEdit: (blockKey) => {
            const { liveTeXEdits } = state;
            dispatch({
              type: 'setLiveTeXEdits',
              liveTeXEdits: liveTeXEdits.set(blockKey, true),
            });
          },
          onFinishEdit: (blockKey) => {
            const { liveTeXEdits } = state;
            dispatch({
              type: 'setLiveTeXEdits',
              liveTeXEdits: liveTeXEdits.remove(blockKey),
            });
          },
          onRemove: (blockKey) => {
            setElementsInSection('price', 'remove', blockKey);
            keysDeleted = keysDeleted.push(blockKey);
            utils.removeTeX({
              blockKey,
              state,
              props,
              dispatch,
              editorRef,
              editorChange,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            });
          },
          handleEditComponent: (value) => {
            dispatch({
              type: 'setEditorReadOnly',
              editorReadOnly: value,
            });
          },
          handleDuplicateElement: (content, entityType, key) =>
            utils.handleDuplicateElement({
              content,
              entityType,
              key,
              state,
              dispatch,
              props,
              editorChange,
              editorRef,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            }),
        },
      };
    }
    if (data && (type === 'image' || type === 'IMAGE')) {
      return {
        component: ImageComponent,
        editable: false,
        props: {
          setDraggingElement,
          upload,
          sectionName: props.sectionName,
          type: sectionType === 'image' ? 'section-image' : '',
          proposalId: props.prop._id,
          language: props.prop.language,
          titleFontPosition: props?.prop?.draft?.titleFont?.position,
          setDropDisabled: props.setDropDisabled,
          resizeWidth,
          resizefactor,
          dispatch,
          update: (immediateUpdate) => {
            editorChange({
              editorState: state.editorState,
              props,
              dispatch,
              updateSelection: utils.updateSelection,
              force: immediateUpdate,
              editorRef,
            });
          },
          editComponent: () => handleImageInsert(state.editorState),
          handleEditComponent: (value, event) => {
            const currentSelection = state?.editorState?.getSelection();
            const selectedBlockKey = currentSelection?.getFocusKey();

            if (selectedBlockKey === block?.getKey() || event === 'drag') {
              dispatch({
                type: 'setEditorReadOnly',
                editorReadOnly: value,
              });
            }
          },
          onRemove: (blockKey) => {
            utils.removeTeX({
              blockKey,
              state,
              props,
              editorRef,
              dispatch,
              editorChange,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            });
          },
        },
      };
    }
    if (data && data.data && type === 'table') {
      return {
        component: TableComponent,
        editable: false,
        props: {
          setDraggingElement,
          sectionName: props.sectionName,
          setDropDisabled: props.setDropDisabled,
          onEdit: (x) => {
            const { editorState } = state;
            x.editorState = editorState;
            onEdit(x);
          },
          language: props.prop.language,
          onRemove: (blockKey) => {
            utils.removeTeX({
              blockKey,
              state,
              props,
              dispatch,
              editorRef,
              editorChange,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            });
          },
          onFinishEdit: (blockKey) => {
            const { liveTeXEdits } = state;
            dispatch({
              type: 'setLiveTeXEdits',
              liveTeXEdits: liveTeXEdits.remove(blockKey),
            });
          },
          update: () => props.saveDraft(state.editorState),
          handleDuplicateElement: (content, entityType, key) => {
            console.log(content, entityType, key);
            utils.handleDuplicateElement({
              content,
              entityType,
              key,
              state,
              dispatch,
              props,
              editorChange,
              editorRef,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            });
          },
          handleEditComponent: (value) => {
            dispatch({
              type: 'setEditorReadOnly',
              editorReadOnly: value,
            });
          },
        },
      };
    }
    if (data && data.data && type === 'testimonial') {
      // blockRenderer TestimonialComponent
      return {
        component: TestimonialComponent,
        editable: false,
        props: {
          setDraggingElement,
          setDeletedBlockKeys,
          bodyFont: props.prop?.draft?.bodyFont,
          sectionName: props.sectionName,
          setDropDisabled: props.setDropDisabled,
          uniqueId: props?.prop?._id || props?.sectionName,
          onEdit: (x) => {
            const { editorState } = state;
            x.editorState = editorState;
            onEdit(x);
          },
          language: props.prop.language,
          onRemove: (blockKey) => {
            utils.removeTeX({
              blockKey,
              state,
              props,
              dispatch,
              editorChange,
              editorRef,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            });
          },
          onFinishEdit: (blockKey) => {
            const { liveTeXEdits } = state;
            dispatch({
              type: 'setLiveTeXEdits',
              liveTeXEdits: liveTeXEdits.remove(blockKey),
            });
          },
          update: () => props.saveDraft(state.editorState),
          handleDuplicateElement: (content, entityType, key) => {
            utils.handleDuplicateElement({
              content,
              entityType,
              key,
              state,
              dispatch,
              props,
              editorChange,
              editorRef,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            });
          },
          handleEditComponent: (value) => {
            dispatch({
              type: 'setEditorReadOnly',
              editorReadOnly: value,
            });
          },
        },
      };
    }
    if (data && data.data && type === 'form') {
      return {
        component: FormComponent,
        editable: false,
        props: {
          setDraggingElement,
          sectionName: props.sectionName,
          setDropDisabled: props.setDropDisabled,
          configText: (x) => {
            return props.config[x] || x;
          },
          language: props.prop.language,
          onEdit: (x) => {
            const { editorState } = state;
            x.editorState = editorState;
            onEdit(x);
          },
          onRemove: (blockKey) => {
            utils.removeTeX({
              blockKey,
              state,
              props,
              dispatch,
              editorRef,
              editorChange,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            });
          },
          onFinishEdit: (blockKey) => {
            const { liveTeXEdits } = state;
            dispatch({
              type: 'setLiveTeXEdits',
              liveTeXEdits: liveTeXEdits.remove(blockKey),
            });
          },
          update: () => props.saveDraft(state.editorState),
          handleDuplicateElement: (content, entityType, key) => {
            console.log(content, entityType, key);
            utils.handleDuplicateElement({
              content,
              entityType,
              key,
              state,
              dispatch,
              props,
              editorChange,
              editorRef,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            });
          },
          handleEditComponent: (value) => {
            dispatch({
              type: 'setEditorReadOnly',
              editorReadOnly: value,
            });
          },
        },
      };
    }
    if (data && data.data && type === 'gallery') {
      return {
        component: GalleryComponent,
        editable: false,
        props: {
          setDraggingElement,
          sectionName: props.sectionName,
          language: props.prop.language,
          setDropDisabled: props.setDropDisabled,
          onEdit: (x) => {
            const { editorState } = state;
            x.editorState = editorState;
            onEdit(x, type);
          },
          onRemove: (blockKey) => {
            utils.removeTeX({
              blockKey,
              state,
              props,
              dispatch,
              editorRef,
              editorChange,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            });
          },
          onFinishEdit: (blockKey) => {
            const { liveTeXEdits } = state;
            dispatch({
              type: 'setLiveTeXEdits',
              liveTeXEdits: liveTeXEdits.remove(blockKey),
            });
          },
          update: () => props.saveDraft(state.editorState),
          handleDuplicateElement: (content, entityType, key) =>
            utils.handleDuplicateElement({
              content,
              entityType,
              key,
              state,
              dispatch,
              props,
              editorChange,
              editorRef,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            }),
          handleEditComponent: (value) => {
            dispatch({
              type: 'setEditorReadOnly',
              editorReadOnly: value,
            });
          },
        },
      };
    }
    if (data && type === 'divider') {
      return {
        component: DividerComponent,
        editable: false,
        props: {
          update: () => props.saveDraft(state.editorState),
        },
      };
    }
    if (data && type === 'html') {
      return {
        component: HTMLComponent,
        editable: false,
        props: {
          setDraggingElement,
          sectionName: props.sectionName,
          setDropDisabled: props.setDropDisabled,
          onEdit: (x) => {
            const { editorState } = state;
            x.editorState = editorState;
            onEdit(x, type);
          },
          language: props.prop.language,
          resizeWidth,
          onRemove: (blockKey) => {
            utils.removeTeX({
              blockKey,
              state,
              props,
              dispatch,
              editorRef,
              editorChange,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            });
          },
          update: () => props.saveDraft(state.editorState),
          handleDuplicateElement: (content, entityType, key) => {
            utils.handleDuplicateElement({
              content,
              entityType,
              key,
              state,
              dispatch,
              props,
              editorChange,
              editorRef,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            });
          },
          handleEditComponent: (value) => {
            dispatch({
              type: 'setEditorReadOnly',
              editorReadOnly: value,
            });
          },
        },
      };
    }
    if (data && type === 'media') {
      return {
        component: VideoComponent,
        editable: false,
        props: {
          setDraggingElement,
          sectionName: props.sectionName,
          setDropDisabled: props.setDropDisabled,
          onRemove: (blockKey) => {
            utils.removeTeX({
              blockKey,
              state,
              props,
              dispatch,
              editorRef,
              editorChange,
              updateLoaderState,
              updateSelection: utils.updateSelection,
            });
          },
          language: props.prop.language,
          editComponent: () => handleInsertVideo(state.editorState),
          handleEditComponent: (value) => {
            dispatch({
              type: 'setEditorReadOnly',
              editorReadOnly: value,
            });
          },
        },
      };
    }
  }

  return null;
};

export default blockRenderer;
