import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="20" fill="#01C44F" />
  </svg>
);

const CurrentProgressIcon = (props) => <Icon component={svg} {...props} />;

export default CurrentProgressIcon;
