/* eslint-disable */
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { Col, Divider, Popover, Row, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import download from 'downloadjs';

import DocxIcon from 'components/Icons/DocxIcon';
import PdfIcon from 'components/Icons/PdfIcon';
import XlsxIcon from 'components/Icons/XlsxIcon';
import DeleteIcon from 'components/Icons/DeleteIcon';
import DownloadIcon from 'components/Icons/DownloadIcon';
import FileIcon from 'components/Icons/FileIcon';
import Button from 'components/Button';
import { DELETE_PROPOSAL_ATTACHMENT } from 'graphql/mutations/proposalMutation';
import { PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';

import './AttachmentSection.scss';

const getIcon = (attachment) => {
  if (attachment.type === 'application/pdf') {
    return <PdfIcon />;
  } else if (
    [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ].includes(attachment.type)
  ) {
    return <XlsxIcon />;
  } else if (
    [
      'attachmentapplication/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ].includes(attachment.type)
  ) {
    return <DocxIcon />;
  }
  return <FileIcon />;
};

const AttachmentSection = ({
  prop,
  showAttachmentModal,
  preview,
  setProp,
  propRef,
  configText,
  setDownloadingFile,
}) => {
  const [deleteAttachment] = useMutation(DELETE_PROPOSAL_ATTACHMENT, {
    onCompleted: ({ deleteProposalAttachment }) => {
      propRef.current = { ...prop, ...deleteProposalAttachment };
      setProp({ ...prop, ...deleteProposalAttachment });
    },
  });

  const [selectedAttachment, setSelectedAttachment] = useState(false);

  const { pathname } = useLocation();

  const ispdf = pathname.indexOf('/PDF/') !== -1;

  const handleDeleteVisibleChange = (visible) => setSelectedAttachment(visible);

  const downloadAttachment = async (attachment) => {
    try {
      setDownloadingFile(true);
      fetch(attachment.url)
        .then((response) => response.blob())
        .then((myBlob) => {
          download(myBlob, attachment.name, attachment.type);
          setDownloadingFile(false);
        });
    } catch (error) {
      download(attachment.url);
      setDownloadingFile(false);
    }
  };

  const removeAttachment = async () => {
    await deleteAttachment({
      variables: {
        proposalId: prop._id,
        index: selectedAttachment,
        topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${prop?.channel || prop?.auid || prop?.uid}`,
      },
    });
    setSelectedAttachment('');
  };

  return (
    <div className={`attach-section ${preview ? 'attachment-preview' : ''}`}>
      <div className="attach-header">
        <h3>{configText('attached files', true)}</h3>
        {!preview && (
          <Button className="add-files" text="Add files" onClick={() => showAttachmentModal(true)}>
            Attach Files
          </Button>
        )}
      </div>
      <Row gutter={[16, 16]}>
        {prop?.attachments.map((attachment, index) => (
          <Col span={8} key={index}>
            {ispdf ? (
              <a href={attachment.url} target="_blank">
                <div className="folder">
                  {getIcon(attachment)}
                  <Tooltip title={attachment.name}>
                    <p>{attachment.name}</p>
                  </Tooltip>
                </div>
              </a>
            ) : (
              <Row className="attachments-options">
                <div className="attachments-options-wrapper">
                  <Tooltip title="Download">
                    <DownloadIcon
                      className="delete"
                      onClick={() => downloadAttachment(attachment)}
                    />
                  </Tooltip>
                  {!preview && (
                    <Popover
                      content={
                        <Col className="delete-attachment">
                          <h3 className="ant-popover-title">
                            Do you want to delete the attachment?
                          </h3>
                          <Divider />
                          <Row>
                            <Button
                              size="middle"
                              type="button"
                              className="Prosprich-editor-components-popover-confirm"
                              text="Delete"
                              onClick={removeAttachment}>
                              Delete
                            </Button>
                            <Button
                              size="middle"
                              type="button"
                              className="Prosprich-editor-components-popover-cancel"
                              text="Cancel"
                              onClick={() => handleDeleteVisibleChange(false)}>
                              Cancel
                            </Button>
                          </Row>
                        </Col>
                      }
                      placement="bottomLeft"
                      overlayClassName="Prosprich-editor-components-popover"
                      trigger="click"
                      visible={selectedAttachment === index}
                      onVisibleChange={() => handleDeleteVisibleChange(index)}>
                      <span>
                        <Tooltip title="Delete">
                          <DeleteIcon className="delete-icon" />
                        </Tooltip>
                      </span>
                    </Popover>
                  )}
                </div>
                <div className="folder">
                  {getIcon(attachment)}
                  <Tooltip title={attachment.name}>
                    <p>{attachment.name}</p>
                  </Tooltip>
                </div>
              </Row>
            )}
          </Col>
        ))}

        {!prop?.attachments?.length && (
          <Col span={24}>
            <p className="attachment-empty">Empty</p>
          </Col>
        )}
      </Row>
    </div>
  );
};

AttachmentSection.defaultProps = {
  preview: false,
};

AttachmentSection.propTypes = {
  prop: PropTypes.instanceOf(Object).isRequired,
  showAttachmentModal: PropTypes.func,
  preview: PropTypes.bool,
  propRef: PropTypes.instanceOf(Object),
  setProp: PropTypes.func.isRequired,
  configText: PropTypes.func.isRequired,
};

export default AttachmentSection;
