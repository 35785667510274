import React, { useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';

import GeneralStats from '../GeneralStats/GeneralStats';
import FakeSubModal from '../../Modals/FakeSubModal';
import CancellationModal from '../../Modals/CancellationModal';
import RefundsModal from '../../Modals/RefundsModal';
import Path from 'routes/path';
import ResetTrialModal from '../../Modals/ResetTrialModal';
import { FETCH_ADMIN_USERS } from 'graphql/queries/adminQueries';
import { MANIPULATE_USER, USER_IMPERSONATE } from 'graphql/mutations/adminMutations';
import { UsersDetailsInfo, UsersDetailsHeader } from './components';
import { userProfileVar, userVar, adminProfileVar, adminVar } from 'graphql/cache';

const UserDetails = () => {
  const history = useHistory();
  const initialFilters = {
    // startDate: moment().subtract(1, 'month').toISOString(),
    endDate: moment().toISOString(),
    search: '',
    paid: false,
    wixUsers: false,
  };
  const [users, setUsers] = useState([]);
  const [showFakeSubModal, setShowFakeSubModal] = useState(false);
  const [showResetTrialModal, setShowResetTrialModal] = useState(false);
  const [cancellationModal, setCancellationModal] = useState(false);
  const [refundsModal, setRefundsModal] = useState(false);
  const [currentUser, setCurrentUser] = useState('');
  const [filters, setFilters] = useState(initialFilters);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(30);
  const [generalStats, setGeneralStats] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateAdminUsers = (adminUsers) => {
    setUsers(
      adminUsers.map((user) => ({
        createdAt: moment(user.createdAt).format('DD/MM/YYYY'),
        name: user?.profile?.name ?? '',
        email: user?.emails?.map((email) => email.address).join() ?? '',
        props: user?.props?.length ?? 0,
        where: user?.profile?.where ?? '',
        referrerUrl: user?.profile?.referrerUrl ?? '',
        landingUrl: user?.profile?.landingUrl ?? '',
        ip: user?.profile?.ip ?? '',
        payment: user.subscriptionId ? 'Stripe' : 'PayPal',
        pType: `${user.ptype} / ${user.otherptype}`,
        uid: user._id,
        actions: user,
        paymentEmail: user.paymentEmail || '',
        deleted: user.deleted,
      }))
    );
  };

  const {
    loading: isFetchingAdminUsers,
    refetch,
    fetchMore,
  } = useQuery(FETCH_ADMIN_USERS, {
    variables: {
      userCondition: {
        queryString: filters.search.trim(),
        queryDates: {
          startDate: filters.startDate,
          endDate: filters.endDate,
        },
        paid: filters.paid,
        generalStats: true,
        wixUsers: filters.wixUsers,
      },
      limit: limit,
      offset: offset,
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const {
        fetchAdminUsers: { adminUsers, generalStats },
      } = res;
      if (generalStats?.usersCount !== null) setGeneralStats([{ ...generalStats, key: 'stats' }]);
      updateAdminUsers(adminUsers);
    },
  });

  const [handleManipulateUser] = useMutation(MANIPULATE_USER, {
    onCompleted: ({ manipulateUser }) => {
      if (manipulateUser?.type === 'getUserPaymentStatus') {
        console.log(manipulateUser?.paymentStatus);
        setCurrentUser({ ...currentUser, paymentStatus: manipulateUser?.paymentStatus });
      } else if (
        manipulateUser?.type === 'getUserInvoices' ||
        manipulateUser?.type === 'refundCharges'
      ) {
        setCurrentUser({ ...currentUser, payments: manipulateUser?.payments });
      } else if (manipulateUser?.type === 'exportData') {
        const dlAnchorElem = document.createElement('a');
        dlAnchorElem.id = 'downloadAnchorElem';
        dlAnchorElem.style = 'display:none';
        document.body.append(dlAnchorElem);
        const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify({
            user: manipulateUser?.user,
            proposals: manipulateUser?.proposals,
            transactions: manipulateUser?.transactions,
          })
        )}`;
        dlAnchorElem.setAttribute('href', dataStr);
        dlAnchorElem.setAttribute('download', `${manipulateUser?.user._id}.json`);
        dlAnchorElem.click();
        dlAnchorElem.remove();
      } else if (manipulateUser?.type === 'resetUserPassword') {
        return message.success(
          `${manipulateUser?.type.replace(/([a-z])([A-Z])/g, '$1 $2')} completed. Set to ${
            manipulateUser?.defaultPassword
          }`
        );
      }
      setLoading(false);
      message.success(`${manipulateUser?.type.replace(/([a-z])([A-Z])/g, '$1 $2')} completed`);
    },
    onError: ({ graphQLErrors }) => {
      setLoading(false);
      if (graphQLErrors) {
        const type = graphQLErrors[0]?.extensions?.type;
        if (type) message.error(`Failed to ${type.replace(/([a-z])([A-Z])/g, '$1 $2')}`);
        else message.error(`Failed`);
      }
    },
  });

  const [impersonateUser] = useMutation(USER_IMPERSONATE, {
    onCompleted: (res) => {
      window.localStorage.setItem('proxy-token', res.generateImpersonateToken.token);
      window.localStorage.setItem('proxy-user-id', res.generateImpersonateToken.user._id);

      console.log('user', res);

      adminProfileVar(userProfileVar());
      adminVar(userVar());
      userProfileVar({
        ...res.generateImpersonateToken.user.profile,
        email: res.generateImpersonateToken.user.emails[0].address,
      });
      userVar({ ...res.generateImpersonateToken.user });
      history.push(Path.DASHBOARD);
      setLoading(false);
    },
    onError: ({ graphQLErrors }) => {
      setLoading(false);
      if (graphQLErrors) {
        const type = graphQLErrors[0]?.extensions?.type;
        if (type) message.error(`Failed to ${type.replace(/([a-z])([A-Z])/g, '$1 $2')}`);
        else message.error(`Failed`);
      }
    },
  });

  const handleFilters = useCallback(
    (type, value) => {
      if (type === 'date') {
        [filters.startDate, filters.endDate] = value;
      } else {
        filters[type] = value;
      }
      setFilters({ ...filters });
    },
    [setFilters, filters]
  );

  const debounceSearchHandler = _.debounce((value = () => {}) => {
    handleFilters('search', value);
  }, 3000);

  const handleReset = () => {
    setFilters({ ...initialFilters });
    refetch();
  };

  const handleUsersActionClick = async (type, user, adminManipulationInfo, secondType) => {
    setLoading(true);
    if (type === 'impersonateUser') {
      impersonateUser({
        variables: {
          _id: user._id,
        },
      });
    } else {
      await handleManipulateUser({
        variables: {
          type,
          uid: user._id,
          adminManipulationInfo,
        },
      });
      if (secondType === 'cancel') {
        setCancellationModal(true);
      }
      if (secondType === 'refund') {
        setRefundsModal(true);
      }
    }
  };

  return (
    <>
      {showFakeSubModal && (
        <FakeSubModal
          visible={showFakeSubModal}
          setVisible={setShowFakeSubModal}
          userInfo={currentUser}
          handleSubmit={handleUsersActionClick}
        />
      )}
      {showResetTrialModal && (
        <ResetTrialModal
          visible={showResetTrialModal}
          setVisible={setShowResetTrialModal}
          userInfo={currentUser}
          handleSubmit={handleUsersActionClick}
        />
      )}
      {cancellationModal && (
        <CancellationModal
          visible={cancellationModal}
          setVisible={setCancellationModal}
          userInfo={currentUser}
          handleSubmit={handleUsersActionClick}
        />
      )}
      {refundsModal && (
        <RefundsModal
          visible={refundsModal}
          setVisible={setRefundsModal}
          userInfo={currentUser}
          handleSubmit={handleUsersActionClick}
        />
      )}
      <GeneralStats generalStats={generalStats} />
      <div className="admin-table-wrapper">
        <UsersDetailsHeader
          refetch={refetch}
          filters={filters}
          debounceSearchHandler={debounceSearchHandler}
          handleFilters={handleFilters}
          handleReset={handleReset}
        />
        <UsersDetailsInfo
          isFetchingAdminUsers={isFetchingAdminUsers || loading}
          users={users}
          limit={limit}
          usersCount={generalStats[0]?.usersCount}
          fetchMore={fetchMore}
          filters={filters}
          updateAdminUsers={updateAdminUsers}
          setCurrentUser={setCurrentUser}
          setOffset={setOffset}
          setLimit={setLimit}
          setShowFakeSubModal={setShowFakeSubModal}
          setShowResetTrialModal={setShowResetTrialModal}
          setCancellationModal={setCancellationModal}
          handleUsersActionClick={handleUsersActionClick}
        />
      </div>
    </>
  );
};

export default UserDetails;
