import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.1868 0H4.67336C3.5162 0 2.57812 0.938073 2.57812 2.09524V32.127C2.57812 33.2842 3.5162 34.2222 4.67336 34.2222H28.7686C29.9258 34.2222 30.8638 33.2842 30.8638 32.127V9.31176C30.8638 8.73813 30.6287 8.18958 30.2132 7.7941L22.6314 0.577574C22.2418 0.206794 21.7246 0 21.1868 0Z"
      fill="#C7BCE4"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="0"
      width="29"
      height="36">
      <path
        d="M21.1898 0H4.67336C3.5162 0 2.57812 0.938071 2.57812 2.09524V33.9048C2.57812 35.0619 3.5162 36 4.67336 36H28.7686C29.9258 36 30.8638 35.0619 30.8638 33.9048V9.27347C30.8638 8.69805 30.6272 8.14795 30.2094 7.75225L22.6306 0.574025C22.2415 0.20543 21.7258 0 21.1898 0Z"
        fill="#7876C1"
      />
    </mask>
    <g mask="url(#mask0)"></g>
    <rect y="11.5723" width="33.4286" height="11.86" rx="1" fill="#7876C1" />
    <path
      d="M13.0827 16.24C13.0827 16.6467 12.9894 17.02 12.8027 17.36C12.616 17.6933 12.3294 17.9633 11.9427 18.17C11.556 18.3767 11.076 18.48 10.5027 18.48H9.4427V21H7.7327V13.98H10.5027C11.0627 13.98 11.536 14.0767 11.9227 14.27C12.3094 14.4633 12.5994 14.73 12.7927 15.07C12.986 15.41 13.0827 15.8 13.0827 16.24ZM10.3727 17.12C10.6994 17.12 10.9427 17.0433 11.1027 16.89C11.2627 16.7367 11.3427 16.52 11.3427 16.24C11.3427 15.96 11.2627 15.7433 11.1027 15.59C10.9427 15.4367 10.6994 15.36 10.3727 15.36H9.4427V17.12H10.3727ZM17.0029 13.98C17.7429 13.98 18.3896 14.1267 18.9429 14.42C19.4963 14.7133 19.9229 15.1267 20.2229 15.66C20.5296 16.1867 20.6829 16.7967 20.6829 17.49C20.6829 18.1767 20.5296 18.7867 20.2229 19.32C19.9229 19.8533 19.4929 20.2667 18.9329 20.56C18.3796 20.8533 17.7363 21 17.0029 21H14.3729V13.98H17.0029ZM16.8929 19.52C17.5396 19.52 18.0429 19.3433 18.4029 18.99C18.7629 18.6367 18.9429 18.1367 18.9429 17.49C18.9429 16.8433 18.7629 16.34 18.4029 15.98C18.0429 15.62 17.5396 15.44 16.8929 15.44H16.0829V19.52H16.8929ZM26.6086 13.98V15.35H23.7486V16.83H25.8886V18.16H23.7486V21H22.0386V13.98H26.6086Z"
      fill="white"
    />
  </svg>
);

const PdfIcon = (props) => <Icon component={svg} {...props} />;

export default PdfIcon;
