import React, { useEffect, useState, useRef } from 'react';
import { Layout } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import PropTypes from 'prop-types';

import helpers, { scrollToSection } from 'helpers/proposal';
import Loader from 'components/Loader';
import DropArrowIcon from 'components/Icons/DropArrowIcon';
import useWindowDimensions from 'hooks/useWindowDimensions';
import AttachmentSection from 'pages/Proposal/components/Draft/components/AttachmentSection/AttachmentSection';
import ProposalHeaderBar from 'pages/Proposal/components/ProposalHeaderBar';
import ProposalWixHeaderBar from 'pages/Proposal/components/ProposalWixHeaderBar';
import ProposalMetaInformation from '../ProposalMetaInformation';
import PublishFooter from '../PublishFooter';
import ContentTable from '../../../ContentTable/ContentTable';

import {
  PublishHeaderSection,
  PublishSection,
  PublishSignatureSection,
  PublishApprovedInfo,
} from './components';

import './PublishContent.scss';

const PublishContent = (props) => {
  const contentRef = useRef(null);
  const redirectTimerRef = useRef({});
  const [footerHeight, setFooterHeight] = useState(100);
  const {
    config,
    prop,
    user,
    setProp,
    updateProposalClient,
    previewTemplate,
    isPublishing,
    publishProposal,
    copyPropsalLink,
    publishedProposalLink,
    setMakePayment,
    downloadPdf,
    isTemplate,
    isSection,
    proposalSettings,
    isSavingForm,
    setIsSavingForm,
    wixPreview,
    addItem,
    setOpenWixPreview,
    clientWixPreview,
    templateWixPreview,
    userWixPreview,
    isEditingModal,
    templateUserWixPreview,
    isHeader,
    spamUrls,
  } = props;
  const { cid } = useParams();
  const { trackEvent } = useIntercom();

  const [showHeader, showOrHideHeader] = useState(true);
  const [showValidationError, setShowValidationError] = useState(false);
  const [sectionHeight, setSectionHeight] = useState(0);
  const [screen, setScreen] = useState('');
  const [collapseContentTable, setCollapseContentTable] = useState(true);
  const [sectionOrderArrangement, setSectionOrderArrangement] = useState(
    prop?.draft?.sectionorder || []
  );
  const [downloadingFile, setDownloadingFile] = useState(false);

  const { pathname } = useLocation();

  const { width } = useWindowDimensions();

  const ispdf = pathname.indexOf('/PDF/') !== -1 || previewTemplate;
  const isWixClient = clientWixPreview;
  const allowPreviewDownload = user?.profile.allowPreviewDownload;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const itemsRef = Array.from({ length: prop?.draft?.sectionorder?.length || 0 }, () => useRef());

  useEffect(() => {
    if (pathname.indexOf('s=true') === -1) {
      const eventtype = cid ? 'new-client-prop-view' : 'new-user-prop-preview';
      if (pathname.indexOf('/cd/') === -1 || clientWixPreview)
        window.ga('send', 'event', eventtype, eventtype, 'init', pathname);

      if (!prop?.wixProposalId) {
        trackEvent(eventtype, {
          pid: prop?.pid,
          id: prop?._id,
        });
      }
    }

    if (width > 580) {
      setScreen('desktop');
    } else {
      setScreen('mobile');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const showValidationErrorFunction = (ref) => {
    setShowValidationError(true);
    const refIndex = prop.draft.sectionorder.indexOf(ref);
    if (itemsRef[refIndex]) {
      itemsRef[refIndex].current.getElementsByClassName('form-error-text')[0]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  const onRedirect = (redirectUrl, redirectAfter) => {
    const redirectFn = () => {
      window.location = redirectUrl;
      redirectTimerRef.current = {};
    };
    redirectTimerRef.current = {
      timer: setTimeout(redirectFn, redirectAfter ? redirectAfter * 1000 : 1000),
      redirectFn,
    };
  };

  const onDownloadPDF = () => {
    const { timer, redirectFn } = redirectTimerRef.current;
    clearTimeout(timer);
    downloadPdf({
      onDownloadCompleted: () => setTimeout(() => redirectFn?.(), 4000),
      onErrorPopupClose: redirectFn,
    });
  };

  const configText = (x, disableTrim, vars = {}) => {
    return helpers.configText({
      x,
      notmarked: true,
      config,
      prop,
      user: user,
      vars,
      disableTrim,
    });
  };

  useEffect(() => {
    if (contentRef?.current) {
      const { clientHeight } = contentRef?.current;
      if (clientHeight) {
        handleHeightChange({ height: clientHeight });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef?.current]);

  if (!prop) {
    return <Loader />;
  }

  const handleHeightChange = ({ height }) => {
    if (height !== sectionHeight) setSectionHeight(height);
  };

  const updateScreen = (screenCalib) => {
    setScreen(screenCalib);
  };

  const openPublishView = (openPreviewValue) => {
    setOpenWixPreview(openPreviewValue);
  };

  const renderPageBreakers = () => {
    const numberOfBreaks = Math.floor(sectionHeight / 1250);
    if (numberOfBreaks) {
      let tempHeight = 26;
      const breaksPage = [];

      for (let i = 0; i < numberOfBreaks; i++) {
        tempHeight += 1250;
        breaksPage.push(
          <div key={tempHeight} className="section-page-breaker" style={{ top: `${tempHeight}px` }}>
            <div className="section-page-breaker-sides" />
            <div
              className="section-page-breaker-center"
              style={{ width: `${screen === 'mobile' ? '500px' : '900px'}` }}
            />
            <div className="section-page-breaker-sides">
              <p>End of {helpers.ordinalSuffixOf(i + 1)} PDF page</p>
            </div>
          </div>
        );
      }

      return breaksPage.map((breakPage) => breakPage);
    }
  };

  let publishSections = [];
  if (prop.draft) {
    const sections = [];
    const order =
      templateWixPreview || templateUserWixPreview
        ? prop.draft.sectionorder.filter((section) => section !== 'whyme2')
        : prop.draft.sectionorder || helpers.defaultSectionOrder;
    order.forEach((sname, snameIndex) => {
      const s =
        prop.draft[sname] && (prop.draft[sname].text || prop.draft[sname].raw) ? (
          <PublishSection
            key={sname}
            ref={itemsRef[snameIndex]}
            prop={prop}
            name={sname}
            configText={configText}
            ispdf={ispdf}
            updateProposalClient={updateProposalClient}
            showValidationError={showValidationError}
            location={props.location}
            setIsSavingForm={setIsSavingForm}
            proposalSettings={proposalSettings}
            screen={screen}
            wixPreview={wixPreview}
            clientWixPreview={clientWixPreview}
            previewTemplate={previewTemplate}
            templateWixPreview={templateWixPreview || templateUserWixPreview}
            userWixPreview={userWixPreview}
          />
        ) : null;
      if (s) {
        sections.push(s);
      }
    });
    if (order.length !== sectionOrderArrangement.length) {
      setSectionOrderArrangement(order);
    }

    publishSections = (
      <div
        id="section-content-container"
        className={`${screen === 'mobile' ? 'mobile-screen' : 'desktop-screen'} ${
          prop?.importedProposal ? 'imported-section-content-container' : ''
        }`}
        ref={contentRef}>
        {isSection && (isTemplate || isHeader || prop.project) && (
          <PublishHeaderSection
            name={isSection ? order : 'header'}
            proposal={prop}
            isPreview={false}
          />
        )}
        {!isSection && (
          <PublishHeaderSection
            name={'header'}
            proposal={prop}
            templateWixPreview={templateWixPreview || templateUserWixPreview}
          />
        )}
        {sections}
        {!isSection && (
          <PublishSignatureSection
            name="signature"
            proposal={prop}
            user={user}
            setProp={setProp}
            updateProposalClient={updateProposalClient}
            configText={configText}
            isClient={!!((cid || ispdf || isWixClient) && !user)}
            isSavingForm={isSavingForm}
            showValidationErrorFunction={showValidationErrorFunction}
            downloadPdf={onDownloadPDF}
            userWixPreview={userWixPreview}
            templateWixPreview={templateWixPreview || templateUserWixPreview}
            wixPreview={wixPreview}
            isEditingModal={isEditingModal}
            onRedirect={onRedirect}
            clientWixPreview={clientWixPreview}
          />
        )}
      </div>
    );
  }

  const fontSizeRange = getSmallLargeFont(prop?.draft?.bodyFont?.fontSize);
  const showTocMenu =
    !isSection && !ispdf && !prop?.importedProposal && !wixPreview && !userWixPreview;
  const languageRTL =
    prop.language?.toLowerCase() === 'hebrew' || prop?.language?.toLowerCase() === 'arabic';

  return (
    <Layout
      className={`publish ${showHeader ? '' : 'minimize'} ${fontSizeRange} ${
        pathname.includes('/cd/') || clientWixPreview ? 'notranslate' : ''
      }`}>
      {downloadingFile && <Loader overlay={true} />}
      {!previewTemplate && <ProposalMetaInformation proposal={prop} user={user} />}
      {!ispdf && !pathname.includes('/cd/') && !clientWixPreview && !userWixPreview && (
        <Layout.Header className="publish-header">
          {wixPreview || templateUserWixPreview ? (
            <ProposalWixHeaderBar
              mode="publish"
              user={user}
              proposal={prop}
              published={!!prop.published}
              copyPropsalLink={copyPropsalLink}
              publishedProposalLink={publishedProposalLink}
              openPublishView={openPublishView}
              isPublishing={isPublishing}
              publishProposal={publishProposal}
              downloadPdf={onDownloadPDF}
              isTemplate={isTemplate}
              isSection={isSection}
              updateScreen={updateScreen}
              screen={screen}
              collapseContentTable={collapseContentTable}
              setCollapseContentTable={setCollapseContentTable}
              addItem={addItem}
              templateUserWixPreview={templateUserWixPreview}
            />
          ) : (
            <>
              <ProposalHeaderBar
                mode="publish"
                user={user}
                proposal={prop}
                published={!!prop.published}
                copyPropsalLink={copyPropsalLink}
                publishedProposalLink={publishedProposalLink}
                isPublishing={isPublishing}
                publishProposal={publishProposal}
                downloadPdf={onDownloadPDF}
                isTemplate={isTemplate}
                isSection={isSection}
                updateScreen={updateScreen}
                screen={screen}
                collapseContentTable={collapseContentTable}
                setCollapseContentTable={setCollapseContentTable}
              />
              <DropArrowIcon
                onClick={() => showOrHideHeader(!showHeader)}
                className="publish-header-toggle-icon"
              />
            </>
          )}
        </Layout.Header>
      )}

      {showTocMenu && (
        <ContentTable
          mode="preview"
          configText={configText}
          collapse={collapseContentTable}
          setCollapse={setCollapseContentTable}
          proposal={prop}
          user={user}
          downloadPdf={onDownloadPDF}
          scrollToSection={scrollToSection}
          clientWixPreview={clientWixPreview}
          contentTableClassName={`publish-content-table${
            pathname.includes('/cd/') || clientWixPreview ? ' publish-content-table-preview' : ''
          } language-${prop?.language?.toLowerCase() || 'english'}`}
          isHebrew={languageRTL}
          config={config}
          templateUserWixPreview={templateUserWixPreview}
        />
      )}
      <Layout.Content
        style={{ paddingBottom: footerHeight }}
        className={`publish-content ${previewTemplate ? 'is-template-preview' : ''} ${
          languageRTL ? 'language-rtl' : 'language-english'
        } ${!collapseContentTable && `content-wrapper-preview${!languageRTL && '-left'}`}`}>
        <>
          <div
            className={`gap 
          ${showTocMenu || allowPreviewDownload !== false ? 'one' : ''}
          ${showTocMenu && allowPreviewDownload !== false ? 'two' : ''}
          `}
          />

          {(prop?.approvedmanually || prop?.signature?.date) && !pathname.includes('/PDF/') && (
            <PublishApprovedInfo
              configText={configText}
              signature={prop?.signature}
              approvedManually={prop?.approvedmanually}
              language={prop?.language}
            />
          )}

          {publishSections}

          {sectionHeight &&
          !pathname.includes('/cd/') &&
          !clientWixPreview &&
          !pathname.includes('/PDF/') &&
          !previewTemplate &&
          !userWixPreview &&
          !/Mobi|Android/i.test(navigator.userAgent) ? (
            renderPageBreakers()
          ) : (
            <div />
          )}

          {!!prop?.attachments?.length && (
            <AttachmentSection
              prop={prop}
              configText={configText}
              preview={true}
              downloadingFile={downloadingFile}
              setDownloadingFile={setDownloadingFile}
              {...props}
            />
          )}
        </>
      </Layout.Content>

      {!previewTemplate &&
        !isSection &&
        !pathname.includes('/PDF/') &&
        !wixPreview &&
        !userWixPreview && (
          <PublishFooter
            setMakePayment={setMakePayment}
            configText={configText}
            name="signature"
            proposal={prop}
            publishedProposalLink={publishedProposalLink}
            isClient={!!((cid || ispdf || isWixClient) && !user)}
            setFooterHeight={setFooterHeight}
            templateUserWixPreview={templateUserWixPreview}
            spamUrls={spamUrls}
          />
        )}
    </Layout>
  );
};

PublishContent.defaultProps = {
  prop: '',
  config: null,
  previewTemplate: false,
  isPublishing: false,
  copyPropsalLink: () => {},
  publishedProposalLink: () => {},
  publishProposal: () => {},
  setMakePayment: () => {},
  downloadPdf: () => {},
  setProp: () => {},
  updateProposalClient: () => {},
  isTemplate: false,
  isSection: false,
  wixPreview: false,
  clientWixPreview: false,
  templateWixPreview: false,
  isEditingModal: false,
  templateUserWixPreview: false,
};

PublishContent.propTypes = {
  prop: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  config: PropTypes.instanceOf(Object),
  previewTemplate: PropTypes.bool,
  publishProposal: PropTypes.func,
  isPublishing: PropTypes.bool,
  copyPropsalLink: PropTypes.func,
  publishedProposalLink: PropTypes.func,
  setMakePayment: PropTypes.func,
  downloadPdf: PropTypes.func,
  setProp: PropTypes.func,
  updateProposalClient: PropTypes.func,
  isTemplate: PropTypes.bool,
  isSection: PropTypes.bool,
  wixPreview: PropTypes.bool,
  clientWixPreview: PropTypes.bool,
  templateWixPreview: PropTypes.bool,
  userWixPreview: PropTypes.bool,
  isEditingModal: PropTypes.bool,
  templateUserWixPreview: PropTypes.bool,
};

export default PublishContent;

const getSmallLargeFont = (fontSize) => {
  fontSize = parseInt(fontSize);

  if (!fontSize || isNaN(fontSize)) {
    return '';
  }

  if (fontSize <= 14) {
    return 'small-font';
  }
  if (fontSize >= 36) {
    return 'max-font';
  }
  if (fontSize >= 24) {
    return 'large-font';
  }

  return '';
};
