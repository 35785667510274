import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Input, Checkbox, Divider } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import Languages from 'constants/languages';

const UserInfo = ({ fullname, setFullname, termsValue, setTermsValue }) => {
  const validatePassword = (rule, value, callback) => {
    const reDigit = /(?=.*[\d\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-])/; //eslint-disable-line
    const reCharacter = /(?=.*[a-zA-Z])/;

    if (value.length > 0 && value.trim().length === 0) {
      callback('Your password cannot just have spaces!');
    } else if (value.length > 0 && value.length < 8) {
      callback('Your password must be at least 8 characters long, please try again.');
    } else if (value.length > 0 && !reCharacter.test(value) && !reDigit.test(value)) {
      callback('At least 1 letter and 1 number/special character is required');
    } else if (value.length > 0 && !reCharacter.test(value)) {
      callback('At least 1 letter is required');
    } else if (value.length > 0 && !reDigit.test(value)) {
      callback('At least 1 number or special character is required');
    } else {
      callback();
    }
  };

  const validateFullname = (rule, value, callback) => {
    if (value.length > 0 && value.trim().length === 0) {
      callback('Your name cannot just have spaces!');
    } else {
      callback();
    }
  };

  return (
    <>
      <Form.Item
        label="Full Name"
        name="fullname"
        labelCol={false}
        rules={[
          { required: true, message: 'Your name cannot be empty!' },
          { validator: validateFullname },
        ]}
        shouldUpdate>
        <Input value={fullname} onChange={(e) => setFullname(fullname)} />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        labelCol={false}
        rules={[
          { required: true, message: 'Your password cannot be empty!' },
          { validator: validatePassword },
        ]}
        shouldUpdate>
        <Input.Password />
      </Form.Item>

      <Form.Item label="Proposal Language" name="proposalLanguage" labelCol={false}>
        <Select suffixIcon={<CaretDownOutlined color="red" />}>
          {Languages.map((language) => (
            <Select.Option key={language.value} value={language.value}>
              {language.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Divider type="horizontal" />
      <Form.Item name="terms" rules={[{ required: !termsValue, message: 'Required!' }]}>
        <Checkbox checked={termsValue} onChange={(e) => setTermsValue(!!e.target.checked)}>
          <span className="asterisk-mark">*</span> By creating an account you agree to our{' '}
          <a href="https://www.goprospero.com/terms" rel="noreferrer" target="_blank">
            Terms &amp; Conditions
          </a>{' '}
          and our
          <a
            rel="noreferrer"
            href="https://www.iubenda.com/privacy-policy/50076459"
            target="_blank">
            {' '}
            Privacy Policy
          </a>
          .
        </Checkbox>
      </Form.Item>
    </>
  );
};

UserInfo.propTypes = {
  setFullname: PropTypes.func.isRequired,
  termsValue: PropTypes.bool,
  setLangauge: PropTypes.func.isRequired,
  setTermsValue: PropTypes.func.isRequired,
};

export default UserInfo;
