import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { PageHeader, Button } from 'antd';

import './TrailEndHeader.scss';

const TrailEndHeader = ({ everPayed, isTeamMember }) => {
  const history = useHistory();

  return (
    <PageHeader className="trial-end-header">
     <p>
        {everPayed ? 'Your subscription has ended 😔 Renew now to start using Prospero again!' : 
        'Your trial has ended 😔 No worries! You can still use PROSPERO with our paid plans.'}
        {isTeamMember && ' Let your account owner know.'} 😄
      </p>
      {!isTeamMember && (
        <Button className="upgrade-button" onClick={() => history.push('/plans')}>
          {'Upgrade Now!'}
        </Button>
      )}
    </PageHeader>
  );
};

TrailEndHeader.defaultProps = {
  everPayed: false,
  isTeamMember: false,
};

TrailEndHeader.propTypes = {
  everPayed: PropTypes.bool,
  isTeamMember: PropTypes.bool,
};

export default TrailEndHeader;
