import React, { useState } from 'react';
import PropTypes from 'prop-types';

import RemoveRow from '../RemoveRow/RemoveRow';
import RemoveColumn from '../RemoveColumn/RemoveColumn';
import TableDragIcon from 'components/Icons/TableDragIcon';

const TableList = ({ table, config, editColumn, deleteColumn, deleteRow, saveTable }) => {
  const [hoveredRow, setHoveredRow] = useState('');
  const [hoveredColumn, setHoveredColumn] = useState('');
  const [hoveredDeleteColumn, setHoveredDeleteColumn] = useState('');
  const [hoveredRowDelete, setHoveredDeleteRow] = useState('');
  const [columnDeleteVisibleChange, setColumnDeleteVisibleChange] = useState('');
  const [rowDeleteVisibleChange, setRowDeleteVisibleChange] = useState('');
  const [colDragOver, setColDragOver] = useState('');
  const [rowDragOver, setRowDragOver] = useState('');

  const handleColDragStart = (e) => {
    const id = Number(e?.target?.id.replace('drag-', ''));
    if (id) {
      e.dataTransfer.setData('colIdx', Number(id));
    }
  };

  const handleRowDragStart = (e) => {
    const id = Number(e?.target?.childNodes[0]?.id.replace('drag-', ''));
    if (id) {
      e.dataTransfer.setData('rowIdx', Number(id));
    }
  };

  const handleDragOver = (e) => e.preventDefault();
  const handleColDragEnter = (e) => {
    let id = Number(e?.target?.id.replace('drag-', ''));
    if (!id) {
      id = Number(e.target.parentNode.id);
    }
    if (typeof id === 'number') {
      setColDragOver(id);
    }
  };

  const handleRowDragEnter = (e) => {
    let id = Number(e?.target?.childNodes[0]?.id.replace('drag-', ''));
    if (!id) {
      id = Number(e?.target?.id);
    }
    if (typeof id === 'number') {
      setRowDragOver(id);
    }
  };

  const handleColOnDrop = (e) => {
    let id = Number(e?.target?.id.replace('drag-', ''));
    if (!id) {
      id = Number(e?.target?.parentNode?.id.replace('drag-', ''));
    }
    if (typeof id === 'number') {
      const droppedColIdx = Number(id) + 1;
      const draggedColIdx = Number(e.dataTransfer.getData('colIdx')) + 1;
      const tempTable = [...table];

      table.forEach((t, index) => {
        if (droppedColIdx && draggedColIdx) {
          const tempT = JSON.parse(JSON.stringify(t));
          tempTable[index][droppedColIdx] = tempT[draggedColIdx];
          tempTable[index][draggedColIdx] = tempT[droppedColIdx];
        }
      });
      saveTable({ tableCopy: tempTable });
      e.preventDefault();
      e.stopPropagation();
      setColDragOver('');
    }
  };

  const handleRowOnDrop = (e) => {
    let id = Number(e?.target?.childNodes[0]?.id.replace('drag-', ''));
    if (!id) {
      id = Number(e?.target?.id.replace('drag-', ''));
    }
    if (typeof id === 'number') {
      const droppedRowIdx = Number(id);
      const draggedRowIdx = Number(e.dataTransfer.getData('rowIdx'));
      if (droppedRowIdx && draggedRowIdx) {
        const tempTable = [...table];
        [tempTable[droppedRowIdx - 1], tempTable[draggedRowIdx - 1]] = [
          tempTable[draggedRowIdx - 1],
          tempTable[droppedRowIdx - 1],
        ];
        saveTable({ tableCopy: tempTable });
      }
      setRowDragOver('');
    }
  };

  /* eslint-disable */
  return table?.map((p, rowIndex) => {
    const draggableRowData = {
      onDragStart: handleRowDragStart,
      onDragOver: handleDragOver,
      onDrop: handleRowOnDrop,
      onDragEnter: handleRowDragEnter,
      dragover: rowIndex === rowDragOver ? 'true' : '',
      draggable: true,
    };

    return (
      <tr
        className="grey2"
        key={rowIndex}
        style={
          rowIndex === 0 && hoveredRowDelete !== rowIndex
            ? { backgroundColor: config.topRowColor }
            : hoveredRowDelete === rowIndex || rowDeleteVisibleChange === rowIndex
            ? { backgroundColor: '#FFDBDB' }
            : { backgroundColor: config.rowColor }
        }
        onMouseEnter={() => setHoveredRow(rowIndex)}
        onMouseLeave={() => {
          setHoveredRow('');
          setHoveredColumn('');
        }}
        id={rowIndex}>
        <td className="grey1 table-row-drag-container" {...draggableRowData}>
          <TableDragIcon className="table-row-drag" id={`drag-${rowIndex + 1}`} />
          <RemoveRow
            hoveredRow={hoveredRow}
            rowIndex={rowIndex}
            deleteRow={() => deleteRow(p.id)}
            rowDeleteVisibleChange={rowDeleteVisibleChange}
            setRowDeleteVisibleChange={setRowDeleteVisibleChange}
            setHoveredDeleteRow={setHoveredDeleteRow}
            setColumnDeleteVisibleChange={setColumnDeleteVisibleChange}
          />
        </td>
        {Object.keys(p)
          .filter((k) => k !== 'id')
          .map((k, columnindex) => {
            const draggableColumnData =
              rowIndex === 0
                ? {
                    onDragStart: handleColDragStart,
                    onDragOver: handleDragOver,
                    onDrop: handleColOnDrop,
                    onDragEnter: handleColDragEnter,
                    dragover: columnindex === colDragOver ? 'true' : '',
                    draggable: true,
                  }
                : null;
            return (
              <td
                className="grey1"
                key={`${rowIndex}${k}`}
                onMouseEnter={() => setHoveredColumn(`td-${columnindex}`)}
                style={
                  hoveredDeleteColumn === columnindex || columnDeleteVisibleChange === columnindex
                    ? { backgroundColor: '#FFDBDB' }
                    : {}
                }
                id={columnindex}
                {...draggableColumnData}>
                {rowIndex === 0 && (
                  <div className="column-action-container">
                    <RemoveColumn
                      hoveredColumn={hoveredColumn}
                      columnindex={columnindex}
                      deleteColumn={deleteColumn}
                      columnDeleteVisibleChange={columnDeleteVisibleChange}
                      setColumnDeleteVisibleChange={setColumnDeleteVisibleChange}
                      setHoveredDeleteColumn={setHoveredDeleteColumn}
                      setRowDeleteVisibleChange={setRowDeleteVisibleChange}
                    />
                    <TableDragIcon
                      className="table-column-drag"
                      id={`drag-${columnindex + 1}`}
                      style={hoveredColumn === `td-${columnindex}` ? {} : { opacity: 1 }}
                    />
                    {/* <Draggable
                      setTable={setTable}
                      hoveredColumn={hoveredColumn}
                      columnindex={columnindex}
                    /> */}
                  </div>
                )}
                <div
                  id={`contenteditable ${rowIndex + 1}${k}`}
                  className="content-editable-item"
                  suppressContentEditableWarning
                  contentEditable={true}
                  onInput={(e) => editColumn({ p }, { k }, e, columnindex)}
                  onKeyDown={(e) => (e?.keyCode === 9 ? e.preventDefault() : '')}
                  dangerouslySetInnerHTML={{
                    __html: p[k],
                  }}
                />
              </td>
            );
          })}
      </tr>
    );
  });
  /* eslint-enable */
};

TableList.propTypes = {
  table: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  setTable: PropTypes.func.isRequired,
  editColumn: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  deleteColumn: PropTypes.func.isRequired,
};

export default TableList;
