import helpers from 'helpers';
import './WixPricing.scss';

export default function Pricing({
  lineItems,
  summaryItems,
  total,
  userWixPreview,
  configText,
  currency,
  shorten,
  isLargeFont,
  currencyCode,
  locale,
}) {
  if (!lineItems?.length) {
    return null;
  }

  return (
    <div className="pricing-wrapper wix-pricing">
      <div className="border">
        <table style={{ marginTop: '-8px' }}>
          <thead className="pricing-header item-row">
            <tr>
              <td className="item-col name max">
                <span className="no-break">{configText('Deliverable')}</span>
              </td>
              <td className={`item-col qty ${userWixPreview ? 'break-word' : ''}`}>
                <span className={`hide-mobile`}>{configText('quantity and price')}</span>
                <span className={`hide-desktop`}>{configText('quantity')}</span>
              </td>
              <td className={`item-col price ${userWixPreview ? 'break-word' : ''}`}>
                <span className={userWixPreview ? '' : 'break'}>
                  {configText('line total text')}
                </span>
              </td>
            </tr>
          </thead>

          <tbody className="pricing-deliverables">
            {lineItems.map((item, i) => (
              <tr key={i} className="deliverable item-row">
                <td className="item-col name max">
                  {item.name}
                  <span className="hide-desktop mobile-qty">
                    {item.quantity} x{' '}
                    <NoBreak
                      locale={locale}
                      currency={currencyCode}
                      shorten={shorten}
                      value={item.price}
                    />
                  </span>
                  {item?.description && <div className="subtitle big">{item.description}</div>}
                </td>
                <td
                  className={`item-col qty ${
                    userWixPreview && item.price.length > 5 ? 'break-word gap' : ''
                  }`}>
                  <span>
                    {item.quantity} x{' '}
                    <NoBreak
                      locale={locale}
                      currency={currencyCode}
                      shorten={shorten}
                      value={item.price}
                    />
                  </span>
                </td>
                <td
                  className={`item-col price ${
                    userWixPreview && item.lineItemPrice.length > 5 ? 'break-word gap' : ''
                  }`}>
                  <div>
                    <NoBreak
                      locale={locale}
                      currency={currencyCode}
                      shorten={shorten}
                      value={item.lineItemPrice}
                    />
                  </div>
                  {item?.tax?.length > 0 ? (
                    <>
                      {item?.tax.map((tax) => (
                        <div className="subtitle" key={tax.name}>
                          {tax.name}:{' '}
                          <NoBreak
                            locale={locale}
                            currency={currencyCode}
                            shorten={shorten}
                            value={tax.amount}
                          />
                        </div>
                      ))}
                    </>
                  ) : null}
                  {/* {item.discount ? <div className="subtitle">Discount: {item.discount}</div> : null} */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <table className="pricing-footer">
        {summaryItems.length > 0 && (
          <tbody className="pricing-summary">
            {summaryItems.map((item, i) => (
              <tr key={i} className="item-row">
                <td className="item-col max">{item.d}</td>
                <td className="item-col price">{largeNumber(item.price, isLargeFont)}</td>
              </tr>
            ))}
          </tbody>
        )}

        <tr className="pricing-total item-row">
          <td className="item-col max">{configText('total text')}</td>
          <td className="item-col price">{largeNumber(total, isLargeFont)}</td>
        </tr>
      </table>
    </div>
  );
}

const largeNumber = (value, isLargeFont) => {
  if (isLargeFont && value.length > 8 && value.endsWith('.00')) {
    return value.substr(0, value.length - 3);
  }

  return value;
};

const NoBreak = ({ locale, currency, shorten, value }) => {
  // if mobile, or too big number remove currency, remove .00
  if (shorten && value.endsWith('.00')) {
    value = value.substr(0, value.length - 3);
  }

  if (!shorten) {
    value = helpers.prefixCurrencySymbol(value, currency, locale);
  }

  return <span className="no-break">{value}</span>;
};
