import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.1868 0H4.67336C3.5162 0 2.57812 0.938073 2.57812 2.09524V32.127C2.57812 33.2842 3.5162 34.2222 4.67336 34.2222H28.7686C29.9258 34.2222 30.8638 33.2842 30.8638 32.127V9.31176C30.8638 8.73813 30.6287 8.18958 30.2132 7.7941L22.6314 0.577574C22.2418 0.206794 21.7246 0 21.1868 0Z"
      fill="#C7BCE4"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="0"
      width="29"
      height="36">
      <path
        d="M21.1898 0H4.67336C3.5162 0 2.57812 0.938071 2.57812 2.09524V33.9048C2.57812 35.0619 3.5162 36 4.67336 36H28.7686C29.9258 36 30.8638 35.0619 30.8638 33.9048V9.27347C30.8638 8.69805 30.6272 8.14795 30.2094 7.75225L22.6306 0.574025C22.2415 0.20543 21.7258 0 21.1898 0Z"
        fill="#7876C1"
      />
    </mask>
    <g mask="url(#mask0)"></g>
    <rect y="11.5723" width="33.4286" height="11.86" rx="1" fill="#7876C1" />
    <path
      d="M7.92 21L6.49 18.85L5.23 21H3.29L5.54 17.43L3.24 13.98H5.23L6.64 16.1L7.88 13.98H9.82L7.59 17.52L9.91 21H7.92ZM12.8784 19.68H15.1184V21H11.1684V13.98H12.8784V19.68ZM18.8741 21.07C18.3607 21.07 17.9007 20.9867 17.4941 20.82C17.0874 20.6533 16.7607 20.4067 16.5141 20.08C16.2741 19.7533 16.1474 19.36 16.1341 18.9H17.9541C17.9807 19.16 18.0707 19.36 18.2241 19.5C18.3774 19.6333 18.5774 19.7 18.8241 19.7C19.0774 19.7 19.2774 19.6433 19.4241 19.53C19.5707 19.41 19.6441 19.2467 19.6441 19.04C19.6441 18.8667 19.5841 18.7233 19.4641 18.61C19.3507 18.4967 19.2074 18.4033 19.0341 18.33C18.8674 18.2567 18.6274 18.1733 18.3141 18.08C17.8607 17.94 17.4907 17.8 17.2041 17.66C16.9174 17.52 16.6707 17.3133 16.4641 17.04C16.2574 16.7667 16.1541 16.41 16.1541 15.97C16.1541 15.3167 16.3907 14.8067 16.8641 14.44C17.3374 14.0667 17.9541 13.88 18.7141 13.88C19.4874 13.88 20.1107 14.0667 20.5841 14.44C21.0574 14.8067 21.3107 15.32 21.3441 15.98H19.4941C19.4807 15.7533 19.3974 15.5767 19.2441 15.45C19.0907 15.3167 18.8941 15.25 18.6541 15.25C18.4474 15.25 18.2807 15.3067 18.1541 15.42C18.0274 15.5267 17.9641 15.6833 17.9641 15.89C17.9641 16.1167 18.0707 16.2933 18.2841 16.42C18.4974 16.5467 18.8307 16.6833 19.2841 16.83C19.7374 16.9833 20.1041 17.13 20.3841 17.27C20.6707 17.41 20.9174 17.6133 21.1241 17.88C21.3307 18.1467 21.4341 18.49 21.4341 18.91C21.4341 19.31 21.3307 19.6733 21.1241 20C20.9241 20.3267 20.6307 20.5867 20.2441 20.78C19.8574 20.9733 19.4007 21.07 18.8741 21.07ZM27.1864 21L25.7564 18.85L24.4964 21H22.5564L24.8064 17.43L22.5064 13.98H24.4964L25.9064 16.1L27.1464 13.98H29.0864L26.8564 17.52L29.1764 21H27.1864Z"
      fill="white"
    />
  </svg>
);

const XlsxIcon = (props) => <Icon component={svg} {...props} />;

export default XlsxIcon;
