import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Checkbox, Row, Col } from 'antd';

import PointRight from 'components/Icons/PointRight';

const ProjectInfo = ({ user, projectsType, clickedCheckbox, setClickedCheckbox }) => {
  const handleCheckboxClick = (index) => {
    clickedCheckbox[index] = !clickedCheckbox[index];
    setClickedCheckbox([...clickedCheckbox]);
  };

  return (
    <>
      <Form.Item name="ptype" labelCol={false}>
        <Row className="helper-text-wrapper-step-3">
          <Col span={1} className="helper-text-step-3 hand-icon">
            <PointRight className="" />
          </Col>
          <Col span={22} className="helper-text-step-3 text">
            This will help us to match you the most relevant content for your proposals
          </Col>
        </Row>
        <Checkbox.Group>
          <Row className="checkbox-grp">
            {projectsType.map((el, index) => (
              <Col
                className={`project-checkbox ${clickedCheckbox[index] ? 'active' : ''}`}
                span={6}
                key={el.key}
                onClick={() => handleCheckboxClick(index)}>
                <Checkbox
                  checked={clickedCheckbox[index]}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCheckboxClick(index);
                  }}
                />
                {el.icon}
                <p>{el.label}</p>
              </Col>
            ))}
            {clickedCheckbox[clickedCheckbox.length - 1] ? (
              <Col span={24} className="other-project">
                <Form.Item label="Other project type?" name="otherptype" labelCol={false}>
                  <Input
                    value={user ? user.otherptype : ''}
                    placeholder="Write small description"
                  />
                </Form.Item>
              </Col>
            ) : null}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item label="Where did you hear about Prospero?" name="where" labelCol={false}>
        <Input />
      </Form.Item>
    </>
  );
};

ProjectInfo.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  projectsType: PropTypes.instanceOf(Array).isRequired,
  clickedCheckbox: PropTypes.instanceOf(Array).isRequired,
  setClickedCheckbox: PropTypes.func.isRequired,
};

export default ProjectInfo;
