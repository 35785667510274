/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="21" height="21" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="4" fill="#F5F6FA" />
    <path
      d="M22.4457 13.9623L22.3647 13.9334L22.0482 13.178L22.0825 13.1004C23.0412 10.9261 22.9751 10.8613 22.8161 10.7036L21.5373 9.45612C21.485 9.40425 21.3932 9.36777 21.3141 9.36777C21.2421 9.36777 21.0242 9.36777 19.1355 10.2232L19.0598 10.2572L18.2806 9.94338L18.2493 9.86581C17.3612 7.66663 17.2702 7.66663 17.0492 7.66663H15.2432C15.023 7.66663 14.9228 7.66663 14.0992 9.86964L14.0682 9.94983L13.293 10.2658L13.2189 10.235C11.9405 9.69607 11.1941 9.42277 10.9995 9.42277C10.9206 9.42277 10.8285 9.45819 10.7759 9.50992L9.49596 10.761C9.33359 10.9212 9.26624 10.9883 10.2751 13.1171L10.3122 13.1961L9.99525 13.9507L9.91768 13.9807C7.66699 14.8504 7.66699 14.9352 7.66699 15.1618V16.9315C7.66699 17.1586 7.66699 17.253 9.92215 18.0611L10.0028 18.0896L10.3198 18.842L10.2857 18.9189C9.32705 21.0941 9.38785 21.153 9.55105 21.3153L10.8279 22.5645C10.8814 22.616 10.9741 22.6528 11.0531 22.6528C11.1246 22.6528 11.342 22.6528 13.2319 21.7978L13.3076 21.7623L14.0873 22.0777L14.1176 22.1557C15.0063 24.3541 15.0976 24.3541 15.3188 24.3541H17.1253C17.3521 24.3541 17.4461 24.3541 18.2704 22.1496L18.3011 22.0694L19.0775 21.7555L19.1514 21.7856C20.4287 22.3261 21.1747 22.5989 21.3681 22.5989C21.4463 22.5989 21.5393 22.5645 21.5927 22.5117L22.8747 21.2578C23.036 21.0969 23.1032 21.031 22.0925 18.9042L22.0549 18.8243L22.3713 18.073L22.4469 18.0434C24.7012 17.169 24.7012 17.0838 24.7012 16.857V15.0884C24.7011 14.8613 24.7011 14.7675 22.4457 13.9623ZM16.184 18.8805C14.57 18.8805 13.257 17.5926 13.257 16.0107C13.257 14.4288 14.5701 13.1426 16.184 13.1426C17.7973 13.1426 19.1101 14.429 19.1101 16.0107C19.1102 17.5923 17.7974 18.8805 16.184 18.8805Z"
      fill="#AFB1BA"
    />
  </svg>
);

const ProjectOtherIcon = (props) => <Icon component={svg} {...props} />;

export default ProjectOtherIcon;
